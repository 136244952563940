import React, { useEffect, useState } from 'react';
import useCacheState from '../../../hooks/useCacheState';
import VisitorsOverviewWidget from './VisitorsOverviewWidget';
import {
  generatechartoptions,
  generatechartoptionsmultipleseries,
  generate_data_frombigquery,
} from '../utils';
function BlackChart({ magnetuuid, filterdate, community_id }) {
  useEffect(() => {
    if (!magnetuuid) {
      return;
    }
    // get tours
    generate_data_frombigquery(
      filterdate,
      magnetuuid,
      settotaltours,
      settotalleads,
      setuniquevisitor,
      generatechartoptions,
      () => {},
      generatechartoptionsmultipleseries,
      () => {},
      setblackChartOptions
    );
  }, [magnetuuid, filterdate]);
  //console.log('chartOptions', chartOptions);
  const [totalleads, settotalleads] = useCacheState(
    0,
    'analytics_totalLeads',
    community_id
  );
  const [totaltours, settotaltours] = useCacheState(
    0,
    'analytics_totalTours',
    community_id
  );
  const [uniquevisitor, setuniquevisitor] = useCacheState(
    0,
    'analytics_uniqueVisitors',
    community_id
  );

  const [blackChartOptions, setblackChartOptions] = useCacheState(
    undefined,
    'analytics_blackChartOptions',
    community_id
  );

  return (
    <VisitorsOverviewWidget
      blackChartOptions={blackChartOptions}
      uniquevisitor={uniquevisitor}
      totalleads={totalleads}
      totaltours={totaltours}
    />
  );
}

export default BlackChart;
