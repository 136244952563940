import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RouteInput from '../../components/Reusable/RouteInput';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import PreviewVideoMagnet from '../manage-magnets/PreviewVideoMagnet';
import { ButtonPreview } from './ButtonPreview';
import { EditBottomButton } from './EditBottomButton';
import { EditSplashScreenButton } from './EditSplashScreenButton';

import { toast } from 'react-toastify';
import EditBottomBar from './EditBottomBar';
export function MagnetPreview({
  routeOpts,
  magnetUuid,
  template,
  config,
  setConfig,
  saveChatbot,
}) {
  const defaultSplashScreenButtons = {
    play_button: { text: 'Resume Tour', id: 1 },
    primary_action: {
      //  text: 'Call Us', id: 2, icon: 'phone'
    },
    secondary_action: {
      //   text: 'Book an in-person tour',
      //   id: 3,
      //   icon: 'calendar',
      //   href: 'https://apple.com',
    },
  };
  const [defaultRoute, setDefaultRoute] = useState(routeOpts[0] || '');
  const [bottomBarButtons, setBottomBarButtons] = useState(
    config?.bottomBarButtons || []
  );
  const [newRouteInput, setNewRouteInput] = useState({});
  const [newButtonText, setNewButtonText] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);

  const [hoverSplashScreenButtonIndex, setHoverSplashScreenButtonIndex] =
    useState(null);

  const [editingButtonId, setEditingButtonId] = useState(null);
  const [editingSplashScreenButtonId, setEditingSplashScreenButtonId] =
    useState(null);

  const [splashScreenButtons, setSplashScreenButtons] = useState(
    config?.splashScreenButtons || {}
  );

  useEffect(() => {
    if (
      (!bottomBarButtons || bottomBarButtons?.length === 0) &&
      config?.bottomBarButtons
    ) {
      setBottomBarButtons(config?.bottomBarButtons || []);
    }

    if (
      (!splashScreenButtons ||
        Object.keys(splashScreenButtons)?.length === 0) &&
      config?.splashScreenButtons
    ) {
      setSplashScreenButtons(config?.splashScreenButtons || {});
    }
  }, [config]);

  useEffect(() => {
    setConfig((prev) => {
      return {
        ...prev,
        bottomBarButtons: bottomBarButtons,
        splashScreenButtons: splashScreenButtons,
      };
    });

    // console.log('splashScreenButtons localupdate', splashScreenButtons, config);
  }, [splashScreenButtons, bottomBarButtons]);

  const addBottomBarButton = (buttonObj) => {
    const newBottomBarButtonState = [...bottomBarButtons, buttonObj];
    setBottomBarButtons(newBottomBarButtonState);
    // Add the new bottomBarButton into default config
    setConfig((prev) => {
      return {
        ...prev,
        bottomBarButtons: newBottomBarButtonState,
      };
    });
  };

  let bottomButtons = [
    {
      text: 'Book Tour',
      icon: 'calendar',
      id: '1',
      iframe_underlay:
        'https://app.usetour.com/cta/scheduler/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=CST&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
    },
    {
      text: '234-423-4390',
      icon: 'phone',
      id: '2',
      // route: "thank_you.schedule_a_tour",
      // href: "https://www.apple.com"
      iframe_underlay:
        'https://app.usetour.com/cta/contactus/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?',
    },
    {
      text: 'Availability',
      icon: 'money',
      id: '3',
      // route: "thank_you.thank_youquestion",
      iframe_underlay:
        'https://app.usetour.com/cta/floorplans/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
    },
  ];

  function showErrorToast(msg) {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
    });
  }

  const removeButton = (buttonId) => {
    setBottomBarButtons((bottomBarButtons) => {
      return bottomBarButtons.filter((button) => button.id !== buttonId);
    });
  };

  return (
    <div className="flex flex-row items-begin">
      <div className="w-full"></div>

      <div className={` `} style={{ minHeight: 700 }}>
        {editingButtonId ? (
          <EditBottomButton
            bottomBarButtons={bottomBarButtons}
            editingButtonId={editingButtonId}
            routeOpts={routeOpts}
            config={config}
            setConfig={setConfig}
            saveChatbot={saveChatbot}
            setEditingButtonId={setEditingButtonId}
            setBottomBarButtons={setBottomBarButtons}
            removeButton={removeButton}
          ></EditBottomButton>
        ) : null}

        {editingSplashScreenButtonId ? (
          <EditSplashScreenButton
            splashScreenButtons={splashScreenButtons}
            editingSplashScreenButtonId={editingSplashScreenButtonId}
            setEditingSplashScreenButtonId={setEditingSplashScreenButtonId}
            setSplashScreenButtons={setSplashScreenButtons}
            routeOpts={routeOpts}
          ></EditSplashScreenButton>
        ) : null}

        <div
          style={{
            width: 600,
            height: 700,

            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 11,
            position: 'relative',
            border: 'solid gray 2px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            <video
              autoPlay
              muted
              loop
              style={{
                height: 700,
                objectFit: 'cover',
              }}
              src={
                getScreenFromTemplateUsingRoute(config?.startScreen, template)
                  ?.video || defaultPreviewTemplateScreen?.video
              }
              // src="https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/New%20Revision%204%20compressed.mp4.mp4??&coconut_id=PwAhSgloJdG84u"
            ></video>
            <div
              style={{}}
              className="absolute opacity-30 h-full bottom-0 left-0 w-full bg-black"
            ></div>

            <div
              style={{
                marginBottom: 20,
                zIndex: 3,

                width: '100%',
                alignItems: 'start',
                fontFamily: 'Plus Jakarta Sans',
                bottom: '20px',
                left: '30px',
                position: 'absolute',
              }}
            >
              <div
                style={{
                  color: 'white',
                  marginRight: 'auto',
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 30,
                    lineHeight: '25px',
                  }}
                >
                  Where to next?
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '60%',
                  height: 'min-content',
                  marginTop: 5,
                  marginBottom: 30,
                  flexDirection: 'column',
                  fontSize: 15,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 15,
                    flexDirection: 'row',
                  }}
                >
                  <button
                    style={{
                      width: '100%',
                      height: 60,
                      borderRadius: 9999,
                      // background: "rgba(58, 116, 228, 0.95)",
                      backgroundColor: config?.primaryColor || 'red',
                      border: 'none',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 5,
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      cursor: 'pointer',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      style={{
                        width: 25,
                        height: 25,
                        marginRight: 10,
                        marginLeft: 15,
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {splashScreenButtons?.play_button?.text || 'Start Tour'}
                    </p>
                  </button>

                  {splashScreenButtons?.primary_action?.text ? (
                    <button
                      onClick={() => {
                        setEditingSplashScreenButtonId('primary_action');
                      }}
                      onPointerEnter={() => {
                        setHoverSplashScreenButtonIndex('primary_action');
                      }}
                      onPointerLeave={() => {
                        setHoverSplashScreenButtonIndex(null);
                      }}
                      className=""
                      style={{
                        width: '100%',
                        height: 60,
                        borderRadius: 9999,
                        background:
                          hoverSplashScreenButtonIndex === 'primary_action'
                            ? 'rgba(0, 0, 0, 0.95)'
                            : 'rgba(39, 39, 39, 0.85)',
                        border: 'none',
                        color:
                          hoverSplashScreenButtonIndex === 'primary_action'
                            ? 'black'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 5,
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        cursor: 'pointer',
                        position: 'relative',
                        transition: 'all 0.2s ease-in-out',

                        // opacity: buttonHover === 3 ? '80%' : '',
                      }}
                    >
                      {splashScreenButtons.primary_action?.icon === 'phone' ? (
                        <Phone
                          style={{
                            width: 25,
                            height: 25,
                            marginRight: 10,
                            marginLeft: 15,
                          }}
                        />
                      ) : splashScreenButtons?.primary_action?.icon ===
                        'money' ? (
                        <DollarSign
                          style={{
                            width: 25,
                            height: 25,
                            marginRight: 10,
                            marginLeft: 15,
                          }}
                        />
                      ) : splashScreenButtons?.primary_action?.icon ===
                        'calendar' ? (
                        <Calendar
                          style={{
                            width: 25,
                            height: 25,
                            marginRight: 10,
                            marginLeft: 15,
                          }}
                        />
                      ) : null}

                      {hoverSplashScreenButtonIndex === 'primary_action' ? (
                        <p
                          style={{
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                          }}
                          className="mx-auto text-white absolute  text-lg flex flex-row items-center justify-center "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 mr-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                          Edit
                        </p>
                      ) : null}

                      <p
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {splashScreenButtons.primary_action.text}
                      </p>

                      <svg
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 10,
                          marginLeft: 'auto',
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setEditingSplashScreenButtonId('primary_action');
                        setSplashScreenButtons((splashScreenButtons) => {
                          return {
                            ...splashScreenButtons,
                            ['primary_action']: {
                              text: 'Call Us',
                              id: 2,
                              icon: 'phone',
                            },
                          };
                        });
                      }}
                      onPointerEnter={() => {
                        setHoverSplashScreenButtonIndex('primary_action');
                      }}
                      onPointerLeave={() => {
                        setHoverSplashScreenButtonIndex(null);
                      }}
                      className=" "
                      style={{
                        width: '100%',
                        height: 60,
                        border:
                          hoverSplashScreenButtonIndex === 'primary_action'
                            ? '2px solid white'
                            : '1px solid white',
                        borderRadius: 9999,
                        background:
                          hoverSplashScreenButtonIndex === 'primary_action'
                            ? 'rgba(255, 255, 255, 0.2)'
                            : 'none',

                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 5,
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        cursor: 'pointer',
                        position: 'relative',
                        transition: 'all 0.2s ease-in-out',

                        // opacity: buttonHover === 3 ? '80%' : '',
                      }}
                    >
                      {hoverSplashScreenButtonIndex === 'primary_action' ? (
                        <p
                          style={{
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                          }}
                          className="mx-auto text-white absolute  text-lg flex flex-row items-center justify-center "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Add
                        </p>
                      ) : null}

                      <p
                        style={{
                          fontWeight: 500,
                        }}
                      ></p>
                    </button>
                  )}
                </div>

                {splashScreenButtons?.secondary_action?.text ? (
                  <button
                    onClick={() => {
                      setEditingSplashScreenButtonId('secondary_action');
                    }}
                    onPointerEnter={() => {
                      setHoverSplashScreenButtonIndex('secondary_action');
                    }}
                    onPointerLeave={() => {
                      setHoverSplashScreenButtonIndex(null);
                    }}
                    className=""
                    style={{
                      width: '100%',
                      height: 60,
                      borderRadius: 9999,
                      background:
                        hoverSplashScreenButtonIndex === 'secondary_action'
                          ? 'rgba(0, 0, 0, 0.8)'
                          : 'rgba(255, 255, 255, 0.8)',
                      border: 'none',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 5,
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      cursor: 'pointer',
                      position: 'relative',
                      transition: 'all 0.2s ease-in-out',

                      // opacity: buttonHover === 3 ? '80%' : '',
                    }}
                  >
                    {splashScreenButtons.secondary_action?.icon === 'phone' ? (
                      <Phone
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 10,
                          marginLeft: 15,
                        }}
                      />
                    ) : splashScreenButtons.secondary_action?.icon ===
                      'money' ? (
                      <DollarSign
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 10,
                          marginLeft: 15,
                        }}
                      />
                    ) : splashScreenButtons.secondary_action?.icon ===
                      'calendar' ? (
                      <Calendar
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 10,
                          marginLeft: 15,
                        }}
                      />
                    ) : null}

                    {hoverSplashScreenButtonIndex === 'secondary_action' ? (
                      <p
                        style={{
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                        }}
                        className="mx-auto text-white absolute  text-lg flex flex-row items-center justify-center "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                          />
                        </svg>
                        Edit
                      </p>
                    ) : null}

                    <p
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {splashScreenButtons.secondary_action.text}
                    </p>

                    <svg
                      style={{
                        width: 25,
                        height: 25,
                        marginRight: 10,
                        marginLeft: 'auto',
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setEditingSplashScreenButtonId('secondary_action');
                      if (!splashScreenButtons?.secondary_action?.text) {
                        setSplashScreenButtons((splashScreenButtons) => {
                          return {
                            ...splashScreenButtons,
                            ['secondary_action']: {
                              text: 'Book an in-person tour',
                              id: 3,
                              icon: 'calendar',
                              href: 'https://apple.com',
                            },
                          };
                        });
                      }
                    }}
                    onPointerEnter={() => {
                      setHoverSplashScreenButtonIndex('secondary_action');
                    }}
                    onPointerLeave={() => {
                      setHoverSplashScreenButtonIndex(null);
                    }}
                    className=" "
                    style={{
                      width: '100%',
                      height: 60,
                      border:
                        hoverSplashScreenButtonIndex === 'secondary_action'
                          ? '2px solid white'
                          : '1px solid white',
                      borderRadius: 9999,
                      background:
                        hoverSplashScreenButtonIndex === 'secondary_action'
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'none',

                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 5,
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      cursor: 'pointer',
                      position: 'relative',
                      transition: 'all 0.2s ease-in-out',

                      // opacity: buttonHover === 3 ? '80%' : '',
                    }}
                  >
                    {hoverSplashScreenButtonIndex === 'secondary_action' ? (
                      <p
                        style={{
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                        }}
                        className="mx-auto text-white absolute  text-lg flex flex-row items-center justify-center "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add
                      </p>
                    ) : null}

                    <p
                      style={{
                        fontWeight: 500,
                      }}
                    ></p>
                  </button>
                )}
              </div>
            </div>

            <EditBottomBar
              setBottomBarButtons={setBottomBarButtons}
              setEditingButtonId={setEditingButtonId}
              bottomButtons={bottomBarButtons}
            ></EditBottomBar>
          </div>
        </div>
      </div>
      <div className="w-full"></div>
    </div>
  );
}

const DollarSign = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Phone = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);

const Calendar = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>
);

function getScreenFromTemplateUsingRoute(route, template) {
  try {
    const routeNames = route.split('.');
    const categoryName = routeNames[0];
    const screenName = routeNames[1];

    console.log(
      'ABCD [getScreenFromTemplateUsingRoute]: ',
      routeNames,
      categoryName,
      screenName
    );

    return template?.categories[categoryName]?.screens[screenName];
  } catch (err) {
    console.log('ABCD Error: ', err.message, route);
    return;
  }
}

const defaultPreviewTemplateScreen = {
  caption: 'Welcome!',
  cloudLibrary: [],
  form: {
    enabled: false,
    inputs: [],
    text_data: {
      body_text: '',
      title: '',
    },
  },
  gif: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.gif',
  iframe: {
    background_color: '#000000',
    enabled: false,
    icon_color: '#ffffff',
    src: '',
  },
  img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.jpg',
  links: [],
  location_card: 'Where to next{{, {firstName}|}}?',
  share_info: {},
  title: 'Main Intro',
  video:
    'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/299/magnet/f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84/intro_Main_Intro/intro__1__mp4_1.mp4?isButtonSeprated=true',
};

const defaultBottomBarButtons = [
  {
    text: 'Book Tour',
    icon: 'calendar',
    // route: "thank_you.available_floorplans"
    iframe_underlay:
      'https://app.usetour.com/cta/scheduler/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=CST&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
    id: 1,
  },
  {
    text: '234-423-4390',
    icon: 'phone',
    // route: "thank_you.schedule_a_tour",
    // href: "https://www.apple.com"
    iframe_underlay:
      'https://app.usetour.com/cta/contactus/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?',
    id: 2,
  },
  {
    text: 'Availability',
    icon: 'money',
    // route: "thank_you.thank_youquestion",
    iframe_underlay:
      'https://app.usetour.com/cta/floorplans/integration/8e205f7d-e0b0-4d1d-ad2d-a051e7cd83fa?timezone=&start=&end=&entratauser=lease_magnets_api@trinitasventures.lcl&entratapass=Leasemagnets1032022^&entratapropertyId=1109467',
    id: 3,
  },
];

// const defaultBottomBarButtons = [];
