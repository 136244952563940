import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ThankYouScreen from '../ThankYouScreen';
import WidgetWrapper from '../WidgetWrapper';
import ContactForm from './ContactForm';
import { isUserDataValid, sendMessage } from './utils';
import moment from 'moment';
import CobrowseIO from 'cobrowse-sdk-js';

CobrowseIO.license = 'quGBhRTXlDPITg';
CobrowseIO.trustedOrigins = [
  'https://embed.tour.video', // parent origin to trust
  'https://tour.video', // parent origin to trust
  'https://hi.tour.video', // parent origin to trust
  'https://app.usetour.com', // parent origin to trust
  'https://usetour.com', // parent origin to trust
  'https://my.leasemagnets.com', // another parent origin to trust
  'https://leasemagnets.com',
  'https://tour.video',
  'https://usetour.com',
  'https://theoneannarbor.com',
  '*',
];
CobrowseIO.start();

const ContactUs = ({ onBack, the_message, showBack = false }) => {
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    message: the_message ? the_message : '',
    //: `[Contact Us : I am interested in the Floor plans and looking to begin renting on ${moment().format('L')}]`,
  });
  const [isContactUsLoading, setIsContactUsLoading] = useState(false);
  const { magnetId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  let msg = urlParams.get('msg', '')
    ? urlParams.get('msg', '')
    : userInfo?.message;
  const formSubmitHandler = async () => {
    const validationError = isUserDataValid(userInfo);

    if (validationError) {
      return toast.error(validationError);
    }

    setIsContactUsLoading(true);
    const isMessageSent = await sendMessage(
      magnetId,
      {
        metadata: {
          message: userInfo.message,
        },
        lead_type: 'Question',
      },
      userInfo,
      {
        setLoading: setIsContactUsLoading,
        onError: (msg) => toast.error(msg),
      }
    );

    setIsContactUsLoading(false);
    if (isMessageSent) setStep(2);
  };

  const handleOnGoBack = () => {
    if (step > 1) setStep((prev) => prev - 1);
    else if (onBack) onBack();
    else history.back();
  };

  return (
    <WidgetWrapper step={showBack === false ? 1 : 0} onBack={handleOnGoBack}>
      {step === 1 && (
        <div className="step-4 flex flex-col">
          <div className="meta">
            <div className="title font">Contact Us</div>
            <div className="txt font">
              Fill out the form below and we'll respond soon.
            </div>
            <ContactForm
              info={userInfo}
              setInfo={setUserInfo}
              onSubmit={formSubmitHandler}
              isLoading={isContactUsLoading}
              defaultMessage={msg}
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <ThankYouScreen
          text={`${userInfo.firstname}, thanks for contacting us.`}
          secText={
            'We have received your request and will get back to you soon !'
          }
          onGoBack={() => setStep((prev) => prev - 1)}
        />
      )}
    </WidgetWrapper>
  );
};

export default ContactUs;
