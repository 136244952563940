import { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  StepContent,
  StepLabel,
  Step,
  Stepper,
  Box,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { generateRoutes } from './ManageMagnet';
import ChatbotDialog from './ChatbotDialog';
import { useParams } from 'react-router-dom';
import { post } from '../../utils/request';
import { toast } from 'react-toastify';
import { getMagnetData } from '../../store/actions/magnetActions';

// TODO send the magnet_settings successfully
// TODO update the original template ?

function UpdateSpecialOfferSection({ onFinished }) {
  const { template: templateOriginal } = useSelector(
    (state) => state.getMagnet
  );
  const _dispatch = useDispatch();
  const { community_id } = useParams();
  const { magnetId: magnetUuid } = useSelector((state) => state.magnetStates);
  const [template, setTemplate] = useState({});
  const [videoSrc, setVideoSrc] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const numberOfSteps = 3;

  console.log('BCDA MagnetUuid: ', magnetUuid);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = async () => {
    if (onFinished) {
      await onFinished();
    }
  };

  function setButtonLabel(payload) {
    setTemplate((prev) => ({
      ...prev,
      default_config: {
        ...prev.default_config,
        buttonLabel: payload,
      },
    }));
  }

  function setSpecialOffer(payload) {
    setTemplate((prev) => ({
      ...prev,
      default_config: {
        ...prev.default_config,
        specialOffer: payload,
      },
    }));
  }

  function setSpecialOfferExpiryDate(payload) {
    setTemplate((prev) => ({
      ...prev,
      default_config: {
        ...prev.default_config,
        specialOfferExpiryDate: payload,
      },
    }));
  }

  useEffect(() => {
    setActiveStep(0);
    // Get Special Offer
    //setSpecialOffer(template?.magnetSettings?.specialOffer || '');
    // Get Video Source
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setVideoSrc(generateRoutes(template)[0]?.video || '');
    }
    // Get Button Label
    // setButtonLabel(template?.default_config?.buttonLabel);
  }, []);

  useEffect(() => {
    if (typeof templateOriginal === 'object') {
      console.log('BCDA Prev Template: ', templateOriginal);
      setTemplate(JSON.parse(JSON.stringify(templateOriginal)));
    }
  }, [templateOriginal]);
  /*
  useEffect(() => {
    if (activeStep === numberOfSteps) {
      handleReset();
    }
  }, [activeStep]);
  */

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {/* STEP 1 */}
        <Step key={'Update your featured special offer'}>
          <StepLabel>Update your featured special offer</StepLabel>
          <StepContent>
            {/* <Typography>{step.description}</Typography> */}
            <StepOne
              specialOffer={template?.default_config?.specialOffer}
              setSpecialOffer={setSpecialOffer}
              specialOfferExpiryDate={
                template?.default_config?.specialOfferExpiryDate
              }
              setSpecialOfferExpiryDate={setSpecialOfferExpiryDate}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={async () => {
                    // await updateMagentSettings({
                    //   newSettingsValues: template.magnetSettings,
                    //   magnetUuid: magnetUuid,
                    //   community_id: community_id,
                    //   toastMessage: 'Succesfully updated special offer.',
                    // });
                    await updateDefaultConfig({
                      newConfigValues: template.default_config,
                      magnetUuid: magnetUuid,
                      community_id: community_id,
                      toastMessage: 'Updated Button and call to actions',
                    });
                    _dispatch(getMagnetData(community_id));
                    handleNext();
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  color="primary"
                >
                  {activeStep === numberOfSteps - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        {/* STEP 2 */}
        <Step key={'Do you want to update your circle CTA (optional)'}>
          <StepLabel>
            Do you want to update your circle CTA (optional)
          </StepLabel>
          <StepContent>
            {/* <Typography>{step.description}</Typography> */}
            <StepTwo
              buttonLabel={template?.default_config?.buttonLabel}
              setButtonLabel={setButtonLabel}
              videoSrc={videoSrc}
              borderColor={template?.default_config?.primaryColor}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  color="primary"
                >
                  {activeStep === numberOfSteps - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        {/* STEP 3 */}
        <Step key={'Do you want to update your CTA bubbles'}>
          <StepLabel
            optional={<Typography variant="caption">Last step</Typography>}
          >
            Do you want to update your CTA bubbles
          </StepLabel>
          <StepContent>
            {/* <Typography>{step.description}</Typography> */}
            <StepThree
              buttonLabel={template?.default_config?.buttonLabel}
              videoSrc={videoSrc}
              borderColor={template?.default_config?.primaryColor}
              defaultConfig={template?.default_config}
              setDefaultConfig={(configPayload) =>
                setTemplate((prev) => ({
                  ...prev,
                  default_config: configPayload,
                }))
              }
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={async () => {
                    // Only Last button should have these functions
                    console.log('BCDA updated template: ', template);
                    await updateDefaultConfig({
                      newConfigValues: template.default_config,
                      magnetUuid: magnetUuid,
                      community_id: community_id,
                      toastMessage: 'Updated Button and call to actions',
                    });
                    _dispatch(getMagnetData(community_id));
                    handleNext();
                    handleReset();
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  color="primary"
                >
                  {activeStep === numberOfSteps - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === numberOfSteps && (
        <div className="p-4 pt-0">
          <p>All steps completed - you&apos;re finished</p>
        </div>
      )}
    </Box>
  );
}

function StepOne({
  specialOffer,
  setSpecialOffer,
  specialOfferExpiryDate: expiryDate,
  setSpecialOfferExpiryDate: setExpiryDate,
}) {
  return (
    <div className="">
      <label className="my-2 mt-4 py-2 px-3 border rounded w-full">
        <p className="text-xs mb-1">Special Offer</p>
        <input
          className="p-1"
          placeholder="250$ Application Waiver"
          value={specialOffer}
          onChange={(e) => setSpecialOffer(e.target.value)}
        />
      </label>
      <label className="my-2 mb-4 py-2 px-3 border rounded w-full">
        <p className="text-xs my-1">Expiry Date</p>
        <input
          type="date"
          className="color-gray-500 p-1"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
        />
      </label>
    </div>
  );
}

function StepTwo({ buttonLabel, setButtonLabel, videoSrc, borderColor }) {
  return (
    <div className="p-4 w-full flex items-center gap-4">
      {/* Tour Bubble Main Preview */}
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: 120,
            width: 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `8px solid ${borderColor || '#224493'}`,
            borderRadius: 100,
          }}
        >
          <video
            src={videoSrc}
            muted
            autoPlay
            playsInline
            loop
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 100,
              overflow: 'hidden',
              objectFit: 'cover',
            }}
          />
          <span
            style={{
              position: 'absolute',
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '16px',
              fontFamily: 'inherit',
              textShadow: '0px 0px 4px black',
            }}
          >
            {buttonLabel}
          </span>
        </div>
      </div>
      {/* Button Label Editor */}
      <label className="my-2 mb-4 py-2 px-3 border rounded w-full">
        <p className="text-xs my-1">Your Primary Call to Action</p>
        <input
          className=""
          placeholder="Tour Now !"
          value={buttonLabel}
          onChange={(e) => setButtonLabel(e.target.value)}
        />
      </label>
    </div>
  );
}

function StepThree({
  videoSrc,
  buttonLabel,
  borderColor,
  defaultConfig,
  setDefaultConfig,
}) {
  return (
    <div className="p-4 flex flex-col items-center justify-center w-full">
      <ChatbotDialog
        optionalDefaultConfig={defaultConfig}
        setOptionalDefaultConfig={setDefaultConfig}
      />
      {/* Tour Bubble Main Preview */}
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: 120,
            width: 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `8px solid ${borderColor || '#224493'}`,
            borderRadius: 100,
          }}
        >
          <video
            src={
              videoSrc
              //'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/8/magnet/category/thank_you_main/Closing_mp4.mp4'
            }
            muted
            autoPlay
            playsInline
            loop
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 100,
              overflow: 'hidden',
              objectFit: 'cover',
            }}
          />
          <span
            style={{
              position: 'absolute',
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '16px',
              fontFamily: 'inherit',
              textShadow: '0px 0px 4px black',
            }}
          >
            {buttonLabel}
          </span>
        </div>
      </div>
    </div>
  );
}

const updateDefaultConfig = async ({
  newConfigValues,
  community_id,
  magnetUuid,
  toastMessage,
}) => {
  console.log('saving_default_config', {
    ...newConfigValues,
  });
  post(
    '/magnets/template/updateMagnet/dynamic/defaultEmbed',
    {
      magnet_uuid: magnetUuid,
      default_config: {
        ...newConfigValues,
      },
    },
    {
      auth: false,
      //, host: "http://localhost:8080"
    }
  )
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      console.log('BCDA Config Response: ', response);
      toast(
        toastMessage
          ? toastMessage
          : `Successfully Saved CTAs & Special Offers`,
        {
          position: 'top-right',
          type: 'success',
          autoClose: 5000,
        }
      );
    })
    .catch((err) => {
      console.log('BCDA Config Error: ', err);
      toast('No internet connection found. App is running in offline mode.', {
        position: 'top-right',
        type: 'success',
        autoClose: 5000,
      });
    });
};

async function updateMagentSettings({
  newSettingsValues,
  community_id,
  magnetUuid,
  toastMessage,
}) {
  console.log('saving_magnet_settings', {
    ...newSettingsValues,
  });

  post(
    '/magnets/template/settings',
    {
      magnet_uuid: magnetUuid,
      magnetSettings: {
        ...newSettingsValues,
      },
      magnet_type: 'video',
    },
    {
      auth: false,
      //, host: "http://localhost:8080"
    }
  )
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      console.log('BCDA Settings Response: ', response);
      toast(
        toastMessage
          ? toastMessage
          : `Successfully Saved CTAs & Special Offers`,
        {
          position: 'top-right',
          type: 'success',
          autoClose: 5000,
        }
      );
    })
    .catch((err) => {
      console.log('BCDA Settings Error: ', err);
      toast('No internet connection found. App is running in offline mode.', {
        position: 'top-right',
        type: 'success',
        autoClose: 5000,
      });
    });
}
export default UpdateSpecialOfferSection;
