import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { get } from '../../../utils/request';
import ContactUs from '../ContactUs/ContactUs';
import useLoadingMessages from '../useLoadingMessages';
import WidgetWrapper from '../WidgetWrapper';
import { getSpecials } from './utils';
import CobrowseIO from 'cobrowse-sdk-js';

CobrowseIO.license = 'quGBhRTXlDPITg';
CobrowseIO.trustedOrigins = [
  'https://embed.tour.video', // parent origin to trust
  'https://tour.video', // parent origin to trust
  'https://hi.tour.video', // parent origin to trust
  'https://app.usetour.com', // parent origin to trust
  'https://usetour.com', // parent origin to trust
  'https://my.leasemagnets.com', // another parent origin to trust
  'https://leasemagnets.com',
  'https://tour.video',
  'https://usetour.com',
  'https://theoneannarbor.com',
  '*',
];
CobrowseIO.start();

const Promotions = () => {
  const [specials, setSpecials] = useState([]);
  const [isSpecialsLoading, setIsSpecialsLoading] = useState(true);
  const [templateSpecial, setTemplateSpecial] = useState('');
  const [magSettings, setMagSettings] = useState({
    specialOffer: '',
  });
  const history = useHistory();
  const { magnetId } = useParams();
  const arrayOfMessages = [
    'Generating a list of relevant special offers',
    'Checking any expiry dates',
    'Retrieving those promotions for you',
    'Contact us for additional information for any special',
  ];
  const loadingMessage = useLoadingMessages(arrayOfMessages);
  const getMagnet = async (magnet_uuid) => {
    const res = await get(
      `/magnets?queryByMagnetUUID=${magnet_uuid}`,
      'GET',
      null,
      { auth: false }
    );

    let _magnetObj = res.magnets[0].magnet_details.template;
    // setStartRoute(_magnetObj.start_route);
    // setGaPropertyId(_magnetObj.gaPropertyId);
    // setFbPixelId(_magnetObj.fbPixelId);
    // setMagnetType(res.magnets[0].magnet_type);

    let tempDict = {};

    if (_magnetObj && 'magnetSettings' in _magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          _magnetObj.magnetSettings &&
          Object.keys(_magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = _magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      // setMagSettings(tempDict);
      console.log('magnetsettings: ', tempDict);
      setTemplateSpecial(tempDict?.specialOffer);
    }
  };

  useEffect(() => {
    (async () => {
      const specialsData = await getSpecials(magnetId);
      setSpecials(specialsData);
      setIsSpecialsLoading(false);
      getMagnet(magnetId);
    })();
  }, []);
  const [step, setStep] = useState(1);
  const handleOnGoBack = () => {
    if (step > 1) setStep((prev) => prev - 1);
    else if (onBack) onBack();
    else history.back();
  };
  const [message, setMessage] = useState('');
  return (
    <>
      {step === 1 && (
        <WidgetWrapper step={1} onBack={handleOnGoBack}>
          <div>
            <div className="content flex flex-col">
              <div className=" px-4">
                <h1 className=" w-full text-center mt-4 text-pr text-2xl">
                  Current Special
                </h1>
                {templateSpecial && (
                  <div
                    className=" hover:opacity-50 cursor-pointer hover:underline border-slate-600 rounded-lg"
                    onClick={() => {
                      setMessage(
                        `I would like to learn more about how I can claim '${templateSpecial}'`
                      );
                      setStep(2);
                    }}
                  >
                    <div className=" text-lg font-semibold mt-4 underline decoration-wavy	">
                      {templateSpecial}
                    </div>
                    {/* <div className=" text-sm font-medium">
                    {special?.description}
                  </div> */}
                  </div>
                )}
                {Object.values(specials).map((specialArr, arrIdx) => {
                  return specialArr?.map((special, idx) => {
                    return (
                      <div
                        className=" hover:opacity-50 cursor-pointer hover:underline "
                        onClick={() => {
                          setMessage(
                            `I would like to learn more about how I can claim '${special?.name}'`
                          );
                          setStep(2);
                        }}
                      >
                        <div className=" text-lg font-semibold mt-4">
                          {special?.name}
                        </div>
                        <div className=" text-sm font-medium">
                          {special?.description}
                        </div>
                      </div>
                    );
                  });
                })}

                <div className=" flex items-center mb-2 mt-4 gap-2">
                  <p
                    onClick={() => {
                      setMessage(
                        `I would like to learn more about your specials`
                      );
                      setStep(2);
                    }}
                    className=" text-pr text-xl cursor-pointer border-b border-pr"
                  >
                    Contact Us Today
                  </p>
                  <p>for all tailored special offers</p>
                </div>
                <div className=" text-gray-500 text-xs mt-4">
                  Hurry, offer ends soon
                </div>
                {isSpecialsLoading ? (
                  <div className=" mt-8 w-full overflow-hidden flex items-center justify-center gap-3">
                    <LoadingIndicator />
                    <h1 className=" text-2xl text-gray-400">
                      {loadingMessage}
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </WidgetWrapper>
      )}
      {step == 2 && (
        <ContactUs
          showBack={true}
          the_message={message}
          onBack={() => setStep(1)}
        />
      )}
    </>
  );
};

export default Promotions;
