import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { supabase } from '../../services/supabase';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { IFRAME_HOST } from '../../utils/request';

const MAX_PHOTOS = 20;

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const PhotoUpload = ({ community_id, magnetUuid }) => {
  const [loadingPhotos, setLoadingPhotos] = useState(true);
  const [savingPhotos, setSavingPhotos] = useState(false);
  const [embedLink, setEmbedLink] = useState(
    `${IFRAME_HOST}/cta/gallery/integration/${magnetUuid}`
  );
  const [embedLinkTimeoutId, setEmbedLinkTimeoutId] = useState();

  const [photoIds, setPhotoIds] = useState([
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/07c0a90d-de4d-440c-ac94-2dcbfa5955c2',
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/2daa6c87-47c4-47c6-bd3c-a088586a34bc',
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/7424df52dbc56261a13d3209f5cc6bf3.jpeg',
  ]);

  const [uploadedPhotos, setUploadedPhotos] = useState({
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/07c0a90d-de4d-440c-ac94-2dcbfa5955c2':
    //   {
    //     caption: 1,
    //     starred: false,
    //   },
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/2daa6c87-47c4-47c6-bd3c-a088586a34bc':
    //   {savingPhotos
    //     caption: 2,
    //     starred: false,
    //   },
    // 'https://tkweddqlriikqgylsuxz.supabase.co/storage/v1/object/public/PropertyPhotos/299/7424df52dbc56261a13d3209f5cc6bf3.jpeg':
    //   {
    //     caption: 3,
    //     starred: false,
    //   },
  });

  const deletePhoto = async (id) => {
    const urlParts = id.split('/');
    const { data, error } = await supabase.storage
      .from('PropertyPhotos')
      .remove(`${community_id}/${urlParts[urlParts.length - 1]}`);
    if (error) {
      console.error(
        `Error in deleting photo with url ${id} from supabase:`,
        error
      );
      return;
    }

    const newUploadedPhotos = structuredClone(uploadedPhotos);
    delete newUploadedPhotos[id];
    setPhotoIds(photoIds.filter((elem) => elem !== id));
    setUploadedPhotos(newUploadedPhotos);
  };

  const setPhoto = (id, newPhoto) => {
    const newUploadedPhotos = structuredClone(uploadedPhotos);
    newUploadedPhotos[id] = newPhoto;
    setUploadedPhotos(newUploadedPhotos);

    console.log(newUploadedPhotos);
  };

  const getPhotos = async () => {
    // fetch state about how photos are ordered and what is starred
    const { data: stateData, stateError } = await supabase
      .from('Community')
      .select('photos')
      .eq('id', community_id);
    let newPhotoIds = [];
    let newUploadedPhotos = {};
    if (stateError) {
      console.error('error in fetching photo state: ', stateError);
      return;
    }

    console.log('stateData', stateData);

    stateData[0].photos.forEach(({ link, caption, starred }) => {
      newUploadedPhotos[link] = { caption: caption, starred: starred };
      newPhotoIds.push(link);
    });

    console.log('newUploadedPhotos from getPhotos()', newUploadedPhotos);

    setUploadedPhotos(newUploadedPhotos);
    setPhotoIds(newPhotoIds);
    setLoadingPhotos(false);
  };

  const isUploadDisabled = () =>
    savingPhotos || loadingPhotos || photoIds.length >= MAX_PHOTOS;

  const savePhotosToSupabase = async (photoIds, uploadedPhotos) => {
    console.log('savePhotosToSupabase', photoIds, uploadedPhotos);

    const { data, error } = await supabase
      .from('Community')
      .update({
        photos: photoIds.map((id) => ({
          link: id,
          caption: uploadedPhotos[id].caption,
          starred: uploadedPhotos[id].starred,
        })),
      })
      .eq('id', community_id);
    if (error) {
      console.error('error in saving photos:', error);
    } else setSavingPhotos(false);

    console.log('savePhotosToSupabase() data', data);
  };

  const uploadPhoto = async (event) => {
    setSavingPhotos(true);

    const newLinks = [];
    const eventFiles = event.target.files;

    for (let i = 0; i < eventFiles.length; i++) {
      if (photoIds.length + i >= MAX_PHOTOS) {
        break;
      }
      const uuid = crypto.randomUUID();
      const { data, error } = await supabase.storage
        .from('PropertyPhotos')
        .upload(`${community_id}/${uuid}`, eventFiles[i], {
          // TODO: for all photos?
          cacheControl: '3600',
          upsert: false,
        });

      if (error) {
        console.error('Error in uploading photo to supabase:', error);
      }
      const link = supabase.storage
        .from('PropertyPhotos')
        .getPublicUrl(`${community_id}/${uuid}`).data.publicUrl;
      newLinks.push(link);
    }

    const newUploadedPhotos = structuredClone(uploadedPhotos);
    newLinks.forEach((link) => {
      newUploadedPhotos[link] = { caption: '', starred: false };
    });
    setUploadedPhotos(newUploadedPhotos);
    setPhotoIds([...photoIds, ...newLinks]);
  };

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      setPhotoIds((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const debouncedSavePhotos = useRef(
    _.debounce(
      (photoIds, uploadedPhotos) =>
        savePhotosToSupabase(photoIds, uploadedPhotos),
      1000
    )
  ).current;

  useEffect(() => {
    getPhotos();
  }, []);

  useEffect(() => {
    if (!loadingPhotos) {
      setSavingPhotos(true);
      debouncedSavePhotos(photoIds, uploadedPhotos);
    }
  }, [photoIds, uploadedPhotos]);

  useEffect(() => {
    console.log('getting changed');
  }, [loadingPhotos]);

  return (
    <Accordion defaultExpanded={true} className="my-4">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex flex-col">
          <p className="text-3xl font-bold">Photo Gallery</p>
          <p className="text-base text-gray-600">
            Upload photos of your property here! You can add a photo gallery
            screen from the Tour dashbaord to showcase these. Favorite them by
            clicking the star button to select special images that will be used
            in your promotional emails to new leads.
            <br />
            Maximum of 20 photos.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col w-full gap-2">
          <DndContext onDragEnd={handleDragEnd}>
            <div
              className="bg-gray-100 flex gap-4 p-4 rounded flex-wrap w-full"
              style={{
                minHeight: '208px',
              }}
            >
              <SortableContext items={photoIds}>
                {photoIds.length ? (
                  photoIds?.map((id) => (
                    <PhotoCard
                      deletePhoto={() => deletePhoto(id)}
                      elem={uploadedPhotos[id]}
                      setPhoto={(newElem) => setPhoto(id, newElem)}
                      key={id}
                      id={id}
                    />
                  ))
                ) : (
                  <p className="text-gray-500">
                    You don't have any photos yet.
                  </p>
                )}
              </SortableContext>
            </div>
          </DndContext>
          <div className="flex justify-content-center align-items-start gap-2 w-full">
            <label
              htmlFor="input-file-upload"
              className={`${
                isUploadDisabled() ? 'bg-blue-200' : 'bg-blue-500 pointer'
              } 
              rounded-md text-white p-3 gap-1 align-items-center inline-flex`}
            >
              {savingPhotos || loadingPhotos ? (
                <>
                  <CircularProgress
                    color="white"
                    size={24}
                    // style={{ height: '24px' }}
                  />
                  <div>Loading...</div>
                </>
              ) : (
                <>
                  <AddAPhotoIcon />
                  <div>Add New</div>
                </>
              )}
            </label>
            <input
              type="file"
              accept=".png,.jpg"
              id="input-file-upload"
              className="hidden"
              onChange={uploadPhoto}
              placeholder="Caption"
              multiple
              disabled={isUploadDisabled()}
            />
            <TextField
              style={{ width: '500px' }}
              onClick={async (event) => {
                clearTimeout(embedLinkTimeoutId);
                try {
                  await navigator.clipboard.writeText(embedLink);
                  console.log('Embed link copied to clipboard');
                } catch (err) {
                  console.error('Failed to copy: ', err);
                  return;
                }
                setEmbedLink('Copied!');
                setEmbedLinkTimeoutId(
                  setTimeout(
                    () =>
                      setEmbedLink(
                        `${IFRAME_HOST}/cta/gallery/integration/${magnetUuid}`
                      ),
                    1000
                  )
                );
              }}
              className="pointer"
              variant="outlined"
              helperText="iframe url (click to copy)"
              value={embedLink}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const PhotoCard = ({ elem, setPhoto, deletePhoto, id }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '1' : '0',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div
        style={{
          backgroundImage: `url(${id})`,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        className="w-40 h-40 rounded bg-cover bg-center relative text-white"
      >
        {/* drag handle */}
        <div
          style={{
            width: '100%',
            height: '100%',
            zIndex: '0',
            position: 'absolute',
          }}
          {...listeners}
        ></div>
        <div
          onClick={() => setPhoto({ ...elem, starred: !elem.starred })}
          className="absolute left-1 top-1 pointer"
          style={{ filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.5))' }}
        >
          {elem.starred ? (
            <StarIcon className="text-yellow-500" />
          ) : (
            <StarBorderIcon />
          )}
        </div>
        <div
          onClick={deletePhoto}
          className="absolute right-1 top-1 pointer"
          style={{ filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.5))' }}
        >
          <DeleteForeverIcon />
        </div>
        <div
          className="absolute bottom-0 rounded border border-gray-200 p-1 bg-black bg-opacity-50"
          style={{ width: '90%', margin: '5%' }}
        >
          <input
            value={elem.caption}
            onChange={(e) => setPhoto({ ...elem, caption: e.target.value })}
            type="text"
            className="text-white rounded w-full"
            placeholder="Caption"
          ></input>
        </div>
      </div>
    </div>
  );
};

export default PhotoUpload;
