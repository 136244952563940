import moment from 'moment';
import momenttz from 'moment-timezone';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContactForm from '../ContactUs/ContactForm';
import ThankYouScreen from '../ThankYouScreen';
import { convert_date_by_tz } from '../utilities';
import WidgetWrapper from '../WidgetWrapper';
import {
  bookAppointment,
  getAvailablePropertyHours,
  getProperty,
} from './utils';
import CobrowseIO from 'cobrowse-sdk-js';

CobrowseIO.license = 'quGBhRTXlDPITg';
CobrowseIO.trustedOrigins = [
  'https://embed.tour.video', // parent origin to trust
  'https://tour.video', // parent origin to trust
  'https://hi.tour.video', // parent origin to trust
  'https://app.usetour.com', // parent origin to trust
  'https://usetour.com', // parent origin to trust
  'https://my.leasemagnets.com', // another parent origin to trust
  'https://leasemagnets.com',
  'https://tour.video',
  'https://usetour.com',
  'https://theoneannarbor.com',
  '*',
];
CobrowseIO.start();

const Scheduler = ({ onBack, scheduleType, showGoBack }) => {
  const [step, setStep] = useState(1);
  const [selectedTourType, setSelectedTourType] = useState(
    scheduleType ? scheduleType : 'in-person'
  );
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState('');
  const [availablepropertyhours, setavailablepropertyhours] = useState([]);
  const [propertyInfo, setPropertyInfo] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  const { magnetId } = useParams();

  useEffect(() => {
    (async () => {
      const propertyData = await getProperty();
      setPropertyInfo(propertyData);
    })();
  }, []);

  useEffect(() => {
    setavailablepropertyhours(
      getAvailablePropertyHours(selectedScheduleDate, propertyInfo)
    );
  }, [selectedDate, propertyInfo]);
  const query = new URLSearchParams(window.location.search);
  const timezone = query.get('timezone') || 'EST';

  const handleSubmitContactForm = async () => {
    setIsAppointmentLoading(true);
    let created_date = await convert_date_by_tz(
      new Date(),
      timezone ? timezone : 'MST'
    );

    var tzOffsets = {
      GMT: '+00:00',
      EDT: '-04:00',
      EST: '-04:00',
      CDT: '-05:00',
      CST: '-06:00',
      MDT: '-06:00',
      MST: '-07:00',
      PDT: '-07:00',
      PST: '-08:00',
    };

    function reverseTimezone(timezone) {
      let currentOffset = tzOffsets[timezone];
      let offsetDirection = currentOffset.charAt(0);

      if (offsetDirection === '+') {
        currentOffset = '-' + currentOffset.substring(1);
      } else if (offsetDirection === '-') {
        currentOffset = '+' + currentOffset.substring(1);
      }

      return currentOffset;
    }

    const isBookingSuccessful = await bookAppointment(
      magnetId,
      {
        metadata: {
          tour_timezone: timezone,
          // tour_time_start: `${moment(selectedDate).set('hour', moment(selectedHour)).toISOString()}`,
          tour_time_start: moment(
            `${moment(selectedDate).format('L')} ${selectedHour}`,
            'MM/DD/YYYY hh:mm A'
          )
            .utcOffset(reverseTimezone(timezone))
            .toISOString(),
          tour_time_start_originating_timezone: `${moment(selectedDate).format(
            'L'
          )} ${selectedHour}`,
          tour_date: moment(selectedDate).format('MMM DD'),
          tour_time_from: selectedHour,
          tour_time_to: moment(selectedHour, 'hh:mm A')
            .add(30, 'm')
            .format('hh:mm A'),
          tour_type: selectedTourType == 'in-person' ? 'In-Person' : 'Virtual',
          tour_length: '30 Minutes',
          // summary (previously note) : "Tour Type: In-Person Tour. Tour Scheduled from Leasemagnets. Scheduled <length> Appointment on <date> at <time>."
          tour_summary: `Tour Type: ${
            selectedTourType == 'in-person' ? 'In-Person' : 'Virtual'
          } Tour. Tour Scheduled from Leasemagnets. Scheduled 30 Minute Appointment on ${moment(
            selectedDate
          ).format('L')} at ${selectedHour}`,
        },
        lead_type: 'Tour',
      },
      userInfo,
      selectedTourType,
      (msg) => toast.error(msg)
    );
    setIsAppointmentLoading(false);
    if (isBookingSuccessful) setStep(4);
  };

  const handleOnGoBack = () => {
    if (step > 1) setStep((prev) => prev - 1);
    else if (onBack) onBack();
    else history.back();
  };

  return (
    <WidgetWrapper step={step} showGoBack={showGoBack} onBack={handleOnGoBack}>
      {step === 1 && (
        <div className=" h-full w-full flex items-center justify-center">
          <div className="step-5 w-96	" style={{ width: '384px' }}>
            <div className="meta">
              <div className="title font">Schedule A Tour</div>
              <div className="txt font">Select your tour type </div>
            </div>
            <div className="actions flex aic">
              <button
                className={`action-btn font ${
                  selectedTourType === 'in-person' ? 'active' : ''
                }`}
                onClick={() => setSelectedTourType('in-person')}
              >
                In Person
              </button>
              <button
                className={`action-btn font ${
                  selectedTourType === 'virtual' ? 'active' : ''
                }`}
                onClick={() => setSelectedTourType('virtual')}
              >
                Virtual
              </button>
            </div>
            <div className="calender">
              <Calendar
                minDate={new Date(new Date().getTime() + 60 * 60 * 24 * 1000)}
                onChange={(value) => {
                  if (!(value < new Date())) {
                    setSelectedScheduleDate(value);
                    setSelectedDate(value);
                    setStep(2);
                  }
                }}
                value={selectedScheduleDate}
                // tileClassName={(dateObj) => {
                //   // console.log('calendardate: ', dateObj?.date.getMonth(), dateObj)
                //   if (
                //     dateObj?.activeStartDate?.getMonth() ===
                //     dateObj?.date?.getMonth()
                //   ) {
                //     console.log('calendardate: ', true);
                //     return '--different-month';
                //   } else {
                //     return undefined;
                //   }
                // }}
              />
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="step-6 flex flex-col">
          <div className="meta">
            <div className="title font">Schedule A Tour</div>
            <div className="stamp flex aic color">
              <div className="icon icon-calendar1" />
              <div className="lbl font s14 b3">
                {moment(selectedScheduleDate).format('MMMM Do YYYY')}
              </div>
            </div>
          </div>
          <div className="time-table">
            <div className="tit font s14 black">
              Select a tour time {timezone ? `(${timezone})` : ''}
            </div>
            <div className="time-list">
              {availablepropertyhours?.map((item, idx) => (
                <div
                  onClick={() => {
                    setSelectedHour(item);
                    setStep(3);
                  }}
                  key={idx}
                  style={{ border: '1px solid #3898ec' }}
                  className={` w-full flex items-center cursor-pointer justify-center text-sm py-2 text-pr  rounded-md   hover:bg-blue-50`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="step-4 flex flex-col">
          <div className="meta">
            <div className="title font">Schedule A Tour</div>
            <div className="stamp flex aic color">
              <div className="icon icon-calendar1" />
              <div className="lbl font s14 b3">
                {moment(selectedScheduleDate).format('MMMM Do YYYY')} at{' '}
                {selectedHour} {timezone ? timezone : ''}
              </div>
            </div>
            <ContactForm
              info={userInfo}
              setInfo={setUserInfo}
              onSubmit={handleSubmitContactForm}
              isLoading={isAppointmentLoading}
              cta={'Schedule Tour'}
              messageOff
            />
          </div>
        </div>
      )}
      {step === 4 && (
        <ThankYouScreen
          text={`${userInfo.firstname}, thanks for scheduling a tour at \n ${propertyInfo?.MarketingName}`}
          details={{
            selectedDate: selectedScheduleDate,
            selectedHour,
          }}
          onGoBack={() => setStep((prev) => prev - 1)}
        />
      )}
    </WidgetWrapper>
  );
};

export default Scheduler;
