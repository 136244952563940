import './CobrowseWrapper.css';
import { useEffect, useState } from 'react';
import ConnectScreen from './ConnectScreen';
import AgentScreen from './AgentScreen';

function CobrowseWrapper({ visitorUuid, setVisitorUuid, currentVisitorData }) {
  // const [visitorUuid, setVisitorUuid] = useState(currentVisitorUuid);
  const [stepNum, setStepNum] = useState(0);
  const [cobrowseToken, setCobrowseToken] = useState(undefined);
  const [session, setSession] = useState(undefined);

  useEffect(() => {
    // Load Already used visitor uuid at start using local storage
    const localStorageKey = 'visitor_uuid';
    if (visitorUuid?.length === 0) {
      const localVisitorUuid = localStorage.getItem(localStorageKey);
      if (localVisitorUuid) setVisitorUuid(localVisitorUuid);
    }

    fetchCobrowseToken().then((receivedToken) => {
      console.log('Cobrowse Token: ', receivedToken);
      setCobrowseToken(receivedToken);
    });
  }, []);

  useEffect(() => {
    const localStorageKey = 'visitor_uuid';
    localStorage.setItem(localStorageKey, visitorUuid);
  }, [visitorUuid]);

  useEffect(() => {
    console.log('ABCDE Session: ', session);
  }, [session]);

  function renderAgentView({ visitorUuid, frameHeight }) {
    return (
      <div className="agent-view" style={{ height: frameHeight }}>
        <h2 className="font-bold my-2">Support Agent</h2>
        <div className="">
          <button className="primary-btn my-1" onClick={() => setStepNum(0)}>
            Reconnect
          </button>
        </div>
        {cobrowseToken ? (
          <AgentScreen
            token={cobrowseToken}
            visitorUuid={visitorUuid}
            setSessionData={setSession}
          />
        ) : (
          <p className="my-2">Loading Token... {cobrowseToken}</p>
        )}
      </div>
    );
  }

  return (
    <div className="CobrowseWrapper mt-3">
      {stepNum === 0 && (
        <div className="connect-screen-container">
          <label>
            <p style={{ textAlign: 'left', margin: 0, marginBottom: 5 }}>
              Enter Visitor Uuid
            </p>
            <input
              className="primary-input"
              placeholder="Visitor Uuid"
              value={visitorUuid}
              onChange={(e) => setVisitorUuid(e.target.value)}
            />
          </label>
          <button className="primary-btn" onClick={() => setStepNum(1)}>
            Connect
          </button>
        </div>
      )}
      {stepNum === 1 &&
        renderAgentView({
          visitorUuid,
          frameHeight:
            session?.state === 'active' && session?.screenInfo?.width
              ? '100vh'
              : '150px',
        })}
    </div>
  );
}

async function fetchCobrowseToken() {
  const res = await fetch(`https://3aes6e.deta.dev/cobrowse_token`);
  const { token } = await res.json();
  return token;
}

export default CobrowseWrapper;
