import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../components/PageNotFound/PageNotFound';
// Views
import { SignIn, SignUp } from '../views';
import BookDemo from '../views/BookDemo';
import Home from '../views/Home';
import RealEstate from '../views/Home1';
import University from '../views/Home2';
import Healthcare from '../views/Home3';
import NewHome from '../views/newhome/NewHome';
import ResetPassword from '../views/signin/ResetPassword';
import UnprotectedRoutes from './UnprotectedRoutes';
import Results from '../views/Results';

const AuthRoutes = () => (
  <Switch>
    <Route exact path="/signup">
      <SignUp />
      {/* <MakeCard/> */}
    </Route>
    <Route exact path="/signin">
      <SignIn />
    </Route>
    <Route exact path="/resetpassword">
      <ResetPassword />
    </Route>
    <Route path="/" exact>
      <Home />
    </Route>
    <Route path="/results" exact>
      <Results />
    </Route>
    <Route path="/newhome" exact>
      <NewHome />
    </Route>
    <Route path="/industry/real-estate" exact>
      <RealEstate />
    </Route>
    <Route path="/industry/university" exact>
      <University />
    </Route>
    <Route path="/industry/healthcare" exact>
      <Healthcare />
    </Route>

    <Route path="/book-demo" exact>
      <BookDemo />
    </Route>
    <UnprotectedRoutes authenticated={false} />
    <Route component={PageNotFound} />
  </Switch>
);

export default AuthRoutes;
