import React from 'react';
import './BottomBar.css';
import { Calendar, DollarSign, Phone } from './Icons';
import { useState } from 'react';
import Slide from '@material-ui/core/Slide';

import { Button } from '@material-ui/core';
//material ui dialog box
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IoMdClose } from 'react-icons/io';
const EditBottomBar = ({
  bottomButtons = [],
  onClickLink,
  setIframeVideoUnderlayToggle,
  iframeVideoUnderlayToggle,
  url,
  img,
  currentRoute,
  setEditingButtonId,
  setBottomBarButtons,
}) => {
  const IS_DESKTOP = !window.matchMedia('only screen and (max-width: 600px)')
    .matches;
  const [hoverBottomButton, setHoverBottomButton] = useState();

  const iconStyle = {
    width: 20,
    height: 20,
    marginRight: 9,
  };
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [photoUrl, setPhotoUrl] = useState(
    'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f1241207-f4a8-4e8a-030e-110332ad6200/public'
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState();
  const [websiteUrl, setWebsiteUrl] = useState('');

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      setPhotoUrl(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  }

  const handleUpload = async () => {
    try {
      let url = '';

      if (file) {
        url = await uploadFile(
          file,
          setProgress,
          'branding',
          'images/' + community_id
        );
      }

      // setMetadata((prevState) => ({
      //   ...prevState,
      //   photoUrl: url,
      // }));

      let postData = {
        magnet_uuid: magnetUuid,
        custom_branding: {
          img: url || photoUrl || '',
          url: websiteUrl,
        },
      };

      console.log('postData', postData);
      if (url || photoUrl) {
        post('/magnets/template/updateMagnet/branding/custom', postData, {
          auth: false,
        })
          .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            console.log('custom branding saved...', response);
            toastNotification('Settings updated', 'success');
          })
          .catch((error) => {
            console.log('custom branding updates failed....', error.message);
            toastNotification(error.message, 'error');
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedSetting('');
  };
  const customBrandingSetting = () => {
    return (
      <SettingDialog
        title="Custom Branding"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          handleUpload();
        }}
      >
        <div className="custom-branding-dialog flex flex-col">
          <div className="form">
            <div className="image-blk">
              <div className="brand-logo">
                {photoUrl && <img src={photoUrl} alt="" />}
              </div>
              <button
                className="font change-img s12 b5 color"
                onClick={() => document.getElementById('_upload-file_').click()}
              >
                Upload Image
              </button>
              <input
                onClick={(e) => (e.target.value = '')}
                onChange={onSelectImage}
                type="file"
                accept="image/*"
                className="hide-element "
                id="_upload-file_"
              />
            </div>
            <div className="rit flex flex-col">
              <div className="field">
                <input
                  type="text"
                  placeholder="custom branding logo url"
                  className="iput font"
                  defaultValue={photoUrl}
                  onChange={(e) => setPhotoUrl(e.target.value)}
                />
              </div>
              <div className="field">
                <input
                  type="text"
                  placeholder="custom branding website url"
                  className="iput font"
                  defaultValue={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                />
              </div>
              {/* <div className="field">
                <button className="save-brand" onClick={handleUpload}>
                  Save Changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </SettingDialog>
    );
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const SettingDialog = ({
    children,
    title = 'summary',
    customButtonText,
    customButtonFunction,
    setOpenDialog,
    handleClose,
    maxWidth = 'sm',
    ...rest
  }) => {
    return (
      <>
        <Dialog
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={maxWidth}
          {...rest}
          BackdropProps={{
            style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
          }}
        >
          <div className=" w-full flex items-center justify-between pr-4">
            <DialogTitle id="alert-dialog-slide-title">{title} </DialogTitle>
            <IoMdClose
              onClick={handleClose}
              className=" w-8 h-8 cursor-pointer text-gray-600"
            />
          </div>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            {customButtonText && (
              <Button
                variant="contained"
                onClick={async () => {
                  const res = await customButtonFunction();
                  setOpenDialog(res);
                }}
                color="primary"
              >
                {customButtonText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#e2e8f0',
        }}
      />
      <div
        style={{
          height: bottomButtons?.length > 0 ? 50 : 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          borderTop: '1px solid white',
          cursor: 'pointer',
          zIndex: 3,
        }}
      >
        {bottomButtons?.map((button, i) => {
          console.log({ underlay: button });
          return (
            <button
              onPointerEnter={() => {
                setHoverBottomButton(button.id);
              }}
              onPointerLeave={() => {
                setHoverBottomButton(null);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setEditingButtonId(button.id);
              }}
              className={`boldOnHover relative ctaTab ${
                iframeVideoUnderlayToggle === button.iframe_underlay ||
                currentRoute === button.route
                  ? 'selectedTab'
                  : ''
              }`}
              style={{
                background:
                  hoverBottomButton === button.id
                    ? 'rgba(0, 0, 0, 0.8)'
                    : 'rgba(255, 255, 255, 1)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {button?.icon === 'phone' ? (
                <Phone style={iconStyle} />
              ) : button?.icon === 'money' ? (
                <DollarSign style={iconStyle} />
              ) : button?.icon === 'calendar' ? (
                <Calendar style={iconStyle} />
              ) : null}

              {IS_DESKTOP ? <p className="">{button.text}</p> : null}
              {hoverBottomButton === button.id ? (
                <p
                  style={{
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                  }}
                  className="mx-auto text-white absolute  text-lg flex flex-row items-center justify-center "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                  </svg>
                  Edit
                </p>
              ) : null}
            </button>
          );
        })}

        {bottomButtons.length < 3 ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              let id = crypto.randomUUID();
              setBottomBarButtons((bottomBarButtons) => {
                return [
                  ...bottomBarButtons,
                  {
                    id: id,
                    icon: 'money',
                    iframe_underlay: '...',
                    text: 'New button',
                  },
                ];
              });
              setEditingButtonId(id);
            }}
            className={`boldOnHover relative ctaTab `}
            style={{
              width: 120,
              height: '100%',
              transition: 'all 0.3s ease-in-out',
              zIndex: 3,
              backgroundColor: 'white',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 dark-on-hover transition"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        ) : null}

        <div
          onClick={() => setOpenDialog(true)}
          // href={
          //   url || 'https://tour.video' //'https://leasemagnets.com'
          // }
          // target="_blank"
          className="permanentButton boldOnHover"
          style={{
            height: '100%',
            backgroundColor: 'white',
            color: 'black',
            border: 'none',

            fontSize: 'small',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3,
          }}
          rel="noreferrer"
        >
          <img
            className="opacityShiftOnHover"
            style={{
              width: '90%',
              maxHeight: bottomButtons?.length > 0 ? 25 : 20,
              objectFit: 'contain',
            }}
            src={
              img ||
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/f1241207-f4a8-4e8a-030e-110332ad6200/public'
              // 'https://assets.website-files.com/5e45b17b74507185e599a039/62d2d73c5bbb74b37358fc16_LeaseMagnets%20Logo%20TYG%204.png'
            }
          />
        </div>
      </div>
      {customBrandingSetting()}
    </>
  );
};

export default EditBottomBar;
