import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import LaunchIcon from '@material-ui/icons/Launch';
import InputAdornment from '@material-ui/core/InputAdornment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillStar, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { IoQrCodeSharp } from 'react-icons/io5';
import { RiPencilLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TargetIcon } from '../../../assets/svg';
import { Header } from '../../../components';
import EditReview from '../../../components/createReview/EditReviewSettings';
import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import ManageChampionMember from '../../../components/Reviews/ManageChampionMember';
import ManageTeamMembers from '../../../components/Reviews/ManageTeamMembers';
import { generateID, isValidEmail } from '../../../core';
import { fakeReviewData } from '../../../fakedata/review';
import { RESIDENT_DASHBOARD, useRequest } from '../../../utils/request.js';
import { slugToString } from '../../../utils/slug';
import CreateReview, {
  EditReviewModal,
} from './../../../components/createReview/CreateReview';
import MultiStepForm from './MultiStepForm';
import ReviewCard from './ReviewCard';
import Autocomplete from 'react-google-autocomplete';
import QRCode from 'react-qr-code';
import BlackChart from './BlackChart';
import { getanalyicsbigquery } from '../../tour-magnet-tabs/utils';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { createScreenKey } from '../../manage-magnets/CreateKeys';
import { Box, Tooltip } from '@material-ui/core';
import { RefreshRounded } from '@material-ui/icons';

function parseJsonPayloadHorizontally(dataArr = null) {
  return dataArr.map((data, i) => {
    let tempObj = {};
    Object.keys(data).map((k, idx) => {
      console.log('response jsonp: ', k, data[k]);
      try {
        let t1 = JSON.parse(data[k]);
        tempObj[k] = t1;
        return t1;
      } catch (e) {
        console.log(e);
      }
      tempObj[k] = data[k];
      return data[k];
    });
    console.log('response jsonfull: ', tempObj);
    return tempObj || {};
  });
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));

const Review = ({ name, url, community_id, title }) => {
  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;
  const [filtersaved, setfiltersaved] = useState(false);
  //const [filterall,setfilterall] = useState('off')

  const [category] = useMemo(() => screenToEdit.split('.'), [screenToEdit]);
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );
  const [value, setValue] = React.useState(2);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [addopen, setAddopen] = useState(false);
  const [addopen2, setAddopen2] = useState(false);

  const [wallfeedback, setWallfeedback] = useState([]);
  useEffect(() => {
    setWallfeedback(fakeReviewData.feedbacktype);
  }, []);

  const filterFeedbacTypeName = (item_name) => {
    const filterData = wallfeedback.filter((item) => item.name !== item_name);
    setWallfeedback(filterData);
  };
  const params = useParams();
  console.log('_magnetData', _magnetData);
  const [adduserbadgeOpen, setAdduserbadgeOpen] = useState(false);
  const [badgeName, setBadgeName] = useState('');
  const [communityData, setCommunityData] = useState(null);
  const [communityResponse, setCommunityResponse] = useState(null);

  const _dispatch = useDispatch();
  let requestID = generateID(3, 4);
  const msgRef = useRef();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [leadName, setLeadName] = useState('');
  const [askGoogleReview, setAskGoogleReview] = useState(0);
  const [type, setType] = useState('SMS');
  const [requestSending, setRequestSending] = useState(false);
  const [error, data, makeRequest] = useRequest({ loading: false });

  const addBadge = (e) => {
    setAdduserbadgeOpen(false);
    //e.preventDefault();
    setDirectualCommunity({
      ...directualCommunity,
      communitybadges: [
        ...directualCommunity.communitybadges,
        { name: badgeName, category: '' },
      ],
    });
    setBadgeName('');
  };

  const [goalopen, setGoalopen] = useState(false);
  const [reviewsTeamMemberOpen, setReviewsTeamMemberOpen] = useState(false);
  const [openSetupDialog, setOpenSetupDialog] = useState(false);
  const [showStepForm, setShowStepForm] = useState(false);

  console.log('openSetupDialog', openSetupDialog);
  const countthebadge = (name) => {
    let count = 0;
    directualCommunity?.reviews.map((item) => {
      item.selectcommunityBadges.map((item2) => {
        if (item2.name == name) {
          count++;
        }
      });
    });
    return count;
  };

  const managesavedreviews = (index, bool) => {
    console.log('managereview', index, bool);
    directualCommunity.reviews[index].saved = bool;
    setDirectualCommunity({ ...directualCommunity });
  };

  const [blackchartoptions, setblackchartoptions] = useState();
  const getISOdate = (subtracteddays) => {
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - subtracteddays);
    let thedate = currDate.toISOString();
    return thedate;
  };
  const [filterdate, setfilterdate] = useState({
    days: 29,
    date: getISOdate(29),
  });
  console.log('blackchartoptions', blackchartoptions);

  const sendtobigquery = async () => {
    try {
      let google_review_forward_query_res = await getanalyicsbigquery(
        'SELECT details_form_title,timestamp_trunc(`default.events`.`_timestamp`, ' +
          `${
            filterdate.days < 30
              ? 'DAY'
              : filterdate.days < 180
              ? 'WEEK'
              : 'MONTH'
          }` +
          ') AS `_timestamp`,  count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
          ` ${filterdate.days} ` +
          'day), day)' +
          ` AND details_form_title = "google_review_forward"` +
          ' GROUP BY `_timestamp`,details_form_title ORDER BY `_timestamp` ASC'
      );
      // 'day), day) AND magnet_uuid=' +
      // `'f39eb022-5c26-4cfa-b8e0-6fb3d3d1cb84' AND details_form_title = "google_review_forward"` +
      console.log(
        'google_review_forward_query_res',
        google_review_forward_query_res?.res
      );
      let ranges = {};
      let series = {};

      let series_data = [];
      google_review_forward_query_res?.res?.map((item) => {
        series_data.push({ x: item[1], y: item[2] });
      });

      return { name: 'Link/scans', type: 'area', data: series_data };

      //settotaltours(total_tour_query_res?.res[0]);
    } catch (error) {
      console.log('google_review_forward_query_res_error', error);
    }
  };

  // useEffect(() => {
  //   sendtobigquery();
  // }, []);

  const [directualCommunity, setDirectualCommunity] = useState({
    // id: 'temporary',
    name: '',
    website: '', //(optional)
    communitybadges: [],
    team: [],
    reviews: {},
    champions: [],
    credits: 0,
    settings: {
      logo: '',
      address: '', //(optional)
      phone: '', // (optional),

      reviewSettings: {
        googleReviews: '',
        form: {
          rating: false,
          video: true,
          text: true,
          name: true,
          email: true,
          questions: [], // if empt
        },
        goals: {
          CurrentRating: 3.5,
          CurrentReviewCount: 399,
          GoalNumberofReviews: 265.55555555555543,
          GoalRating: 4.1,
          ReviewScoreExpected: 5,
        },
      },
      referralSettings: {
        demographics: {},
      },
    },
  });
  const [gmbObj, setGmbObj] = useState(
    directualCommunity?.settings?.gmbObj || {}
  );

  const [reviewStreaks, setReviewStreaks] = useState({});

  console.log('directualCommunity', directualCommunity);
  const createcommunity = async () => {
    try {
      // to avoiding to upload same file again
      console.log('createcommunity');
      let postData = {
        id: params.community_id,
        ...directualCommunity,
        communitybadges: {
          communitybadges: directualCommunity.communitybadges,
        },
        team: {
          team: directualCommunity.team,
        },
        reviews: {
          reviews: directualCommunity.reviews,
        },
        champions: {
          champions: directualCommunity.champions,
        },
      };
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
    } catch (err) {
      console.log('err', err);
      console.log('err', err.response);
    }
  };

  const updatecommunity = async (community_obj) => {
    console.log('directual_community', community_obj);
    let postData = {
      id: params.community_id,
      ...community_obj,
      communitybadges: {
        communitybadges: community_obj.communitybadges,
      },
      team: {
        team: community_obj.team,
      },
      reviews: community_obj.reviews,
      champions: {
        champions: community_obj.champions,
      },
    };
    console.log('postData from Review', postData);
    toastNotification('Successfully saved', 'success');
    try {
      console.log('postData', postData);
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
    } catch (e) {
      console.log(e.response);
    }
  };

  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);
    temp && temp.length > 0 && setCommunityResponse(temp[0]);

    if (temp?.settings?.goals) {
      setgoals({ ...temp.settings.goals });
    }
    if (res.data.payload.length > 0) {
      setCommunityData({
        id: res.data?.payload?.[0]?.id,
        name: res.data?.payload?.[0]?.name,
        nameClean: cleaner(res.data?.payload?.[0]?.name),
        team: res.data?.payload?.[0]?.team
          ? JSON.parse(res.data?.payload?.[0]?.team).team
          : [],
        logo: res.data?.payload?.[0]?.settings
          ? JSON.parse(res.data?.payload?.[0]?.settings)?.logo
          : '',
      });

      try {
        let thejson = {
          ...temp[0],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        if (thejson?.reviews?.google_reviews_scraped) {
          let series_data = [];
          let ranges = {};
          let series = {};
          thejson?.reviews?.google_reviews_scraped?.map((item) => {
            series_data.push([
              new Date(item?.review_datetime_utc).getTime(),
              item?.review_rating,
            ]);
          });

          ranges['reviewclicks'] = 'reviewclicks';
          series = [
            {
              name: 'Reviews',
              data: series_data,
              type: 'scatter',
              fill: {
                type: 'image',
                opacity: 1,
                image: {
                  src: [
                    'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/70812f1c-bb57-4ed1-26da-d6ba093ded00/w=40,sharpen=1',
                  ],
                  width: 40,
                  height: 40,
                },
              },
            },
          ];
          let series_res = await sendtobigquery();
          if (series_res) {
            series.push(series_res);
          }
          setblackchartoptions({ ranges, series });
        }

        setDirectualCommunity({ ...thejson });
        setGmbObj(temp?.[0]?.settings?.gmbObj);
        // console.log('after', directualCommunity);
        setcreatecommunityenable(true);
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      setShowStepForm(true);
      console.log('thejson response_empty');
    }
    setloading(false);
  };

  useEffect(() => {
    getcommunity();
  }, []);

  const [createcommunityenable, setcreatecommunityenable] = useState(false);
  // useEffect(() => {
  //   if (createcommunityenable) {
  //     createcommunity();
  //   }
  // }, [directualCommunity]);
  const [openeditreview, setopeneditreview] = useState(false);
  const [teamMemberName, setTeamMemberName] = useState('');
  const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState(null);
  const [
    selectedTeamMemberReviewTrackingId,
    setSelectedTeamMemberReviewTrackingId,
  ] = useState('');
  const teamMemberIndex = useMemo(() => {
    let i;
    communityData?.team.forEach((item, index) => {
      if (item?.name === teamMemberName) {
        i = index;
      }
    });
    console.log(i);
    return i;
  }, [teamMemberName]);

  const handleTypeSwitcher = (type) => {
    if (type === 'SMS') {
      setType('SMS');
      setEmail('');
    } else {
      setType('email');
      setPhone('');
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + match[2] + match[3];
    }
    return false;
  }

  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };

  function getAptSlug() {
    // Split URL by '/'
    const url = window.location.href.split('/');
    return url[url.length - 2];
  }

  function cleaner(str) {
    if (str) {
      var strLower = str.toLowerCase();
      return strLower.replace(/\W/g, '');
    }

    return false;
  }
  // Handle send message
  function handleSubmit(e) {
    setRequestSending(true);
    e.preventDefault();
    // Create data object

    let data = {};

    if (type === 'SMS') {
      const phoneNumber = formatPhoneNumber(phone);
      if (phoneNumber === false) {
        toast('Phone number is invalid', {
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
        setRequestSending(false);
        return null;
      }

      data = {
        message: {
          to: {
            phone_number: '+1' + phoneNumber,
          },
          template: 'ZHWWQ62XKN4NRCJWZHD3GBP1Z5KZ',
          data: {
            name: leadName,
            message: msgRef.current.innerText,
          },
        },
      };
      console.log('requestSend SMS', data);

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Text sent successfully :)', 'success');
          // if (localStorage.getItem('credits')) {
          //   let credits = parseInt(localStorage.getItem('credits'));
          //   localStorage.setItem('credits', `${--credits}`);
          //   setCredits(localStorage.getItem('credits'));
          // }
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
          setRequestSending(false);
        });
    } else {
      // type === email
      if (!email || !isValidEmail(email)) {
        toast('Please enter a valid email', {
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
        setRequestSending(false);
        return null;
      }
      data = {
        to: email,
        aptName: slugToString(getAptSlug()),
        leadName: name,
        reviewLink: `${RESIDENT_DASHBOARD}/survey/${getAptSlug()}?request=${requestID}`,
        message: msgRef.current.innerText,
      };

      data = {
        message: {
          to: {
            email: email,
          },
          template: 'BBYS20X8VX4674JN7JCFJM5V5YWZ',
          data: {
            subjectLine:
              (teamMemberName ? teamMemberName : communityData?.name) +
              ' has requested a review',
            fromName:
              `Hey 👋 ${leadName}` +
              (teamMemberName ? '' : `,  ${teamMemberName} left you a message`),
            fromPic:
              'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/153/magnet/category/b1_2_bed_2_bath/4_bed___4_bath_mp4.gif',
            message: msgRef.current.innerText,
          },
        },
      };

      console.log('requestSend SMS', data);

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Email sent successfully :)', 'success');
          // if (localStorage.getItem('credits')) {
          //   let credits = parseInt(localStorage.getItem('credits'));
          //   localStorage.setItem('credits', `${--credits}`);
          //   setCredits(localStorage.getItem('credits'));
          // }
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
          setRequestSending(false);
        });
    }
    // const route = type === 'SMS' ? '/send_sms' : '/send_email';

    // let requestHistory = {
    //   id: requestID,
    //   type: 'review_request',
    //   src_community_id: community_id,
    //   src_user_id: '',
    //   reviewId: '',
    //   data: {
    //     requesterName: '',
    //     requesterEmail: '',
    //     requesterPhone: '',
    //     leadName: teamMemberName, // requster name
    //     leadPhone: phone, // requster phone
    //     leadEmail: email, // requster email
    //     leadUserId: generateID(3, 4), // requster user
    //     staffId: '',
    //     defaultReviewStars: 4,
    //     contextId: '',
    //     completedBonusReview: false,
    //     specialOffer: '',
    //   },
    // };

    // _dispatch(createRequestHistory(requestHistory));
  }
  const [isform, setIsform] = useState('qrcode');
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedDialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
      >
        <div
          className="text-3xl font-thin absolute right-2 lga:right-4 right-4 top-0 lg:top-2 text-black cursor-pointer"
          onClick={handleClose}
        >
          &times;
        </div>
        <EditReviewModal open={openeditreview} setOpen={setopeneditreview} />

        <div className="w-full px-0 py-0 lg:py-14 lg:px-8 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-start lg:items-start">
          <div className="flex-1">
            <div className=" w-full flex items-center gap-6 justify-center pb-3">
              <h6
                onClick={() => setIsform(true)}
                className={
                  isform
                    ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                    : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                }
              >
                <FaWpforms /> Form
              </h6>
              <h6
                onClick={() => setIsform('qrcode')}
                className={
                  isform === 'qrcode'
                    ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                    : ' font-medium cursor-pointer text-gray-600 flex items-center gap-1 '
                }
              >
                <IoQrCodeSharp />
                QR code
              </h6>
            </div>
            {isform === true && (
              <CreateReview
                hideReviewSettings={false}
                personalizedShareRequestor={teamMemberName}
                requestGoogleReview={true}
                tempaskGoogleReview={askGoogleReview}
                tempselectedTeamMemberIndex={selectedTeamMemberIndex}
                url={
                  directualCommunity?.settings?.reviewSettings?.googleReviews
                }
              />
            )}
            {isform === 'qrcode' && (
              <>
                <QrCodeGMB />
              </>
            )}
            {isform === 'texting' && (
              <>
                <div className=" my-2 font-medium text-sm mb-2">
                  Request Review via Personalized Message (Recommended)
                </div>
                <div
                  className=" rounded-xl bg-pr p-3.5 text-white text-sm mb-4"
                  ref={msgRef}
                >
                  <div contentEditable>
                    Hi {leadName || 'XXXX'}, it's {teamMemberName} from{' '}
                    {communityData?.name}! I was hoping you could do me a favor
                    and leave my team and me a review? Thank you in advance.
                  </div>
                  <p className=" text-white text-sm mt-3">
                    {`${RESIDENT_DASHBOARD}/writereview/${community_id}/${
                      directualCommunity?.google_place_id
                    }${
                      selectedTeamMemberReviewTrackingId
                        ? '?u=' + selectedTeamMemberReviewTrackingId
                        : ''
                    }`}
                  </p>
                </div>
                <div className="flex items-center">
                  <label className="inline-flex items-center mr-4">
                    <input
                      type="checkbox"
                      className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                      value="SMS"
                      onChange={(e) => handleTypeSwitcher(e.target.value)}
                      checked={type === 'SMS'}
                    />
                    <span className="ml-2 text-sm">Send SMS</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                      value="email"
                      onChange={(e) => handleTypeSwitcher(e.target.value)}
                      checked={type === 'email'}
                    />
                    <span className="ml-2 text-sm">Send Email</span>
                  </label>
                </div>
                <div className=" w-full flex flex-col gap-1 mt-3">
                  <div className="mb-2">
                    {communityData?.team && communityData?.team.length && (
                      <MaterialAutocomplete
                        id="combo-box-demo"
                        options={communityData?.team?.map((item, ind) => {
                          return { name: item.name, id: ind };
                        })}
                        onChange={(event, value) => {
                          setSelectedTeamMemberIndex(value.id);
                          setTeamMemberName(value.name);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Team Member (Optional)"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div>
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        placeholder=""
                        defaultValue={leadName}
                        onChange={(e) => setLeadName(e.target.value)}
                        fullWidth
                      />
                    </div>
                    <div>
                      {type === 'SMS' && (
                        <TextField
                          type="number"
                          id="outlined-basic"
                          label="Phone"
                          variant="outlined"
                          defaultValue={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          fullWidth
                        />
                      )}
                      {type === 'email' && (
                        <TextField
                          type="email"
                          id="outlined-basic"
                          label="Email address"
                          variant="outlined"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                        />
                      )}
                    </div>
                  </div>

                  {/* <div className="block">
                    <div className="mt-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 border-0 focus:ring-0 "
                          onClick={() => {
                            console.log(
                              'attempt to click review, ',
                              askGoogleReview
                            );
                            if (askGoogleReview > 0) {
                              setAskGoogleReview(0);
                            } else {
                              setAskGoogleReview(1);
                            }
                          }}
                        />
                        <span className="ml-2 mr-3">Ask for Google Review</span>
                        <div className="flex justify-center">
                          <select
                            className="form-select
      appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label="Disabled select example"
                            disabled={askGoogleReview == 0}
                            onSelect={(e) => {
                              console.log('selected: ', e.target.value);
                              setAskGoogleReview(e.target.value);
                            }}
                            value={askGoogleReview}
                          >
                            <option value={0}>Open this select menu</option>
                            <option value={1}>Popup with Confirmation</option>
                            <option value={2}>
                              Redirect without Confirmation
                            </option>
                          </select>
                        </div>
                      </label>
                    </div>
                  </div> */}

                  <div>
                    <button
                      className=" bg-pr px-3 mt-4 py-2.5 rounded-md flex items-center justify-center text-white font-light"
                      onClick={handleSubmit}
                    >
                      {requestSending
                        ? 'Request Sending....'
                        : 'Request Review'}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex-1 w-full mb-5 lg:mb-0">
            <div className="flex items-center w-full mb-4">
              <div className="flex-1 font-bold">Request Review via Link</div>
              <div
                onClick={() => setopeneditreview(true)}
                className="flex items-center cursor-pointer font-size-12"
              >
                Settings&nbsp;
                <FiSettings />
              </div>
            </div>
            <span className=" font-size-sm">
              Priority Review Platform Link (ie: Google Review)
            </span>
            <div className=" w-full mb-4 border rounded-sm py-2 flex items-center justify-between px-2">
              <p className="w-full wordwrap mr-1">
                {directualCommunity?.settings?.reviewSettings?.googleReviews}
              </p>
              <a
                href={`${directualCommunity?.settings?.reviewSettings?.googleReviews}`}
                target="_blank"
              >
                <HiOutlineExternalLink className=" w-6 h-6 cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </AnimatedDialog>

      <div className=" w-full min-h-screen">
        {/*Manage team members / Add team member(s)*/}
        {!loading && (
          <ManageTeamMembers
            saveCommunityTeam={(team) => {
              console.log('team', team);
              setDirectualCommunity({ ...directualCommunity, team: team });
              updatecommunity({ ...directualCommunity, team: team });
            }}
            setAddopen={setAddopen}
            addopen={addopen}
            initialTeamState={directualCommunity?.team}
            community_id={community_id}
          />
        )}
        {/* Goal Component */}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={goalopen}
          onClose={() => setGoalopen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={goalopen}>
            <GoalComponent
              goalsState={{
                CurrentReviewCount:
                  directualCommunity?.reviews?.google_review_goals?.current[0],
                CurrentRating:
                  directualCommunity?.reviews?.google_review_goals?.current[1],
                GoalRating:
                  goalopen > 1
                    ? goalopen
                    : directualCommunity?.reviews?.google_review_goals
                        ?.goalRating,
                ReviewScoreExpected: 5,
                GoalNumberofReviews: 2,
              }}
              onSave={(goals) => {
                setDirectualCommunity({
                  ...directualCommunity,
                  settings: { ...directualCommunity.settings, goals },
                });
                // updatecommunity({
                //   ...directualCommunity,
                //   settings: { ...directualCommunity.settings, goals },
                // });
              }}
              teamMemberCt={directualCommunity?.team?.length}
              closeModal={() => setGoalopen(false)}
            />
          </Fade>
        </Modal>
        <Header>
          <div className="dashboard-hdr flex aic w-full">
            <div className="left flex aic">
              <a href={url} target="_blank">
                <OpenInNewIcon />
              </a>
              &nbsp;&nbsp;
              <Link to="/" className="item flex aic">
                <div className="txt font s14 b3 anim">{name}</div>
                {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
              </Link>
              <div className="item flex aic">
                <div
                  onClick={() => {
                    _dispatch({
                      type: 'SCREEN_TO_EDIT',
                      payload: '',
                    });
                    window.history.pushState({}, `View ${community_id}`, `#`);
                  }}
                  className="txt font s14 b5 black"
                >
                  Reviews
                </div>
                {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
              </div>
              {categoryTitle && (
                <div className="item flex aic">
                  <div className="txt font s14 b5 black">{categoryTitle}</div>
                </div>
              )}
              {/* <div className="right flex aic">
                <div className="right flex aic">
                  <button
                    onClick={() => {
                      setOpenSetupDialog(true);
                    }}
                    className="add-credits-btn cleanbtn font s15 b4 color"
                  >
                    <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                      Trust Setup
                    </button>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </Header>
        {
          <>
            <div className=" w-full grid grid-cols-1 lg:grid-cols-2 mt-3 px-4">
              <div>
                <h1 className=" text-sm font-semibold pt-1 block">
                  Currently at:{' '}
                </h1>

                <div className=" flex items-center gap-4 flex-wrap">
                  <h1 className=" text-3xl font-semibold ">
                    {
                      directualCommunity?.reviews?.google_review_goals
                        ?.current?.[0]
                    }

                    <span className=" text-3xl font-light "> reviews</span>
                  </h1>
                  {/* <h1 className=" text-sm font-semibold pt-1">
                    {parseInt(
                      directualCommunity?.settings?.goals?.CurrentReviewCount
                    )
                      ? parseInt(
                          directualCommunity?.settings?.goals
                            ?.CurrentReviewCount
                        )
                      : 0}

                    <span className=" text-sm font-medium "> goal reviews</span>
                  </h1> */}
                </div>

                <div className=" flex items-center flex-wrap  gap-2 w-full">
                  <div className=" bg-blue-50 rounded-3xl mt-2 px-2  flex gap-1 items-center  py-1">
                    <img src="https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/9bc34e95-2527-4e62-a052-857b6ecc2200/w=25,sharpen=1" />
                    {directualCommunity?.reviews?.google_review_goals
                      ?.current?.[1] ? (
                      <>
                        <Rating
                          value={
                            directualCommunity?.reviews?.google_review_goals
                              ?.current?.[1]
                          }
                          precision={0.5}
                          onChange={(event, newValue) => {
                            console.log('rating TYG: ', newValue);
                            if (newValue > 4.9) {
                              setGoalopen(4.9);
                            } else {
                              setGoalopen(newValue);
                            }
                          }}
                        />
                        <p className="text-xs text-gray-700">
                          {
                            directualCommunity?.reviews?.google_review_goals
                              ?.current?.[1]
                          }{' '}
                          out of 5
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <Tooltip title="Refresh Reviews">
                    <IconButton
                      onClick={() => {
                        axios
                          .post(
                            'https://xc9dp0.deta.dev/initiate_reviews_pull',
                            {
                              community_id,
                              placeId: directualCommunity?.google_place_id,
                            }
                          )
                          .then(() => {
                            location.reload();
                          });
                      }}
                    >
                      <RefreshRounded />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <p className="mt-4">Review & Goal Summary</p>
                  <button
                    className=" flex items-center gap-1 cursor-pointer"
                    onClick={() => setGoalopen(true)}
                    onMouseEnter={() => setReviewsTeamMemberOpen(true)}
                    onMouseLeave={() => setReviewsTeamMemberOpen(false)}
                  >
                    <div className=" h-5 text-pr">
                      <TargetIcon />
                    </div>
                    <div>
                      <p className=" text-pr text-xs">
                        {' '}
                        {directualCommunity?.reviews?.google_review_goals
                          ?.goalRating ? (
                          <div className="flex">
                            Your Next Goal rating is{' '}
                            {
                              directualCommunity?.reviews?.google_review_goals
                                ?.goalRating
                            }{' '}
                            stars (Approx{' '}
                            {
                              directualCommunity?.reviews?.google_review_goals
                                ?.current?.[0]
                            }{' '}
                            →
                            {
                              directualCommunity?.reviews?.google_review_goals
                                ?.latest_review_state?.['5']?.final?.[0]
                            }{' '}
                            Reviews)
                            <RiPencilLine className=" text-gray-600 ml-1" />
                          </div>
                        ) : (
                          <div className="flex">
                            {' '}
                            Set your community goal
                            <RiPencilLine className=" text-gray-600 ml-1" />
                          </div>
                        )}{' '}
                      </p>
                      <p className=" text-black text-xs flex">
                        To do that we need to:
                      </p>
                      {/* <div className="flex">
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        
                      </div> */}
                    </div>
                  </button>

                  {directualCommunity?.reviews?.google_review_goals
                    ?.latest_review_state && (
                    <div className="mt-2">
                      {Object.entries(
                        directualCommunity?.reviews?.google_review_goals
                          ?.latest_review_state
                      )
                        .sort((v1, v2) => v2[0] - v1[0])
                        .map(([potentialRating, ratingGoals], idx) => {
                          return (
                            <div className="flex">
                              <AiFillStar className=" text-yellow-400" />{' '}
                              {potentialRating} | 🎯{' '}
                              {ratingGoals?.current_specific?.[0]} /{' '}
                              {ratingGoals?.current_specific?.[0] +
                                ratingGoals?.goal?.[0]}{' '}
                              <span className="text-gray-400">
                                &nbsp;&nbsp;&nbsp; ({ratingGoals?.goal?.[0]}{' '}
                                left{' '}
                                {directualCommunity?.team?.length ? (
                                  <>
                                    {' '}
                                    /{' '}
                                    {ratingGoals?.goal?.[0] /
                                      directualCommunity?.team?.length}{' '}
                                    reviews per team member{' '}
                                  </>
                                ) : (
                                  <></>
                                )}{' '}
                                ){' '}
                                {idx <
                                Object.entries(
                                  directualCommunity?.reviews
                                    ?.google_review_goals?.latest_review_state
                                )?.length -
                                  1
                                  ? ' or'
                                  : ''}{' '}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                {/* <div className=" my-3 ">
                  <button
                    onClick={handleOpen}
                    className=" py-2 px-3 text-white flex  items-center rounded-3xl w-full lg:w-40 bg-pr justify-between"
                  >
                    <p className=" text-sm">Request review</p>
                    <BsArrowRight className=" w-5 h-5" />
                  </button>
                </div> */}

                {/* review form BEGIN */}

                {/* review form END */}
              </div>

              {/* second row */}

              <div>
                <div className="">{AddTeamMemberAndChampions()}</div>

                {/* {AddBadgesButtons()} */}
              </div>

              {/* end second row */}
            </div>

            <div className=" w-full grid grid-cols-1 lg:grid-cols-2 mt-3 px-4">
              <div className="w-full px-4 pt-2 ">
                <div className="flex ">
                  <h1 className=" font-bold text-2xl mb-2 mr-5">
                    Collect Reviews
                  </h1>
                  {moment(
                    directualCommunity?.reviews
                      ?.last_google_scrape_request_timestamp
                  ) >
                  moment(
                    directualCommunity?.reviews?.last_google_scrape_complete
                  ) ? (
                    <>
                      <div className="flex justify-center items-center">
                        <div
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          role="status"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full px-0 py-0 lg:py-14 lg:px-8 flex flex-col-reverse items-start justify-start lg:flex-row lg:justify-start lg:items-start">
                  <div className="flex-1">
                    <div className=" w-full flex items-start gap-6 justify-start pb-3">
                      {/* <h6
                        onClick={() => setIsform(true)}
                        className={
                          isform === true
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                        }
                      >
                        <FaWpforms /> Form
                      </h6> */}
                      <h6
                        onClick={() => setIsform('qrcode')}
                        className={
                          isform === 'qrcode'
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600 flex items-center gap-1 '
                        }
                      >
                        <IoQrCodeSharp />
                        Link & QR code
                      </h6>
                      <h6
                        onClick={() => setIsform('texting')}
                        className={
                          isform === 'texting'
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                        }
                      >
                        <FaWpforms /> Texting
                      </h6>
                      <h6
                        onClick={() => setIsform('settings')}
                        className={
                          isform === 'settings'
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                        }
                      >
                        <FiSettings /> Settings
                      </h6>
                    </div>
                    {isform === true && (
                      <CreateReview
                        hideReviewSettings={false}
                        personalizedShareRequestor={teamMemberName}
                        requestGoogleReview={true}
                        tempaskGoogleReview={askGoogleReview}
                        tempselectedTeamMemberIndex={selectedTeamMemberIndex}
                        url={
                          directualCommunity?.settings?.reviewSettings
                            ?.googleReviews
                        }
                      />
                    )}
                    {isform === 'qrcode' && <QrCodeGMB />}
                    {isform === 'settings' && <EditReview />}
                    {isform === 'texting' && (
                      <>
                        <div className=" my-2 font-medium text-sm mb-2">
                          Request Review via Personalized Message (Recommended)
                        </div>
                        <div
                          className=" rounded-xl bg-pr p-3.5 text-white text-sm mb-4"
                          ref={msgRef}
                        >
                          <div contentEditable>
                            Hi {leadName || 'XXXX'}, it's {teamMemberName} from{' '}
                            {communityData?.name}! I was hoping you could do me
                            a favor and leave my team and me a review? Thank you
                            in advance.
                          </div>
                          <p className=" text-white text-sm mt-3">
                            {RESIDENT_DASHBOARD}/writereview/{community_id}/
                            {directualCommunity?.google_place_id}
                            {teamMemberName
                              ? '?u=' + createScreenKey(teamMemberName)
                              : ''}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <label className="inline-flex items-center mr-4">
                            <input
                              type="checkbox"
                              className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                              value="SMS"
                              onChange={(e) =>
                                handleTypeSwitcher(e.target.value)
                              }
                              checked={type === 'SMS'}
                            />
                            <span className="ml-2 text-sm">Send SMS</span>
                          </label>
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                              value="email"
                              onChange={(e) =>
                                handleTypeSwitcher(e.target.value)
                              }
                              checked={type === 'email'}
                            />
                            <span className="ml-2 text-sm">Send Email</span>
                          </label>
                        </div>
                        <div className=" w-full flex flex-col gap-1 mt-3">
                          <div className="mb-2">
                            {communityData?.team && communityData?.team.length && (
                              <MaterialAutocomplete
                                id="combo-box-demo"
                                options={communityData?.team?.map(
                                  (item, ind) => {
                                    return { name: item.name, id: ind };
                                  }
                                )}
                                onChange={(event, value) => {
                                  setSelectedTeamMemberIndex(value.id);
                                  setTeamMemberName(value.name);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Team Member (Optional)"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          </div>
                          <div className="grid grid-cols-2 gap-1">
                            <div>
                              <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                placeholder=""
                                defaultValue={leadName}
                                onChange={(e) => setLeadName(e.target.value)}
                                fullWidth
                              />
                            </div>
                            <div>
                              {type === 'SMS' && (
                                <TextField
                                  type="number"
                                  id="outlined-basic"
                                  label="Phone"
                                  variant="outlined"
                                  defaultValue={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  fullWidth
                                />
                              )}
                              {type === 'email' && (
                                <TextField
                                  type="email"
                                  id="outlined-basic"
                                  label="Email address"
                                  variant="outlined"
                                  defaultValue={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  fullWidth
                                />
                              )}
                            </div>
                          </div>

                          {/* <div className="block">
                            <div className="mt-2">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 border-0 focus:ring-0 "
                                  onClick={() => {
                                    console.log(
                                      'attempt to click review, ',
                                      askGoogleReview
                                    );
                                    if (askGoogleReview > 0) {
                                      setAskGoogleReview(0);
                                    } else {
                                      setAskGoogleReview(1);
                                    }
                                  }}
                                />
                                <span className="ml-2 mr-3">
                                  Ask for Google Review
                                </span>
                                <div className="flex justify-center">
                                  <select
                                    className="form-select
      appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    aria-label="Disabled select example"
                                    disabled={askGoogleReview == 0}
                                    onSelect={(e) => {
                                      console.log('selected: ', e.target.value);
                                      setAskGoogleReview(e.target.value);
                                    }}
                                    value={askGoogleReview}
                                  >
                                    <option value={0}>
                                      Open this select menu
                                    </option>
                                    <option value={1}>
                                      Popup with Confirmation
                                    </option>
                                    <option value={2}>
                                      Redirect without Confirmation
                                    </option>
                                  </select>
                                </div>
                              </label>
                            </div>
                          </div> */}

                          <div>
                            <button
                              className=" bg-pr px-3 mt-4 py-2.5 rounded-md flex items-center justify-center text-white font-light"
                              onClick={handleSubmit}
                            >
                              {requestSending
                                ? 'Request Sending....'
                                : 'Request Review'}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    bg-gray-200
                  </div>
                </div>
              </div>

              <div className=" flex items-start mt-2 gap-3 flex-col ">
                <h1 className=" font-bold text-2xl">Review Analytics™️</h1>

                {loading ? (
                  <>
                    <div className="border border-blue-100  shadow rounded-md p-4 max-w-lg w-full ">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-3 py-1">
                          <div className="h-2 bg-gray-200 rounded"></div>
                          <div className="h-2 bg-gray-200 rounded w-32"></div>
                          <div className="space-y-4 bg-gray-100 p-3">
                            <div className="h-2 bg-gray-200 rounded "></div>
                            <div className=" flex items-center flex-wrap justify-center gap-10">
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                            </div>
                            <div className=" flex items-center flex-wrap justify-center gap-10">
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                            </div>
                            <div className=" flex items-center flex-wrap justify-center gap-10">
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-300 text-xl">
                                G
                              </div>
                            </div>
                            <div className="flex items-center gap-6 flex-wrap  justify-center">
                              <div className="h-2 bg-gray-200 w-12 rounded "></div>
                              <div className="h-2 bg-gray-200 w-12 rounded "></div>
                              <div className="h-2 bg-gray-200 w-12 rounded "></div>
                              <div className="h-2 bg-gray-200 w-12 rounded "></div>
                              <div className="h-2 bg-gray-200 w-12 rounded "></div>
                            </div>
                            <div className="flex items-center gap-6  justify-center">
                              <div className="h-2 bg-gray-200 w-24 rounded "></div>
                              <div className="h-2 bg-gray-200 w-24 rounded "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!directualCommunity?.reviews?.google_review_goals && (
                      <div>
                        <h1 className="text-2xl text-gray-400">
                          Sorry! No data available!😥
                        </h1>
                      </div>
                    )}
                  </>
                )}

                {directualCommunity?.reviews?.google_review_goals?.streaks
                  ?.hot_streak >= 2 ? (
                  <div className=" w-full">
                    <p className="mt-1 text-sm font-bold">
                      Congrats, you're officially on a hot{' '}
                      {
                        directualCommunity?.reviews?.google_review_goals
                          ?.streaks?.hot_streak
                      }{' '}
                      🔥 review streak{' '}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {directualCommunity?.reviews?.google_review_goals?.streaks
                  ?.hot_streak == 1 ? (
                  <div className=" w-full">
                    <p className="mt-1 text-sm font-bold">
                      One more review, 🧨 and you will be on a hot streak{' '}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {directualCommunity?.reviews?.google_review_goals?.streaks
                  ?.hot_streak == 0 &&
                directualCommunity?.reviews?.google_review_goals?.streaks
                  ?.cold_streak == 1 ? (
                  <div className=" w-full">
                    <p className="mt-1 text-sm font-bold">
                      ❌ Your fire just went out, let’s try to go on a streak
                      again
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div className=" w-full">
                  {directualCommunity?.reviews?.google_review_goals?.streaks
                    ?.hot_streak == 0 &&
                  directualCommunity?.reviews?.google_review_goals?.streaks
                    ?.cold_streak >= 2 ? (
                    <p className="mt-1 text-sm font-bold">
                      You are officially on a cold{' '}
                      {
                        directualCommunity?.reviews?.google_review_goals
                          ?.streaks?.cold_streak
                      }{' '}
                      🥶 review streak <br />
                      Let's change this today
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                {blackchartoptions && (
                  <BlackChart blackChartOptions={blackchartoptions} />
                )}

                {/* <div className="flex flex-row">
                  <div className="w-8 h-8 bg-gray-200 hover:bg-gray-300  items-start rounded-sm cursor-pointer ml-2">
                    <Link to={'/community/' + community_id + '/reviews/embed'}>
                      <i className="fa fa-share text-xl align-middle p-1"></i>
                    </Link>
                  </div>
                </div>
                <div className=" flex items-center gap-5">
                  <div className=" flex items-center gap-1 text-sm">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      checked={filtersaved == false}
                      onClick={(e) => setfiltersaved(false)}
                    />
                    <label htmlFor="push-notifications">All</label>
                  </div>
                  <div className=" flex items-center gap-1 text-sm">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      checked={filtersaved == true}
                      onClick={(e) => setfiltersaved(true)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="push-notifications">Saved</label>
                  </div>
                </div> */}
                <div className=" mt-3 w-full grid  grid-cols-1 md:grid-cols-1 gap-5">
                  <div>Recent Reviews</div>

                  {loading ? (
                    <>
                      <div className="border border-blue-100  shadow rounded-md p-4 max-w-sm w-full ">
                        <div className="animate-pulse flex space-x-4">
                          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                          <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-gray-200 rounded"></div>
                            <div className="space-y-3">
                              <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                              </div>
                              <div className="h-2 bg-gray-200 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border border-blue-100  shadow rounded-md p-4 max-w-sm w-full ">
                        <div className="animate-pulse flex space-x-4">
                          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                          <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-gray-200 rounded"></div>
                            <div className="space-y-3">
                              <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                              </div>
                              <div className="h-2 bg-gray-200 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!directualCommunity?.reviews?.google_reviews_scraped && (
                        <div>
                          <h1 className="text-2xl text-gray-400">
                            Sorry! You dont have any reviews yet!😥
                          </h1>
                          <h1 className="text-2xl text-gray-400">
                            Check if you have any or{' '}
                            <span className="font-bold">
                              refresh
                              <Tooltip title="Refresh Reviews">
                                <IconButton
                                  onClick={() => {
                                    axios
                                      .post(
                                        'https://xc9dp0.deta.dev/initiate_reviews_pull',
                                        {
                                          community_id,
                                          placeId:
                                            directualCommunity?.google_place_id,
                                        }
                                      )
                                      .then(() => {
                                        location.reload();
                                      });
                                  }}
                                >
                                  <RefreshRounded />
                                </IconButton>
                              </Tooltip>
                            </span>{' '}
                            the data{' '}
                          </h1>
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    {directualCommunity?.reviews?.google_reviews_scraped &&
                      directualCommunity?.reviews?.google_reviews_scraped.map(
                        (review, idx) => {
                          return (
                            <>
                              <div
                                id={review?.review_id}
                                className="flex items-center mb-4 space-x-4"
                              >
                                {/* <img
                                  className="w-10 h-10 rounded-full"
                                  src={review?.author_image}
                                  alt=""
                                /> */}
                                <Avatar
                                  className={{
                                    margin: 10,
                                    color: '#fff',
                                    backgroundColor: '#000',
                                  }}
                                >
                                  {review?.author_title?.split(' ')[0][0]}
                                  {review?.author_title?.split(' ')?.[1]
                                    ? review?.author_title?.split(' ')[1][0]
                                    : review?.author_title.substring(1, 2)}
                                </Avatar>

                                <div className="space-y-1 font-medium dark:text-white">
                                  <p>
                                    {review?.author_title}
                                    <div className="flex items-center mb-1">
                                      {[
                                        ...Array(review?.review_rating || 1),
                                      ].map(() => (
                                        <svg
                                          aria-hidden="true"
                                          className="w-5 h-5 text-yellow-400"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <title>First star</title>
                                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                        </svg>
                                      ))}

                                      <span className="text-gray-400 font-sm">
                                        {moment
                                          .utc(review?.review_datetime_utc)
                                          .local()
                                          .fromNow()}
                                      </span>
                                    </div>

                                    <time
                                      datetime="2014-08-16 19:00"
                                      className="block text-sm text-gray-500 dark:text-gray-400"
                                    ></time>
                                  </p>
                                </div>
                              </div>

                              <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                                {review?.review_text}
                              </p>
                            </>
                          );
                        }
                      )}
                  </div>

                  {/* <div className=" mt-3 w-full grid  grid-cols-1 md:grid-cols-2 gap-5">
                    <div>Team Leaderboard: (Scans, NPS)</div>
                    <div>Top Event Sources Leaderboard</div>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        }

        {/* {showStepForm && <MultiStepForm community_id={community_id} />} */}
      </div>
    </>
  );

  function QrCodeGMB() {
    return (
      <div className={` w-full flex items-start justify-start min-h-screen`}>
        <div className=" w-full lg:w-96  border-2 border-gray-500 rounded-md p-3 shadow-lg bg-white">
          <div className="flex items-center mb-4 space-x-4">
            <img
              className="w-20 h-20 rounded-full"
              src={directualCommunity?.settings?.photo}
              alt=""
            />
            <div className="space-y-1 font-medium dark:text-white">
              <p>
                {directualCommunity?.name}{' '}
                <a
                  href={directualCommunity?.website}
                  target="_blank"
                  className="block text-sm text-gray-500 dark:text-gray-400"
                >
                  {directualCommunity?.website}
                </a>
              </p>
            </div>
          </div>

          <hr className="my-3 h-px bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="grid place-items-center center justify-center items-center">
            <h1 className="text-lg mt-2 mb-2 font-bold mb-2">
              Scan to review ⬇️
            </h1>
            <QRCode
              value={`${RESIDENT_DASHBOARD}/writereview/${community_id}/${
                directualCommunity?.google_place_id
              }${
                selectedTeamMemberReviewTrackingId
                  ? '?u=' + selectedTeamMemberReviewTrackingId
                  : ''
              }`}
              size={220}
            />
          </div>

          {teamMemberName && (
            <p className="block text-center center text-md text-gray-500 w-full mt-4 dark:text-gray-400 ">
              Thank you,
              <br />
              👋 @{createScreenKey(teamMemberName)}
            </p>
          )}

          <hr className="my-3 mt-5 h-px bg-gray-200 border-0 dark:bg-gray-700" />

          {directualCommunity?.google_place_id && (
            <div>
              {/* <a
                href={`https://search.google.com/local/writereview?placeid=${directualCommunity?.google_place_id}`}
                target="_blank"
              >
                https://search.google.com/local/writereview?placeid=n
                {directualCommunity?.google_place_id}
              </a>{' '}
              <br />
              <a
                href={`${RESIDENT_DASHBOARD}/writereview/${community_id}/${directualCommunity?.google_place_id}`}
                target="_blank"
              >
                {RESIDENT_DASHBOARD}/writereview/{community_id}/
                {directualCommunity?.google_place_id}
              </a> */}

              <TextField
                id="input-with-icon-textfield"
                label="Link with Tracking"
                value={`${RESIDENT_DASHBOARD}/writereview/${community_id}/${
                  directualCommunity?.google_place_id
                }${
                  selectedTeamMemberReviewTrackingId
                    ? '?u=' + selectedTeamMemberReviewTrackingId
                    : ''
                }`}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() =>
                          window.open(
                            `${RESIDENT_DASHBOARD}/writereview/${community_id}/${
                              directualCommunity?.google_place_id
                            }${
                              selectedTeamMemberReviewTrackingId
                                ? '?u=' + selectedTeamMemberReviewTrackingId
                                : ''
                            }`,
                            '_blank'
                          )
                        }
                      >
                        <LaunchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="input-with-icon-textfield"
                label="No Tracking"
                value={`https://search.google.com/local/writereview?placeid=${directualCommunity?.google_place_id}`}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() =>
                          window.open(
                            `https://search.google.com/local/writereview?placeid=${directualCommunity?.google_place_id}`,
                            '_blank'
                          )
                        }
                      >
                        <LaunchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span className="text-sm font-gray-200">
                GMB: {directualCommunity?.google_place_id}
              </span>

              <div className="mb-2">
                {communityData?.team && communityData?.team.length ? (
                  <MaterialAutocomplete
                    id="combo-box-demo"
                    options={communityData?.team}
                    onChange={(event, value) => {
                      setTeamMemberName(value?.name);
                      console.log('selectedTeamObj: ', value);
                      setSelectedTeamMemberReviewTrackingId(value?.id);
                      // setSelectedTeamMemberIndex(value.id);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Team Member (Optional)"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          <Autocomplete
            apiKey={'AIzaSyCrD2NbwBUEj-jqtU303rfETOYChYehO2A'}
            onPlaceSelected={(place) => {
              console.log('GMBOBJ: ', place);
              setGmbObj(place);

              updatecommunity({
                ...directualCommunity,
                settings: {
                  ...directualCommunity.settings,
                  gmbObj: place,
                },
                google_place_id: place?.place_id,
              });
              axios.post('https://xc9dp0.deta.dev/initiate_reviews_pull', {
                community_id,
                placeId: place?.place_id,
              });
            }}
            options={{
              types: ['establishment'],
              // componentRestrictions: { country: "us" },
            }}
          />
        </div>
      </div>
    );
  }

  function AddBadgesButtons() {
    return (
      <div className=" w-full flex items-center gap-3 flex-wrap px-0 mt-3">
        {directualCommunity?.communitybadges &&
          directualCommunity.communitybadges.map((item, ind) => (
            <button
              key={ind}
              className=" bg-gray-100 rounded-sm button_hover p-2 flex items-center gap-2"
            >
              <p className=" text-sm text-gray-900">{item.name}</p>
              <p className=" px-2 border-l text-sm hide_element border-gray-600">
                {countthebadge(item.name)}
              </p>
              <p
                onClick={() => {
                  let communitybadges = directualCommunity.communitybadges;
                  communitybadges.splice(ind, 1);
                  setDirectualCommunity({
                    ...directualCommunity,
                    communitybadges,
                  });
                }}
                className="  border-l cursor-pointer text-sm show_element bg-gray-600 text-white p-1 rounded-full"
              >
                <IoMdClose className=" w-4 h-4" />
              </p>
            </button>
          ))}
        {adduserbadgeOpen === false ? (
          <button
            onClick={() => setAdduserbadgeOpen(true)}
            className=" bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
          >
            <p className=" text-sm font-bold"> Add Badge</p> <AiOutlinePlus />
          </button>
        ) : (
          <form className=" flex" onSubmit={(e) => addBadge(e)}>
            <input
              type="text"
              autoFocus
              onChange={(e) => setBadgeName(e.target.value)}
              required
              className=" py-1 border rounded-sm px-2 w-32"
            />
          </form>
        )}
        {badgeName && (
          <button
            onClick={() => addBadge()}
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
          >
            Save
          </button>
        )}
      </div>
    );
  }

  function AddTeamMemberAndChampions() {
    return (
      <div className=" grid grid-cols-2">
        <div>
          <div className=" flex items-center gap-4">
            <h1 className=" text-xl font-semibold">
              {directualCommunity?.team?.length
                ? directualCommunity?.team?.length
                : 'Add'}{' '}
              <span className=" text-sm">Team Members</span>
            </h1>
          </div>

          <div className=" flex items-center ">
            {loading && (
              <div className="    rounded-md p-2  ">
                <div className="animate-pulse flex ">
                  <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                  <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                  <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                </div>
              </div>
            )}
            {directualCommunity?.team &&
              directualCommunity?.team.map((item, ind) => (
                <Tooltip title={item?.name} followCursor>
                  <Box>
                    <div
                      key={ind}
                      onClick={() => setAddopen(true)}
                      className=" p-2 text-2xl uppercase -ml-2  bg-gray-300 font-medium rounded-full border-2 border-white cursor-pointer"
                    >
                      {item?.name?.slice(0, 2)}
                    </div>
                  </Box>
                </Tooltip>
              ))}

            <Tooltip title={'Add New Team Member'} followCursor>
              <Box>
                <div
                  onClick={() => setAddopen(true)}
                  className=" p-2 text-2xl -ml-2  bg-black font-medium rounded-full border-2 border-white text-white cursor-pointer"
                >
                  <AiOutlinePlus className=" w-4 h-4" />
                </div>
              </Box>
            </Tooltip>
          </div>
        </div>

        <div className=" ">
          {/* <div className=" flex items-center gap-4">
            <h1 className=" text-xl font-semibold">
              {directualCommunity?.champions?.length
                ? directualCommunity?.champions?.length
                : 'Add'}{' '}
              <span className=" text-sm">Review Sources</span>
            </h1>
          </div>
          <button
            onClick={() => setAddopen2(true)}
            className="    flex items-center rounded-3xl mt-1 w-full gap-4 "
          >
            <div className=" flex items-center ">
              {directualCommunity?.champions &&
                [
                  ...(directualCommunity.champions || []),
                  { name: '' },
                  { name: '' },
                  { name: '' },
                  { name: '' },
                ]
                  .slice(0, 4)
                  .map((item, ind) => (
                    <div
                      key={ind}
                      className=" p-2 text-sm uppercase text-white  -ml-2  bg-black font-medium rounded-full border-2 border-white"
                    >
                      {item.name ? (
                        item.name.slice(0, 2)
                      ) : (
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      )}
                    </div>
                  ))}
              <div
                onClick={() => setAddopen2(true)}
                className=" p-2 text-sm -ml-2  bg-black font-medium rounded-full border-2 border-white text-white cursor-pointer"
              >
                <AiOutlinePlus className=" w-5 h-5" />
              </div>
            </div>
          </button> */}
        </div>
      </div>
    );
  }

  function GoalComponent({
    goalsState,
    onSave,
    teamMemberCt = 0,
    closeModal = (e) => {},
  }) {
    console.log('goals state: ', goalsState);
    const [goals, setgoals] = useState(
      goalsState
        ? goalsState
        : {
            CurrentReviewCount: 40,
            CurrentRating: 3.2,
            GoalRating: 4.1,
            ReviewScoreExpected: 5,
            GoalNumberofReviews: 2,
          }
    );
    console.log('goals state: ', goals);
    // goals: {
    //   CurrentReviewCount: 2,
    //   CurrentRating: 2,
    //   GoalRating: 2,
    //   ReviewScoreExpected: 5,
    //   GoalNumberofReviews: 2,
    //
    // },

    function calculateNumberOfReviewsNeededToReachGoal(
      currReviewCt,
      currReviewRating,
      goalRating,
      expectedIndivRating
    ) {
      let goalReviewCt =
        Math.ceil(
          Number(currReviewCt) * Number(currReviewRating) -
            Number(goalRating) * Number(currReviewCt)
        ) /
        (Number(goalRating) - Number(expectedIndivRating));

      return goalReviewCt;
    }

    function displayStars(rating) {
      let stars = '';
      for (let i = 0; i < rating; i++) {
        stars = stars + '⭐';
      }
      return stars;
    }

    return (
      <div className={classes.paper + ' w-full lg:w-2/4 bg-white'}>
        <h6 className=" font-medium w-full pb-2 border-b">
          Update Your Goals for your community
        </h6>
        {goals.CurrentReviewCount && (
          <div className="mt-3">Current Review Count</div>
        )}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Current Review Count"
          value={goals?.CurrentReviewCount}
          onChange={(e) => {
            if (Number(e.target.value) || e.target.value == '') {
              setgoals({ ...goals, CurrentReviewCount: e.target.value });
            }
          }}
        />

        {goals.CurrentRating && <div className="mt-1">Current Rating</div>}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Current Rating"
          value={goals?.CurrentRating}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, CurrentRating: e.target.value });
            }
          }}
        />

        {goals.GoalRating && (
          <div className="mt-1">
            Goal Rating{' '}
            {goals?.CurrentRating >= goals?.GoalRating && (
              <span className=" text-red-500">
                Please ensure that Goal Rating &gt; Current Rating
              </span>
            )}{' '}
          </div>
        )}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Goal Rating"
          value={goals?.GoalRating}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, GoalRating: e.target.value });
            }
          }}
        />

        <span>
          {goals?.ReviewScoreExpected && (
            <div className="mt-1">
              Each score is expected to be at least{' '}
              {displayStars(goals?.ReviewScoreExpected)}{' '}
              {goals?.ReviewScoreExpected <= goals?.GoalRating && (
                <span className=" text-red-500">
                  Please ensure that Expected Review Score &gt; Goal Rating
                </span>
              )}
            </div>
          )}
        </span>
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Each review expected to be a least X stars"
          value={goals?.ReviewScoreExpected}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, ReviewScoreExpected: e.target.value });
            }
          }}
        />

        <h6 className=" text-sm mt-3 mb-1">
          Reviews needed: To get to {goals?.GoalRating} stars, you will need{' '}
          {Math.round(
            calculateNumberOfReviewsNeededToReachGoal(
              goals?.CurrentReviewCount,
              goals?.CurrentRating,
              goals?.GoalRating,
              goals?.ReviewScoreExpected
            )
          )}{' '}
          {displayStars(goals?.ReviewScoreExpected)} new reviews <br />
          <br />
          {teamMemberCt > 1 && (
            <span>
              Each of the {teamMemberCt} team members is responsible for{' '}
              {Math.round(
                calculateNumberOfReviewsNeededToReachGoal(
                  goals?.CurrentReviewCount,
                  goals?.CurrentRating,
                  goals?.GoalRating,
                  goals?.ReviewScoreExpected
                )
              ) / teamMemberCt}{' '}
              reviews
            </span>
          )}
        </h6>
        <div className=" w-full grid grid-cols-2 mt-2 gap-2">
          <button
            className=" bg-pr w-full py-2 text-white font-light"
            onClick={() => {
              onSave({
                ...goals,
                GoalNumberofReviews: calculateNumberOfReviewsNeededToReachGoal(
                  goals?.CurrentReviewCount,
                  goals?.CurrentRating,
                  goals?.GoalRating,
                  goals?.ReviewScoreExpected
                ),
              });
              closeModal();
            }}
          >
            save button
          </button>
          <button className=" border w-full py-2" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
};

export default Review;
