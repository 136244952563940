import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { get } from '../../utils/request';

// Details --> selectedDate
const ThankYouScreen = ({ details, text, secText, onGoBack }) => {
  console.log('details', details);
  const { magnetId } = useParams();
  const [magnetSettings, setMagnetSettings] = useState({});

  function getMagnetTemplate() {
    get(`/magnets/template?magnet_uuid=${magnetId}`, { auth: false })
      .then((data) => {
        const extractedTemplateData = data.magnet.magnet_details.template;
        console.log(
          'extractedTemplateData?.magnetSettings',
          extractedTemplateData?.magnetSettings
        );
        setMagnetSettings(extractedTemplateData?.magnetSettings || {});
      })
      .catch((error) => {
        console.log('error@getVideoTemplate: ', error.message);
      });
  }
  useEffect(() => {
    getMagnetTemplate();
  }, []);

  return (
    <div className="p-4">
      <div className=" w-full flex mt-3 items-center justify-center">
        <div className=" w-32 h-32 rounded-full overflow-hidden">
          <div className=" w-32 h-32 rounded-full overflow-hidden">
            {magnetSettings?.waveVideo && (
              <video
                width="100%"
                height="100%"
                autoPlay={true}
                muted
                loop={true}
                preload
              >
                <source
                  src={
                    magnetSettings?.waveVideo ||
                    'https://customer-qqdk2u3dbwgfurzm.cloudflarestream.com/6ef5266f24500ff86b50cd45bcc7ccfa/downloads/default.mp4'
                  }
                  type="video/mp4"
                />
              </video>
            )}
            {magnetSettings?.waveImg && (
              <img
                alt="waveTYG"
                className="w-32 h-32 rounded-full"
                src={
                  magnetSettings?.waveImg ||
                  'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/2e43454b-af4a-4018-3e0f-bd1841f0d000/public'
                }
              />
            )}
          </div>
        </div>
      </div>
      <h4 className=" pt-4 text-pr text-base font-semibold w-full text-center">
        {text}
      </h4>
      <p className=" text-gray-600 text-base font-light pt-4 w-full text-center">
        {secText
          ? secText
          : 'We have received your request and have scheduled your tour on'}
      </p>
      {details?.selectedDate ? (
        <p className=" font-bold text-pr w-full text-center pt-2">
          {moment(details?.selectedDate).format('MMMM Do YYYY')} at{' '}
          {details?.selectedHour}
          {/* at{' '}
            {selectedhour} */}
        </p>
      ) : null}
      <div className=" flex items-center justify-center mt-10">
        <button onClick={onGoBack} className=" text-pr font-medium">
          Go back
        </button>
      </div>
    </div>
  );
};

export default ThankYouScreen;
