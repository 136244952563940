import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { widgets, theme } from './assets';

function VisitorsOverviewWidget({
  blackChartOptions,
  uniquevisitor,
  totaltours,
  totalleads,
}) {
  const { series, ranges } = blackChartOptions || widgets;
  const [tabValue, setTabValue] = useState(1);
  const currentRange = Object.keys(ranges)[tabValue];

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      width: '100%',
      height: '100%',
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.light],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: '#4D8AE6',
    },
    grid: {
      show: true,
      borderColor: theme.palette.divider,
      padding: {
        top: 10,
        bottom: -40,
        left: 0,
        right: 0,
      },
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: 2,
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
      x: {
        format: 'MMM dd, yyyy',
      },
      y: {
        formatter: (value) => `${value}`,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        stroke: {
          color: theme.palette.divider,
          dashArray: 0,
          width: 2,
        },
      },
      labels: {
        offsetY: -20,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tickAmount: 20,
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
    },
    yaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      // min: (min) => min - 80,
      // max: (max) => max + 200,
      tickAmount: 5,
      show: false,
    },
  };

  return (
    <div theme={theme.palette.primary.main}>
      <div
        style={{ background: '#1E293B' }}
        className="sm:col-span-2 lg:col-span-3 dark flex flex-col flex-auto shadow relative rounded-2xl overflow-hidden  text-white"
      >
        <div className="flex items-center gap-32 mt-10 ml-20 mr-24 sm:mr-40">
          <div className="flex flex-col">
            <h1 className="mr-16 text-2xl md:text-3xl font-semibold  leading-7">
              Traffic Overview
            </h1>
            <h2 className="font-medium">
              {['Visitors', 'Tours', 'Leads'][tabValue]}
            </h2>
          </div>

          <div className="mt-12 sm:mt-0 sm:ml-8 z-20">
            <Tabs
              value={tabValue}
              onChange={(ev, value) => {
                setTabValue(value);
              }}
              indicatorColor="secondary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons={false}
              className="-mx-4 min-h-40"
              classes={{
                indicator: 'flex justify-center bg-transparent w-full h-full',
              }}
              TabIndicatorProps={{
                children: (
                  <Box className="w-full h-full rounded-full bg-gray-50 opacity-20" />
                ),
              }}
            >
              {Object.entries(ranges).map(([key, label]) => (
                <Tab
                  className="text-14 font-semibold min-h-40 min-w-64  px-12"
                  disableRipple
                  key={key}
                  label={label}
                />
              ))}
            </Tabs>
          </div>
        </div>

        <div className=" flex items-center mt-16 justify-start lg:pl-20 flex-wrap w-full gap-20 ">
          <div className="flex flex-col">
            <div className="flex items-center text-white">
              <div className="font-bold text-xl text-secondary leading-5">
                Visitors
              </div>
              {/* <Tooltip title="Score is calculated by using the historical ratio between Page Views and Visitors. Best score is 1000, worst score is 0.">
                            <IconButton>
                            <BsFillInfoCircleFill className="ml-3 text-gray-400 w-3 h-3 cursor-pointer" />
                            </IconButton>
                        </Tooltip> */}
            </div>
            <div className="flex items-start mt-2">
              {blackChartOptions ? (
                <div className="text-4xl font-bold tracking-tight leading-none">
                  {uniquevisitor}
                </div>
              ) : (
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  {/* <span class="visually-hidden">Loading...</span> */}
                </div>
              )}
              {/* <div className="flex items-center ml-8">
                  <FuseSvgIcon className="text-green-500" size={20}>
                            heroicons-solid:arrow-circle-up
                            </FuseSvgIcon>
                  <h1 className="ml-4 text-md font-medium text-green-500">
                    42.9%
                  </h1>
                </div> */}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center text-white">
              <div className="font-bold text-xl text-secondary leading-5">
                Tours
              </div>
              {/* <Tooltip title="Score is calculated by using the historical ratio between Page Views and Visitors. Best score is 1000, worst score is 0.">
                            <IconButton>
                            <BsFillInfoCircleFill className="ml-3 text-gray-400 w-3 h-3 cursor-pointer" />
                            </IconButton>
                        </Tooltip> */}
            </div>
            <div className="flex items-start mt-2">
              {blackChartOptions && totaltours ? (
                <div className="text-4xl font-bold tracking-tight leading-none">
                  {totaltours}
                </div>
              ) : (
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  {/* <span class="visually-hidden">Loading...</span> */}
                </div>
              )}
              {/* <div className="flex items-center ml-8">
                  <FuseSvgIcon className="text-green-500" size={20}>
                            heroicons-solid:arrow-circle-up
                            </FuseSvgIcon>
                  <h1 className="ml-4 text-md font-medium text-green-500">
                    42.9%
                  </h1>
                </div> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-auto h-80">
          <ReactApexChart
            options={chartOptions}
            series={series[currentRange]}
            type={chartOptions.chart.type}
            height={chartOptions.chart.height}
          />
        </div>
        {/* {!blackChartOptions && (
          <div class="flex justify-center items-center absolute top-0 left-0 w-full h-full z-20">
            <div
              class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
           
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default VisitorsOverviewWidget;
