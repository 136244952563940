import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'video-react/dist/video-react.css'; // import css
import './css/App.scss';
import './custom_css/Calendar.css';
import * as splashLoader from './lottie/splash_loader.json';
import AuthRoutes from './routes/AuthRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import firebase from './utils/base';
import { whitelabelLogoOrText } from './utils/branding';
import { send_user_login_location_to_discord } from './utils/api';
const stripePromise = loadStripe(
  'pk_test_51K5VDmCgQX5AncW3mJMyiUlA5qwmYiGaWsQMjlOutWk6a1iGZi4OEfwnyVmOH4JH3eRtR8GbC1zZidNlvEeSOTP700ZnJSphhp'
);

function App() {
  const [appLoading, setAppLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth.onAuthStateChanged((u) => {
      setUser(u);
      send_user_login_location_to_discord(u);
      setAppLoading(false);
    });
  }, []);

  const _splashLoader_ = {
    loop: true,
    autoplay: true,
    animationData: splashLoader.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);
  return (
    <Elements stripe={stripePromise}>
      {appLoading ? (
        <div className="splash">
          <div className="loader flex aic rel">
            {/* <Lottie options={_splashLoader_} width={130} /> */}
            {whitelabelLogoOrText()}
          </div>
        </div>
      ) : (
        <div className="App">
          <ToastContainer />
          <Router>{user ? <ProtectedRoutes /> : <AuthRoutes />}</Router>
        </div>
      )}
    </Elements>
  );
}

export default App;
