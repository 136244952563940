import { BottomBarConfig } from './BottomBarConfig';
import { Card, Grid, Switch, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import Notification from '../../components/Reusable/Notification';
import { getMagnetData } from '../../store/actions/magnetActions';
import { getanalyicsbigquery } from '../../utils/api';
import { uploadFile } from '../../utils/files';
import { generatechartoptions, getISOdate } from '../../utils/helpers';
import { get, post } from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import TourBubbleConfig from './TourBubbleConfig';
import AddBubbleSection from './AddBubbleSection';
import { MagnetPreview } from './MagnetPreview';
import UpdateConfigAlert from './UpdateConfigAlert';
import { EMBED_HOST } from '../../utils/request';
import MagnetSettings from '../manage-magnets/MagnetSettings';
import PhotoUpload from './PhotoUpload';
import TriggersModal from './TriggersModal';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TalkAndTriggers = ({ name, url, community_id, title }) => {
  const _dispatch = useDispatch();
  const { template, magnetData } = useSelector((state) => state.getMagnet);
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setRoutes(generateRoutes(template));
      return;
    }
    (async () => {
      console.log('calledddd', community_id);
      await _dispatch(getMagnetData(community_id));
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setRoutes(generateRoutes(template));
    }
  }, [template]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Triggers"
      /> */}
      {ChatbotCustomizer(community_id, routes, magnetData)}
    </>
  );
};

export default TalkAndTriggers;

function ChatbotCustomizer(community_id, routes, magnetData) {
  const chatbotRef = useRef();
  //const [chatbot, setchatbot] = useState([]);
  const [isSchedulerBubbleOpen, setIsSchedulerBubbleOpen] = useState(false);
  const [isSchedulerBubbleInEditor, setIsSchedulerBubbleInEditor] =
    useState(false);
  const _dispatch = useDispatch();
  const { template } = useSelector((state) => state.getMagnet);
  const [defaultConfig, setDefaultConfig] = useState(template?.default_config);
  const [tempConfig, setTempConfig] = useState(null);

  const [valueIndex, setValueIndex] = useState(null);
  const [condition, setCondition] = useState([]);
  const { magnetId: magnetUuid } = useSelector((state) => state.magnetStates);

  function getChatbot() {
    console.log('defaultConfig?.chatbot =', defaultConfig?.chatbot);
    return defaultConfig?.chatbot || [];
  }

  function setChatbot(chatbotPayload) {
    setDefaultConfig((prev) => ({ ...prev, chatbot: chatbotPayload }));
  }

  function getRandomName() {
    let hexString = uuidv4();
    // remove decoration
    hexString = hexString.replace(/-/g, '');
    let base64String = Buffer.from(hexString, 'hex').toString('base64');
    console.log('base64:', base64String);
    return base64String;
  }

  const onClickLink = (link) => {
    if (link.route) {
      const newRoute = link.route.split('.');
      // onClick(newRoute);
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href, '_blank').focus();
    } else if (link.href_direct) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href_direct, '_self').focus();
    }
  };

  function copy(e, ref) {
    setChatBotCopied(true);
    ref.current.select();
    document.execCommand('copy');
    e.target.focus();
    onCopyText();
  }

  const onCopyText = () => {
    setTimeout(() => {
      setChatBotCopied(false);
    }, 1000);
  };
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const saveChatbot = async (props) => {
    const data = {
      community_id,
      magnet_uuid: magnetUuid,
    };
    console.log('saving_default_config', {
      ...defaultConfig,
      chatbot: getChatbot(),
    });
    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbed',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...defaultConfig,
          chatbot: getChatbot(),
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then(async (response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        if (props?.refreshtemplate) {
          await _dispatch(getMagnetData(community_id));
        }
        toast(
          props?.toastMessage
            ? props?.toastMessage
            : `Successfully Saved CTAs & Special Offers`,
          {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          }
        );
      })
      .catch((e) => {
        console.log('error', e);
        toast('No internet connection found. App is running in offline mode.', {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      });
  };

  const [chatBotCopied, setChatBotCopied] = useState(false);
  const [conditionModal, setconditionModal] = useState(false);
  const handleCloseSignOutDialog = () => {
    setconditionModal(false);
  };

  // const addDateInchatBot = () => {
  //   let copyChatbot = chatbot;

  //   console.log('Above Dates', { startDate, endDate });

  //   copyChatbot[valueIndex].condition = {
  //     dateStart: startDate,
  //     dateEnd: endDate,
  //   };
  //   setchatbot([...copyChatbot]);

  //   setconditionModal(false);
  // };

  const [progress, setProgress] = useState(false);

  const handleUpload = async (file) => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        'magnetuid/' + guidGenerator()
      );
      setProgress(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };
  const [buttonIndex, setButtonIndex] = useState(0);
  const [idx, setIdx] = useState(0);
  const [chartOptions, setchartOptions] = useState('');
  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      let imageUrl = await handleUpload(e.target.files[0]);

      let copytemporaryChatbot = getChatbot();
      if (copytemporaryChatbot[buttonIndex].buttons[idx]?.picArray) {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray.push({
          img: imageUrl,
          alt: '',
        });
      } else {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray = [
          { img: imageUrl, alt: '' },
        ];
      }

      setChatbot([...copytemporaryChatbot]);
    }
  }
  function addSubtitles(index, e, idx) {
    let copytemporaryChatbot = [...getChatbot()];

    copytemporaryChatbot[index].buttons[idx].subTitle = e.target.value;

    setChatbot([...copytemporaryChatbot]);
  }

  const deleteSelectedImage = (item, index, idx, pic_idx) => {
    const filterdImageArrray = getchatbot()[index].buttons[idx].picArray.filter(
      (pic, l_index) => pic_idx != l_index
    );
    let copyAgainChatbot = [...getChatbot()];
    copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray;
    console.log(
      (copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray)
    );
    setChatbot([...copyAgainChatbot]);
    // setchatbot(chatbot[index].buttons[idx].picArray = filterdImageArrray);
  };
  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  const [operator, setOperator] = useState();

  const addConditon = () => {
    setCondition([
      ...condition,
      {
        type: condition?.[condition.length - 1]?.type,
        route: '',
        operator: '',
        value: [],
      },
    ]);
  };
  const removeCondtion = (ind) => {
    setCondition(condition.filter((item, index) => ind !== index));
  };
  const [magSettings, setMagSettings] = useState({
    specialOffer: '',
  });
  const [gaPropertyId, setGaPropertyId] = useState();
  const [startRoute, setStartRoute] = useState();
  const [fbPixelId, setFbPixelId] = useState();
  const [magnetType, setMagnetType] = useState();

  const [triggerTab, setTriggerTab] = useState('tour-bubble-offer');
  const [videoStartChoice, setVideoStartChoice] = useState('');
  const [routeStartChoice, setRouteStartChoice] = useState('');
  const [routeOpts, setRouteOpts] = useState([]);
  const [schedulerSelectedDate, setSchedulerSelectedDate] = useState(null);

  console.log('routeStartChoice', routeStartChoice);

  const handleChangeMenu = (event) => {
    console.log(event.target.value);
    setDefaultConfig({ ...defaultConfig, startScreen: event.target.value });
    if (event.target.value.includes('|')) {
      const route = event.target.value.split('|', 2)[0];
      const videourl = event.target.value.split('|', 2)[1];
      console.log('TRIGGER IN |', route, videourl);
      setRouteStartChoice(route);
      setVideoStartChoice(videourl);
    }
  };

  const getMagnet = async () => {
    const res = await get(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );

    let _magnetObj = res.magnets[0].magnet_details.template;
    setStartRoute(_magnetObj.start_route);
    setGaPropertyId(_magnetObj.gaPropertyId);
    setFbPixelId(_magnetObj.fbPixelId);
    setMagnetType(res.magnets[0].magnet_type);

    let tempDict = {};

    if (_magnetObj && 'magnetSettings' in _magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          _magnetObj.magnetSettings &&
          Object.keys(_magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = _magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      setMagSettings(tempDict);
    }

    if (_magnetObj) {
      const tempRoutes = generateRoutes(_magnetObj);
      console.log('tempRoutes', tempRoutes);
      setRouteOpts(tempRoutes);
      setDefaultConfig((prev) => ({
        ...prev,
        // startScreen:
        //   tempRoutes.length > 0
        //     ? tempRoutes[0].route // '|' + tempRoutes[0].video
        //     : '',
      }));
      setVideoStartChoice(tempRoutes.length > 0 ? tempRoutes[0].video : '');
      setRouteStartChoice(tempRoutes.length > 0 ? tempRoutes[0].route : '');
    }
  };

  const [NotificationBar, setNotification] = Notification();
  function updateSettings() {
    post('/magnets/template/settings', {
      magnet_uuid: magnetUuid,
      magnetSettings: magSettings,
      start_route: startRoute,
      gaPropertyId: gaPropertyId,
      fbPixelId: fbPixelId,
      magnet_type: magnetType,
    }).then((data) => {
      setNotification(`Settings updated`, 'success');
      location.reload();
    });
  }

  useEffect(() => {
    getMagnet();
  }, []);

  const [valueArr, setValueArr] = useState([]);

  const handleAddChip = (val) => {
    setValueArr([...valueArr, val]);
  };

  const handleDeleteChip = (chip, selectedIndex, idx) => {
    let fvalue = condition;
    let nvalue = fvalue[idx].value.filter(
      (item, ind) => selectedIndex !== ind && item !== chip
    );
    fvalue[idx].value = nvalue;
    // console.log("nvalue", nvalue)
    setCondition([...fvalue]);
  };

  const updateCodition = (val, ind, property) => {
    console.log('condition updated', val);
    let copyCondtionArray = condition;

    copyCondtionArray[ind][property] = val;

    setCondition([...copyCondtionArray]);
  };

  var past_7_date = new Date();
  past_7_date.setDate(past_7_date.getDate() - 7);
  let thedate = new Date(past_7_date).toISOString();

  const [trigger_stats, settrigger_stats] = useState([]);
  const [trigger_totals, settrigger_totals] = useState('');
  const [active_trigger_button, setactive_trigger_button] = useState('');
  const [handlefirst, sethandlefirst] = useState('open_tour');
  const setLayoutDirection = (newDirection) => {
    setDefaultConfig({
      ...defaultConfig,
      layout: newDirection,
    });
  };

  const [filterdate, setfilterdate] = useState({
    days: 90,
    date: getISOdate(90),
  });

  const sendquerytobigquery = async () => {
    try {
      let triggerss_res = await getanalyicsbigquery(
        'select  a.details_button_title as title,a.details_open_tour_type as details_open_tour_type,a.details_cta_title as details_cta_title,views,submitted,a.details_button_subtitle as details_button_subtitle,a.to as `to` from ' +
          '(SELECT details_button_title,details_open_tour_type,details_cta_title, count(*) as views,details_button_subtitle,`to` FROM `leasemagnets-app.default.events` where details_button_title is not null' +
          ` and event_type = 'open_tour' and magnet_uuid = '${magnetUuid}' ` +
          'AND utc_time > ' +
          `'${filterdate.date}' ` +
          'group by details_button_title,details_open_tour_type,details_cta_title,details_button_subtitle,`to` ORDER BY `views` DESC) as a left join (SELECT details_button_title,    count(*) as submitted FROM `leasemagnets-app.default.events`' +
          `where details_button_title is not null and event_type = 'form_submission' ` +
          'AND utc_time > ' +
          `'${filterdate.date}' and magnet_uuid = '${magnetUuid}' ` +
          'group by details_button_title ORDER BY `submitted` DESC ) as b on a.details_button_title = b.details_button_title ORDER BY submitted DESC,views DESC'
      );
      console.log('triggerss_res', triggerss_res);
      let triggers = [];
      if (triggerss_res?.res) {
        triggerss_res?.res?.map((item, index) => {
          triggers.push({
            title: item[0],
            subtitle: item[2] ? `${item[2]} : ` : null,
            clicks: item[3],
            conversions: item[4],
            open_tour_type: item[1] ? `${item[1]} : ` : null,
            details_button_subtitle: item[5],
            details_route: item[6],
          });
        });
        settrigger_stats(triggers);
      }
      let trigger_graph_query = await getanalyicsbigquery(
        'SELECT timestamp_trunc(`default.events`.`_timestamp`, ' +
          `${filterdate.days > 90 ? 'WEEK' : 'DAY'}` +
          ') AS `_timestamp`, count(*) AS `count`,details_button_title FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -' +
          ` ${filterdate.days} ` +
          'day), day) AND magnet_uuid=' +
          `'${magnetUuid}' AND details_button_title is not null ` +
          `${
            active_trigger_button
              ? 'AND details_button_title = ' + `'${active_trigger_button}'`
              : ''
          } ` +
          'AND  `default.events`.`event_type` = ' +
          `"${handlefirst}"` +
          ' GROUP BY `_timestamp`,details_button_title ORDER BY `_timestamp` ASC'
      );
      let total_clicks_query = await getanalyicsbigquery(
        'SELECT count(*) AS `count` FROM `default.events` WHERE magnet_uuid=' +
          `'${magnetUuid}' AND details_button_title is not null ` +
          `${
            active_trigger_button
              ? 'AND details_button_title = ' + `'${active_trigger_button}'`
              : ''
          } ` +
          'AND  `default.events`.`event_type` = ' +
          `"open_tour"` +
          'AND utc_time > ' +
          `'${filterdate.date}' `
      );
      let total_conversions_query = await getanalyicsbigquery(
        'SELECT count(*) AS `count` FROM `default.events` WHERE magnet_uuid=' +
          `'${magnetUuid}' AND details_button_title is not null ` +
          `${
            active_trigger_button
              ? 'AND details_button_title = ' + `'${active_trigger_button}'`
              : ''
          } ` +
          'AND  `default.events`.`event_type` = ' +
          `"form_submission"` +
          'AND utc_time > ' +
          `'${filterdate.date}' `
      );
      console.log('trigger_graph_query', trigger_graph_query);
      console.log('total_clicks_query', total_clicks_query);
      console.log('total_conversions_query', total_conversions_query);
      settrigger_totals({
        total_clicks: total_clicks_query?.res[0][0],
        total_conversions: total_conversions_query?.res[0][0],
      });
      generatechartoptions(trigger_graph_query, 'Triggers', setchartOptions);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (magnetUuid) {
      sendquerytobigquery();
    }
  }, [filterdate, handlefirst, active_trigger_button, magnetUuid]);

  const PreviewWidgetEmbed = ({ uuid }) => {
    const [renderDemo, setRenderDemo] = useState(false);
    // useEffect(() => {
    //   if (renderDemo) {
    //     const s = document.createElement('script');
    //     s.setAttribute('src', `${EMBED_HOST}/leasemagnets.js`);
    //     s.setAttribute('async', 'async');
    //     s.setAttribute('defer', 'defer');

    //     s.addEventListener(
    //       'load',
    //       () => window.LeaseMagnets && window.LeaseMagnets(config)
    //     );
    //     s.addEventListener('error', (e) => console.error(e));

    //     document.body.appendChild(s);

    //     return () => {
    //       window.destroyLeaseMagnets && window.destroyLeaseMagnets();
    //       document.body.removeChild(s); // Clean up
    //     };
    //   }
    // }, [renderDemo, config]);

    // if (alwaysShow) {
    //   // Don't show the "Show Preview" button, the preview will be opened via window.toggleLeaseMagnetsOpen
    //   return null;
    // }

    return (
      <div className="EmbedSavePreview">
        <br />
        <div className="bottom-status-bar flex aic">
          <Button
            color="inherit"
            variant="contained"
            // onClick={() => setRenderDemo((render) => !render)}
            onClick={() => window.open(`${EMBED_HOST}?uuid=${uuid}`, '_blank')}
            style={{ marginRight: '10px' }}
          >
            {renderDemo ? 'Clear Preview' : 'Preview'}
          </Button>{' '}
          <Button
            color="primary"
            variant="contained"
            // onClick={() => setRenderDemo((render) => !render)}
            onClick={async () => {
              saveChatbot({ refreshtemplate: true });
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  // create state value for choosing specific row in cta list
  const [selectedRow, setSelectedRow] = useState(null);

  // write function to handle row selection
  const handleRowSelection = (newSelection) => {
    console.log('rowSelected: ', newSelection);
    setSelectedRow(newSelection);
  };

  const deselectRow = () => {
    setSelectedRow(null);
  };

  // is valid row selected if it selectedRow is not null
  const isValidRowSelected = selectedRow !== null;

  function addNewChatBubble() {
    setChatbot([
      ...getChatbot(),
      {
        type: 'Basic',
        text: '',
        id: uuidv4(),
        condition: [[]],
        buttons: [{ label: '', route: '', href: '' }],
      },
    ]);
    handleRowSelection(getChatbot()?.length);
  }

  function deleteChatBubble(index) {
    setChatbot([
      ...getChatbot().slice(0, index),
      ...getChatbot().slice(index + 1, getChatbot().length),
    ]);
  }

  return (
    <div className="chatbot-setting-dialog min-h-screen">
      {tempConfig ? (
        <div className={`fixed z-10 w-full`}>
          <UpdateConfigAlert
            selectedDate={schedulerSelectedDate}
            onSaveClick={() => {
              setTempConfig((prev) => ({ ...prev, saveNow: true }));
            }}
            onCancelClick={() => setTempConfig(null)}
            onEditClick={() => {
              delete tempConfig.configExporterId;
              setDefaultConfig(tempConfig);
              setTempConfig(null);
            }}
          />
        </div>
      ) : null}

      <div className=" ls lg:col-span-3 flex flex-col pl-4 pt-8">
        {/* <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 mb-3">
          <li className="mr-2">
            <a
              href="#"
              className={`inline-block py-3 px-4 rounded-lg ${
                triggerTab === 'tour-bubble-offer'
                  ? ' text-white bg-blue-600 rounded-lg active'
                  : ' hover:text-gray-900 border-slate-300	border-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white '
              } `}
              aria-current="page"
              onClick={() => setTriggerTab('tour-bubble-offer')}
            >
              Tour Bubble
            </a>
          </li>

          <li className="mr-2">
            <a
              href="#"
              className={`inline-block py-3 px-4 rounded-lg ${
                triggerTab === 'tour-bottom-bar'
                  ? ' text-white bg-blue-600 rounded-lg active'
                  : ' hover:text-gray-900 border-slate-300	border-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white '
              } `}
              aria-current="page"
              onClick={() => setTriggerTab('tour-bottom-bar')}
            >
              Bottom Bar
            </a>
          </li>

          <li className="mr-2">
            <a
              href="#"
              className={`inline-block py-3 px-4 rounded-lg ${
                triggerTab === 'splash-screen'
                  ? ' text-white bg-blue-600 rounded-lg active'
                  : ' hover:text-gray-900 border-slate-300	border-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white '
              } `}
              aria-current="page"
              onClick={() => setTriggerTab('splash-screen')}
            >
              Splash Screen
            </a>
          </li>
          <li className="mr-2">
            <a
              href="#"
              className={`inline-block py-3 px-4 rounded-lg ${
                triggerTab === 'magnet-preview'
                  ? ' text-white bg-blue-600 rounded-lg active'
                  : ' hover:text-gray-900 border-slate-300	border-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white '
              } `}
              aria-current="page"
              onClick={() => setTriggerTab('magnet-preview')}
            >
              Video Magnet Preview
            </a>
          </li>

          <li className="mr-2">
            <a
              href="#"
              className={`inline-block py-3 px-4 rounded-lg ${
                triggerTab === 'cta-analytics'
                  ? ' text-white bg-blue-600 active'
                  : ' hover:text-gray-900 border-slate-300	border-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white '
              } `}
              onClick={() => setTriggerTab('cta-analytics')}
            >
              CTA Analytics
            </a>
          </li>
        </ul>
         */}
        <div className=" rounded-md">
          {triggerTab === 'tour-bubble-offer' && (
            <div>
              <div className={` grid grid-cols-2`}>
                <TourBubbleConfig
                  addNewChatBubble={addNewChatBubble}
                  deleteChatBubble={deleteChatBubble}
                  routeOpts={routeOpts}
                  magnetUuid={magnetUuid}
                  videoStartChoice={videoStartChoice}
                  handleChangeMenu={handleChangeMenu}
                  setLayoutDirection={setLayoutDirection}
                  onClickLink={onClickLink}
                  PreviewWidgetEmbed={({ uuid, buttonLabel }) => (
                    <PreviewWidgetEmbed uuid={uuid} buttonLabel={buttonLabel} />
                  )}
                  setDefaultConfig={
                    tempConfig ? setTempConfig : setDefaultConfig
                  }
                  defaultConfig={tempConfig ? tempConfig : defaultConfig}
                  shouldModifyConfig={false}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleRowSelection={handleRowSelection}
                  isValidRowSelected={isValidRowSelected}
                  deselectRow={deselectRow}
                />

                <AnimatedDialog
                  open={isValidRowSelected}
                  onClose={() => {
                    deselectRow();
                  }}
                  fullWidth
                  maxWidth="md"
                >
                  <AddBubbleSection
                    defaultConfig={tempConfig ? tempConfig : defaultConfig}
                    setDefaultConfig={
                      tempConfig ? setTempConfig : setDefaultConfig
                    }
                    addNewChatBubble={addNewChatBubble}
                    handleRowSelection={handleRowSelection}
                    saveChatbot={saveChatbot}
                    setValueIndex={setValueIndex}
                    setCondition={setCondition}
                    setconditionModal={setconditionModal}
                    setIdx={setIdx}
                    setButtonIndex={setButtonIndex}
                    onSelectImage={onSelectImage}
                    deleteSelectedImage={deleteSelectedImage}
                    addSubtitles={addSubtitles}
                    progress={progress}
                    community_id={community_id}
                    routes={routes}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    isValidRowSelected={isValidRowSelected}
                    deselectRow={deselectRow}
                  />
                </AnimatedDialog>
                <div className=" grid grid-cols-1 gap-10 w-full">
                  {/* {isValidRowSelected && (
                    <AddBubbleSection
                      defaultConfig={tempConfig ? tempConfig : defaultConfig}
                      setDefaultConfig={
                        tempConfig ? setTempConfig : setDefaultConfig
                      }
                      addNewChatBubble={addNewChatBubble}
                      handleRowSelection={handleRowSelection}
                      saveChatbot={saveChatbot}
                      setValueIndex={setValueIndex}
                      setCondition={setCondition}
                      setconditionModal={setconditionModal}
                      setIdx={setIdx}
                      setButtonIndex={setButtonIndex}
                      onSelectImage={onSelectImage}
                      deleteSelectedImage={deleteSelectedImage}
                      addSubtitles={addSubtitles}
                      progress={progress}
                      community_id={community_id}
                      routes={routes}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                      isValidRowSelected={isValidRowSelected}
                      deselectRow={deselectRow}
                    />
                  )} */}
                  {/* {(!isValidRowSelected || getChatbot()?.length === 0) &&
                    magnetUuid && (
                      <ManageSpecialsSection
                        setDefaultConfig={setDefaultConfig}
                        defaultConfig={tempConfig ? tempConfig : defaultConfig}
                        NotificationBar={NotificationBar}
                        saveChatbot={saveChatbot}
                        setchatbot={setChatbot}
                        magnetId={magnetUuid}
                      />
                    )} */}
                  <MagnetPreview
                    template={template}
                    magnetUuid={magnetUuid}
                    routeOpts={routeOpts}
                    //config={tempConfig ? tempConfig : defaultConfig}
                    //setConfig={tempConfig ? setTempConfig : setDefaultConfig}
                    config={defaultConfig}
                    setConfig={setDefaultConfig}
                    saveChatbot={saveChatbot}
                  />
                </div>
              </div>

              <PhotoUpload
                community_id={community_id}
                magnetUuid={magnetUuid}
              />

              <MagnetSettings
                community_id={community_id}
                magnet_uuid={magnetUuid}
                title="Settings"
              />
            </div>
          )}

          {triggerTab === 'tour-bottom-bar' && (
            <>
              {magnetUuid && routeOpts ? (
                <div>
                  <BottomBarConfig
                    template={template}
                    magnetUuid={magnetUuid}
                    routeOpts={routeOpts}
                    //config={tempConfig ? tempConfig : defaultConfig}
                    //setConfig={tempConfig ? setTempConfig : setDefaultConfig}
                    config={defaultConfig}
                    setConfig={setDefaultConfig}
                    saveChatbot={saveChatbot}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {triggerTab === 'magnet-preview' && (
            <>
              {magnetUuid && routeOpts ? (
                <div>
                  <MagnetPreview
                    template={template}
                    magnetUuid={magnetUuid}
                    routeOpts={routeOpts}
                    //config={tempConfig ? tempConfig : defaultConfig}
                    //setConfig={tempConfig ? setTempConfig : setDefaultConfig}
                    config={defaultConfig}
                    setConfig={setDefaultConfig}
                    saveChatbot={saveChatbot}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {triggerTab === 'splash-screen' && (
            <>
              {magnetUuid && routeOpts ? (
                <div>
                  <span className="mb-2 text-lg font-bold	">Splash Screen</span>
                  <div className="grid grid-cols-2">
                    <div>info1</div>
                    <div>info2</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {triggerTab === 'cta-analytics' && (
            <div id="analyticsTriggerTYG">
              <div className="w-full flex items-center justify-between mt-2 px-4">
                <div className=" flex items-center gap-4 mb-2">
                  <select
                    className=" border-none outline-none bg-none cursor-pointer text-sm text-gray-400 mb-2"
                    name=""
                    defaultValue={90}
                    value={filterdate.days}
                    id=""
                    onChange={(e) =>
                      setfilterdate({
                        days: e.target.value,
                        date: getISOdate(e.target.value),
                      })
                    }
                  >
                    <option value={7}>Last 7 days</option>
                    <option value={28}>Last 4 Weeks</option>
                    {/* <option value="4 weeks">4 weeks</option> */}
                    <option value={90}>3 months</option>
                    <option value={360}>1 Year</option>
                    <option value={3600}>All time</option>
                  </select>
                </div>
              </div>
              {chartOptions ? (
                <div className="w-full">
                  <Chart {...chartOptions} />
                </div>
              ) : (
                ''
              )}

              {trigger_totals ? (
                <div className=" my-2 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 border divide-x mx-2">
                  <div
                    onClick={() => sethandlefirst('open_tour')}
                    className={` w-full flex items-center justify-center flex-col cursor-pointer ${
                      handlefirst === 'open_tour' && 'bg-gray-50'
                    } py-5 hover:bg-gray-50`}
                  >
                    <h1 className=" text-4xl font-medium text-gray-500">
                      {trigger_totals?.total_clicks}
                    </h1>
                    <h1 className=" text-xl font-medium text-gray-500">
                      Open tour clicks
                    </h1>
                    <div className=" flex items-center gap-1 mt-3 text-xs">
                      {/* <div className=" bg-gray-100 w-6 h-6 flex items-center justify-center">
                      <BsArrowDownRight className=" w-5 h-5" />
                    </div>
                    -5% */}
                    </div>
                  </div>
                  <div
                    onClick={() => sethandlefirst('form_submission')}
                    className={` w-full flex items-center justify-center flex-col cursor-pointer ${
                      handlefirst === 'form_submission' && 'bg-gray-50'
                    } py-5 hover:bg-gray-50`}
                  >
                    <h1 className=" text-4xl font-medium text-gray-500">
                      {trigger_totals?.total_conversions}
                    </h1>
                    <h1 className=" text-xl font-medium text-gray-500">
                      Conversion
                    </h1>
                    <div className=" flex items-center gap-1 mt-3 text-xs">
                      {/* <div className=" bg-gray-100 w-6 h-6 flex items-center justify-center">
                      <BsArrowDownRight className=" w-5 h-5" />
                    </div>
                    -5% */}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="w-full">
                <div className=" w-full py-4 px-4 border mt-3">
                  <div className=" grid grid-cols-3 pb-2 border-b w-full">
                    <h2 className=" text-xl text-center">Triggers</h2>
                    <h2 className=" text-xl text-center">Clicks</h2>
                    <h2 className=" text-xl text-center">Conversions</h2>
                  </div>
                  {trigger_stats?.map((item) => (
                    <div
                      onClick={() => setactive_trigger_button(item.title)}
                      className={` ${
                        active_trigger_button === item.title &&
                        active_trigger_button != undefined &&
                        active_trigger_button != '' &&
                        'bg-gray-50'
                      } grid grid-cols-3 w-full py-2 hover:bg-gray-50 divide-x`}
                    >
                      <div>
                        <a
                          className=" text-xs	 border-b-4 border-indigo-500 "
                          href={`https://app.usetour.com/dashboard/${community_id}/tour#${item?.details_route}`}
                          target="_blank"
                        >
                          <OpenInNewIcon fontSize="small" />{' '}
                          {item?.details_route}
                        </a>
                        <p>{item?.title}</p>
                        <p className=" text-gray-400 py-1">
                          {`${
                            item?.details_button_subtitle != null
                              ? item?.details_button_subtitle
                              : item?.details_button_subtitle + ' | '
                          }`}{' '}
                          {item?.open_tour_type}
                        </p>
                        <div className=" flex items-center">
                          {/* <p className="flex items-center gap-1 text-gray-500 border-b cursor-pointer hover:text-pr">
                        <BiLinkExternal /> intro.main
                      </p> */}
                        </div>
                      </div>
                      <h2 className=" text-2xl text-center">{item?.clicks}</h2>
                      <h2 className=" text-2xl text-center">
                        {item?.conversions}
                      </h2>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className=" rs  flex  flex-col items-start pt-20 pr-3">
        <TriggerScheduler
          videoStartChoice={videoStartChoice}
          defaultConfig={tempConfig ? tempConfig : defaultConfig}
          setTempConfig={setTempConfig}
          tempConfig={tempConfig}
          setSchedulerSelectedDate={setSchedulerSelectedDate}
          community_id={community_id}
          magnetUuid={magnetUuid}
        />
      </div> */}
      <TriggersModal
        open={conditionModal}
        handleCloseSignOutDialog={handleCloseSignOutDialog}
        setconditionModal={setconditionModal}
        routes={routes}
        condition={condition}
        updateCodition={updateCodition}
        addConditon={addConditon}
        removeCondtion={removeCondtion}
        getChatbot={getChatbot}
        setChatbot={setChatbot}
        routeOpts={routeOpts}
        handleDeleteChip={handleDeleteChip}
        valueIndex={valueIndex}
      />
    </div>
  );
}
