import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Grid, TextField } from '@material-ui/core';
import io from 'socket.io-client';
import axios from 'axios';
import CobrowseWrapper from './cobrowse/CobrowseWrapper';
import {
  connectwithsocket,
  sendsocketmessage,
  initializeAbly,
  sendrealtimemessage,
} from './utils';
import moment from 'moment';
import CobrowseScreen from './cobrowse/CobrowseScreen';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getanalyicsbigquery } from '../../utils/api';
import InfoScreen from './cobrowse/InfoScreen';

function Engage({ magnet_uuid }) {
  const [activevisitors, setactivevisitors] = useState();
  const [channelref, setchannelref] = useState('');

  const [currentVisitorUuid, setCurrentVisitorUuid] = useState('');
  const [currentVisitorData, setCurrentVisitorData] = useState('null');

  useEffect(() => {
    let temp_activevisitors = [];
    initializeAbly(
      setchannelref,
      setactivevisitors,
      magnet_uuid,
      temp_activevisitors
    );
  }, []);

  console.log('activevisitors', activevisitors);
  return (
    <div>
      {/* <div className=" items-center align-baseline">
        {activevisitors?.map((item) => (
          <div>
            <h1>Visitor : {item?.data?.visitorId}</h1>
            <h1>
              utm_source : {item?.data?.tourUtmAndSourceParams?.utm_source}
            </h1>
            <h1>Started : {moment(item?.data?.started).format('h:mm:ss a')}</h1>
            <h1>
              Pages visited :{' '}
              {item?.data?.tourVisitHistory?.pageCt
                ? item?.data?.tourVisitHistory?.pageCt
                : 0}
            </h1>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                sendrealtimemessage(channelref, item?.data?.visitorId);
              }}
            >
              Connect
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                setCurrentVisitorUuid(item?.data?.visitorId);
              }}
            >
              View
            </Button>
          </div>
        ))}
      </div> */}
      <div className="grid" style={{ gridTemplateColumns: '300px auto' }}>
        <div>
          <UsersList
            allData={activevisitors}
            onConnect={(visitorId) => {
              sendrealtimemessage(channelref, visitorId);
            }}
            onView={(visitorId, visitorData) => {
              setCurrentVisitorUuid(visitorId);
              setCurrentVisitorData(visitorData);
            }}
            activeVisitorUuid={currentVisitorUuid}
          />
        </div>
        {/* Connection Screen */}
        <div className="w-full">
          <InfoScreen
            onCall={() => sendrealtimemessage(channelref, currentVisitorUuid)}
            visitorData={currentVisitorData}
          >
            {/* <CobrowseScreen visitorUuid={currentVisitorUuid} /> */}
            <CobrowseWrapper
              visitorUuid={currentVisitorUuid}
              setVisitorUuid={setCurrentVisitorUuid}
              currentVisitorData={currentVisitorData}
            />
          </InfoScreen>
        </div>
      </div>
    </div>
  );
}

export default Engage;

function UsersList({ allData, onConnect, onView, activeVisitorUuid }) {
  return (
    <section
      className="mt-4 my-2 mx-1 border rounded overflow-hidden bg-gray-50"
      style={{
        minWidth: 300,
        maxWidth: 300,
        width: 300,
        maxHeight: 600,
        minHeight: 600,
        height: 600,
      }}
    >
      <div className="px-3 py-3 flex items-center justify-between border-bottom bg-blue-500">
        <p className="text-lg text-white font-bold">Active Visitors</p>
        <p className="font-bold text-white rounded bg-green-400 py-1 px-2">
          {allData ? allData.length : 0}
        </p>
      </div>
      {(!allData || allData?.length == 0) && (
        <div className="w-full h-full flex items-center justify-center">
          {!allData ? <LoadingIndicator /> : 'No Users are active now !'}
        </div>
      )}
      {console.log('alldata', allData, 'allData?.length', allData?.length)}
      <div className="overflow-scroll" style={{ height: '550px' }}>
        {allData?.map((item) => {
          return (
            <UsersListItem
              data={item}
              onConnect={() => {
                onConnect(item?.data?.visitorId);
                setCurrentVisitorData(item?.data);
                setCurrentVisitorUuid(item?.data?.visitorId);
              }}
              onView={() => {
                onView(item?.data?.visitorId, item?.data);
              }}
              isActive={item?.data?.visitorId === activeVisitorUuid}
            />
          );
        })}
      </div>
    </section>
  );
}

function UsersListItem({ data, onConnect, onView, isActive }) {
  return (
    <div
      onClick={onView}
      className={`flex flex-col p-4 border-bottom ${
        isActive
          ? 'bg-blue-100'
          : 'bg-gray-50 hover:bg-gray-100 active:bg-gray-200'
      } cursor-pointer`}
    >
      <div>
        {data?.note && (
          <p>
            <span className="font-bold" style={{ color: 'green' }}>
              {data?.note}
            </span>{' '}
          </p>
        )}
        {data?.data?.userInfo?.name && (
          <p>
            <span className="font-bold">Name:</span>{' '}
            {data?.data?.userInfo?.name}
          </p>
        )}
        <p className="text-xs">
          <span className="font-bold text-base">Visitor Id:</span>{' '}
          {data?.data?.visitorId}
        </p>
        <p>
          <span className="font-bold">Utm Source:</span>{' '}
          {data?.data?.tourUtmAndSourceParams?.utm_source}
        </p>
        <p>
          <span className="font-bold">Total Visited Count:</span>{' '}
          {data?.data?.tourVisitHistory?.pageCt
            ? data?.data?.tourVisitHistory?.pageCt
            : 0}
        </p>
        <p>
          <span className="font-bold">Current Visited pages Count:</span>{' '}
          {data?.data?.currentTourHistoryObj?.uservisitHistory?.split('=>')
            ?.length
            ? data?.data?.currentTourHistoryObj?.uservisitHistory?.split('=>')
                ?.length
            : 0}
        </p>
        <p>
          <span className="font-bold">Current Visited pages History:</span>{' '}
          {data?.data?.currentTourHistoryObj?.visitHistoryArr
            ? data?.data?.currentTourHistoryObj?.visitHistoryArr?.join(' => ')
            : ''}
        </p>
        <p>
          <span className="font-bold">Current Page:</span>{' '}
          {data?.data?.currentpath ? data?.data?.currentpath : ''}
        </p>
        <p className="text-xs">
          <span className="font-bold text-base">Page Url:</span>{' '}
          {data?.data?.pageUrl ? data.data.pageUrl : ''}
        </p>
        {/* <p>
          <span className="font-bold">User location:</span>{' '}
          {visitorLocationobj
            ? `${visitorLocationobj?.city}-${visitorLocationobj.country}-${visitorLocationobj?.continent}`
            : 'Fetching Location...'}
        </p> */}
        <p>
          <span className="font-bold">Started:</span>{' '}
          {moment(new Date(data?.data?.started)).format('h:mm:ss a')}
        </p>
      </div>
      <div className="my-2 flex gap-3">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onView();
          }}
          className="border px-3 py-1 rounded-md hover:bg-gray-200 active:bg-gray-300 font-bold text-sm"
        >
          View
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onConnect();
          }}
          className="border px-3 py-1 rounded-md hover:bg-gray-200 active:bg-gray-300 font-bold text-sm"
        >
          Call
        </button>
      </div>
    </div>
  );
}
