import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import CustomHeader from '../../components/Reusable/CustomHeader';
import { getMagnetData } from '../../store/actions/magnetActions';
import { get } from '../../utils/request';
import SimpleLeads from '../videoembed/SimpleLeadsTable';
import AttributionStatsComponent from './AttributionStatsComponent';
import MapContainer from './MapContainer';
import TourAnalysisComponent from './TourAnalysisComponent';
import {
  chartOptions_init,
  count_details_from_rows,
  getentratadata,
} from './utils';
import BlackChart from './analyticsComponents/BlackChart';
import useCacheState from '../../hooks/useCacheState';
import { getSupabaseTable } from '../manage-magnets/utils';
import Notification from '../../components/Notification';
// import { motion } from 'framer-motion';
const MainAnalytics = ({ name, community_id, url, title }) => {
  const [magnetUuid, setMagnetUuid] = useCacheState(
    undefined,
    'analytics_magnetUuid',
    community_id
  );
  let { template, magnetLoading } = useSelector((state) => state.getMagnet);
  const [analyticsTab, setAnalyticsTab] = useState('leads');
  const [magnetDetails, setMagnetDetails] = useState();
  const [loading, setloading] = useState(true);
  const [leadCount, setLeadCount] = useCacheState(
    undefined,
    'analytics_leadCount',
    community_id
  );
  const [leads, setLeads] = useState();
  const [NotificationBar, setNotification] = Notification();

  // const [leadList, setLeadList] = useState(
  //   [],
  //   'analytics_leadList',
  //   community_id
  // );
  const getISOdate = (subtracteddays) => {
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - subtracteddays);
    let thedate = currDate.toISOString();
    return thedate;
  };

  const [filterdate, setfilterdate] = useState({
    days: 90,
    date: getISOdate(90),
  });

  useEffect(() => {
    if (!community_id || !url) {
      return;
    }
    async () => {
      console.log('community_id_community_id', community_id);
      getMagnetData(community_id);
    };
    getMagnet();
  }, [community_id, url, filterdate]);

  const [floorPlansJson, setfloorPlansJson] = useCacheState(
    '',
    'analytics_floorPlansJson',
    community_id
  );
  useEffect(() => {
    getentratadata(setfloorPlansJson);
  }, []);

  const getMagnet = async () => {
    const res = await get(`/magnets?queryByCommunity=${community_id}`, {
      auth: false,
    });
    console.log('resssss1', res);
    if (res?.magnets?.length == 0) {
      return;
    }
    setMagnetUuid(res.magnets?.[0].uuid);
    setMagnetDetails(res?.magnets[0]?.magnet_details?.template);
    // const localLeads = await get(`/leads?magnet_uuid=${magnetuuid}`, {
    //   auth: true,
    // });
    // setLeadList(localLeads);

    // get Leads from Supabase
    const { data, error } = await getSupabaseTable('Lead', '', {
      column_name: 'magnet_uuid',
      column_value: res.magnets?.[0].uuid,
    });
    console.log('leads indataLeads = ', error, data);
    setLeads({ leads: data });
    if (setLeadCount) {
      setLeadCount(data?.length);
    }

    setloading(false);
    return res.magnets[0].uuid;
  };
  //console.log('lengthSelection', lengthSelection, magnetUuid);
  if (loading) {
    return (
      <div className="analytics-page flex flex-col">
        <div className="tit holder" />
        <div className="duration-drop holder" />
        <div className="analytic-wrap flex">
          <div className="graph holder" />
          <div className="summary flex flex-col">
            <div className="summary-item holder" />
            <div className="summary-item holder" />
            <div className="summary-item holder" />
            <div className="summary-item holder" />
          </div>
        </div>
        <div className="analytic-wrap flex">
          <div className="graph holder" />
          <div className="graph holder" />
        </div>
      </div>
    );
  }

  // Components BEGIN  --------------------------------

  const GoalsAndPromotionsComponent = () => {
    return (
      <div className=" my-6  grid grid-col-1 lg:grid-cols-3 gap-5 px-4">
        <div>
          <h2>Let’s set goals</h2>
          <div className="relative">
            <textarea
              placeholder="Occupancy, goals, what are your available floor plans"
              className=" mt-3 w-full border bg-gray-50 text-sm text-gray-600 h-52 p-4 rounded-md"
            />
            <button
              type="submit"
              className="absolute bottom-0 right-2 text-white mb-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Save Notes
            </button>
          </div>

          <div>
            <h2 className=" text-md mt-4">Floor Plan Availabilities</h2>
            <div className=" mt-3 w-full">
              {!floorPlansJson ? (
                <button
                  disabled
                  type="button"
                  className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg  hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  Loading Floor plans...
                </button>
              ) : (
                floorPlansJson.map((item) => (
                  <div className=" flex items-center justify-between pr-4">
                    <div className=" flex items-center gap-4">
                      <img
                        className=" w-16 object-contain"
                        src={item.pic}
                        alt=""
                      />
                      <div>
                        <p className="text-sm">{item.name}</p>
                        <p className="text-sm text-gray-400">
                          Bedroom : {item.rooms.Bedroom}, Bathroom :{' '}
                          {item.rooms.Bathroom}
                        </p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-400">Unit available : </p>
                  </div>
                ))
              )}
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const inactiveTabsClasses =
    'inline-block p-4 w-full bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700';
  const activeTabsClasses =
    'inline-block p-4 w-full rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none text-gray-900 bg-gray-100 dark:bg-gray-700 dark:text-white';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Analytics"
      />

      <NotificationBar />

      <div className=" relative">
        <div className=" flex items-center justify-end w-full pr-4  mt-4 absolute z-20 top-7 left-0 ">
          <select
            style={{ background: '#1E293B' }}
            className=" border outline-none bg-none cursor-pointer text-sm text-white py-1  rounded-md mb-2 bg-black"
            name=""
            defaultValue={90}
            value={filterdate.days}
            id=""
            onChange={(e) =>
              setfilterdate({
                days: e.target.value,
                date: getISOdate(e.target.value),
              })
            }
          >
            <option value={7}>Last 7 days</option>
            <option value={28}>Last 4 Weeks</option>
            {/* <option value="4 weeks">4 weeks</option> */}
            <option value={90}>3 months</option>
            <option value={360}>1 Year</option>
            <option value={3600}>All time</option>
          </select>
        </div>
        <BlackChart
          filterdate={filterdate}
          magnetuuid={magnetUuid}
          community_id={community_id}
        />
      </div>

      <div className="sm:hidden">
        <label for="tabs" className="sr-only">
          Select your country
        </label>
        <select
          id="tabs"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option>Leads</option>
          <option>Canada</option>
          <option>France</option>
          <option>Germany</option>
        </select>
      </div>
      <ul class="hidden text-sm font-medium text-center text-gray-500 mt-6 rounded-lg divide-x divide-gray-200 shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
        <li class="w-full">
          <div
            className={
              analyticsTab === 'leads' ? activeTabsClasses : inactiveTabsClasses
            }
            aria-current="page"
            onClick={() => setAnalyticsTab('leads')}
          >
            Leads
            <span class="ml-2 px-2 inline-flex justify-center items-center   text-xs font-semibold text-blue-800 bg-blue-200 rounded-md">
              {leadCount < 0 ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 text-blue-400 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
              ) : (
                leadCount
              )}
            </span>
          </div>
        </li>
        <li class="w-full">
          <div
            className={
              analyticsTab === 'tourAnalysis'
                ? activeTabsClasses
                : inactiveTabsClasses
            }
            aria-current="page"
            onClick={() => setAnalyticsTab('tourAnalysis')}
          >
            Tour Analysis
          </div>
        </li>
        <li class="w-full">
          <div
            href=""
            className={
              analyticsTab === 'attributionStats'
                ? activeTabsClasses
                : inactiveTabsClasses
            }
            onClick={() => setAnalyticsTab('attributionStats')}
          >
            Attribution
          </div>
        </li>
        <li class="w-full">
          <div
            className={
              analyticsTab === 'map' ? activeTabsClasses : inactiveTabsClasses
            }
            onClick={() => setAnalyticsTab('map')}
          >
            Demographics
          </div>
        </li>
      </ul>

      {analyticsTab === 'leads' && (
        <SimpleLeads
          showTitle={false}
          showLeadCount={false}
          setLeadCount={setLeadCount}
          magnet_uuid={magnetUuid}
          name={name}
          leads={leads}
          setLeads={setLeads}
          setNotification={setNotification}
        />
      )}
      {analyticsTab === 'tourAnalysis' && (
        <TourAnalysisComponent
          filterdate={filterdate}
          magnetUuid={magnetUuid}
          mangetDetails={magnetDetails}
          community_id={community_id}
        />
      )}

      {analyticsTab === 'attributionStats' && (
        <div className=" w-full ">
          <AttributionStatsComponent magnetUuid={magnetUuid} url={url} />
        </div>
      )}
      {analyticsTab === 'goals' && <GoalsAndPromotionsComponent />}
      {analyticsTab === 'map' && (
        <div className=" w-full px-6 py-3">
          <div className="rounded overflow-hidden w-full shadow">
            <MapContainer magnetUuid={magnetUuid} />
          </div>
        </div>
      )}
    </>
  );
};

export default MainAnalytics;
