import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';

export function EditSplashScreenButton({
  editingSplashScreenButtonId,
  splashScreenButtons,
  setEditingSplashScreenButtonId,
  setSplashScreenButtons,
  routeOpts,
}) {
  console.log({ splashScreenButtons });
  let thisButton = splashScreenButtons[editingSplashScreenButtonId];
  const actionType = thisButton.hasOwnProperty('iframe_underlay')
    ? 'iframe_underlay'
    : thisButton.hasOwnProperty('route')
    ? 'route'
    : 'href';

  // const actionType = thisButton.hasOwnProperty("iframe_underlay") ? "iframe_underlay" : thisButton.hasOwnProperty("route") ? "route" : "href"

  return (
    <AnimatedDialog
      onClose={() => setEditingSplashScreenButtonId(null)}
      open={editingSplashScreenButtonId !== null}
    >
      <div>
        <div
          // style={{
          //   width: 500,
          // }}
          className="flex px-3 border border-gray-300   flex-col rounded-xl bg-white pt-6 pb-6 relative"
        >
          <TextField
            value={thisButton.text}
            onChange={(e) => {
              e.persist();
              setSplashScreenButtons((splashScreenButtons) => {
                return {
                  ...splashScreenButtons,
                  [editingSplashScreenButtonId]: {
                    ...splashScreenButtons[editingSplashScreenButtonId],
                    text: e.target.value,
                  },
                };
              });
            }}
            placeholder="Button Title"
          />

          <div>
            <p className="mb-1 text-gray-600">Choose an Icon</p>
            <div className="flex flex-row p-2 items-center border rounded bg-gray-50 gap-2">
              <div
                className={`${
                  thisButton.icon === 'money'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setSplashScreenButtons((splashScreenButtons) => {
                    return {
                      ...splashScreenButtons,
                      [editingSplashScreenButtonId]: {
                        ...splashScreenButtons[editingSplashScreenButtonId],
                        icon: 'money',
                      },
                    };
                  });
                }}
              >
                <DollarSign
                  className="cursor-pointer text-gray-500 hover:text-black"
                  style={{ width: 32, height: 32 }}
                />
              </div>

              <div
                className={`${
                  thisButton.icon === 'phone'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setSplashScreenButtons((splashScreenButtons) => {
                    return {
                      ...splashScreenButtons,
                      [editingSplashScreenButtonId]: {
                        ...splashScreenButtons[editingSplashScreenButtonId],
                        icon: 'phone',
                      },
                    };
                  });
                }}
              >
                <Phone
                  className="cursor-pointer text-gray-500 hover:text-black"
                  style={{ width: 32, height: 32 }}
                ></Phone>
              </div>

              <div
                className={`${
                  thisButton.icon === 'calendar'
                    ? 'bg-blue-200 p-1 rounded-xl'
                    : ''
                }`}
                onClick={() => {
                  setSplashScreenButtons((splashScreenButtons) => {
                    return {
                      ...splashScreenButtons,
                      [editingSplashScreenButtonId]: {
                        ...splashScreenButtons[editingSplashScreenButtonId],
                        icon: 'calendar',
                      },
                    };
                  });
                }}
              >
                <Calendar
                  style={{ width: 32, height: 32 }}
                  className="cursor-pointer text-gray-500 hover:text-black"
                ></Calendar>
              </div>
            </div>
          </div>

          <div className="flex flex-row border border-gray-300 w-full h-10 rounded-xl mb-5  overflow-hidden">
            <button
              onClick={() => {
                setSplashScreenButtons((splashScreenButtons) => {
                  delete splashScreenButtons[editingSplashScreenButtonId].route;
                  delete splashScreenButtons[editingSplashScreenButtonId].href;
                  return {
                    ...splashScreenButtons,
                    [editingSplashScreenButtonId]: {
                      ...splashScreenButtons[editingSplashScreenButtonId],
                      iframe_underlay: '',
                    },
                  };
                });
              }}
              style={{
                backgroundColor:
                  actionType === 'iframe_underlay' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center"
            >
              Embed Url
            </button>
            <button
              onClick={() => {
                setSplashScreenButtons((splashScreenButtons) => {
                  delete splashScreenButtons[editingSplashScreenButtonId].route;
                  delete splashScreenButtons[editingSplashScreenButtonId]
                    .iframe_underlay;
                  return {
                    ...splashScreenButtons,
                    [editingSplashScreenButtonId]: {
                      ...splashScreenButtons[editingSplashScreenButtonId],
                      href: '',
                    },
                  };
                });
              }}
              style={{
                backgroundColor: actionType === 'href' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center"
            >
              Open in New Tab
            </button>
            <button
              onClick={() => {
                setSplashScreenButtons((splashScreenButtons) => {
                  delete splashScreenButtons[editingSplashScreenButtonId].href;
                  delete splashScreenButtons[editingSplashScreenButtonId]
                    .iframe_underlay;
                  return {
                    ...splashScreenButtons,
                    [editingSplashScreenButtonId]: {
                      ...splashScreenButtons[editingSplashScreenButtonId],
                      route: routeOpts[0],
                    },
                  };
                });
              }}
              style={{
                backgroundColor: actionType === 'route' ? 'lightgray' : 'white',
              }}
              className="w-1/3 h-10 grid place-items-center"
            >
              Go to Route
            </button>
          </div>

          {actionType === 'route' ? (
            <>
              <p>Select a Route</p>

              <select
                value={splashScreenButtons[editingSplashScreenButtonId].route}
                onChange={(e) => {
                  e.persist();

                  setSplashScreenButtons((splashScreenButtons) => {
                    delete splashScreenButtons[editingSplashScreenButtonId]
                      .href;
                    delete splashScreenButtons[editingSplashScreenButtonId]
                      .iframe_underlay;
                    return {
                      ...splashScreenButtons,
                      [editingSplashScreenButtonId]: {
                        ...splashScreenButtons[editingSplashScreenButtonId],
                        route: e.target.value,
                      },
                    };
                  });
                }}
                className="mb-3 border border-gray-300 rounded-md py-2 px-1"
              >
                {routeOpts.map((opt) => {
                  return <option value={opt.route}>{opt.id}</option>;
                })}
              </select>
            </>
          ) : null}

          {actionType !== 'route' ? (
            <>
              <p>Button Destination:</p>

              <input
                defaultValue={
                  splashScreenButtons[editingSplashScreenButtonId][actionType]
                }
                onBlur={(e) => {
                  e.persist();
                  setSplashScreenButtons((splashScreenButtons) => {
                    return {
                      ...splashScreenButtons,
                      [editingSplashScreenButtonId]: {
                        ...splashScreenButtons[editingSplashScreenButtonId],
                        [actionType]: e.target.value,
                      },
                    };
                  });
                }}
                className="mb-3 border border-gray-300 rounded-md py-2 px-1"
              >
                {/* {routeOpts.map(opt => {

                                        return (<option value={opt.route}>
                                            {opt.id}
                                        </option>)
                                    })} */}
              </input>
            </>
          ) : null}

          <div className=" flex gap-4">
            <button
              className="w-1/2 bg-red-500 hover:bg-red-700 active:bg-red-800 w-full py-2 px-4 text-white rounded"
              onClick={() => {
                setEditingSplashScreenButtonId(null);
                setSplashScreenButtons((splashScreenButtons) => {
                  return {
                    ...splashScreenButtons,
                    [editingSplashScreenButtonId]: {},
                  };
                });
              }}
            >
              Delete
            </button>

            <button
              className="w-1/2 bg-blue-400 hover:bg-blue-500 active:bg-blue-800 w-full py-2 px-4 text-white rounded"
              onClick={() => {
                setEditingSplashScreenButtonId(null);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </AnimatedDialog>
  );
}

const DollarSign = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Phone = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);

const Calendar = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>
);
