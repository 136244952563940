import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const AppLoader = () => {
  return (
    <div className="app-loader flex aic">
      <CircularProgress />
    </div>
  );
};

export default AppLoader;
