import { useState } from 'react';
import ChatbotButton from './ChatbotButton';
import { CloseIcon } from './Icons';
import './ChatbotBubble.css';

const ChatbotBubble = ({
  item,
  primaryColor = '#4399DF',
  onDelete,
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      {(item.text || (item?.buttons && item?.buttons?.length > 1)) && (
        <div
          key={item.id}
          className={`initial-message-bubble ${className}`}
          style={{
            width: '280px',
            boxShadow: 'rgba(211, 211, 211, 0.326) 0px 4px 20px',
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && (
            <div className="chatbot-button-close" onClick={onDelete}>
              <CloseIcon
                height={12}
                width={12}
                style={{ position: 'static' }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginLeft: '-23px',
                position: 'relative',
                backgroundColor: '#342E2E',
                borderRadius: '100%',
                width: '35px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                color: 'white',
                justifyContent: 'center',
              }}
            >
              👋
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: '#5CCD4D',
                  width: '11px',
                  height: '11px',
                  borderRadius: '100%',
                  bottom: '0px',
                  right: '0px',
                }}
              ></div>
            </div>
            <b
              style={{
                fontFamily: 'Manrope, sans-serif',
                marginLeft: '10px',
              }}
            >
              Leasing Team
            </b>{' '}
            <span
              style={{
                color: 'lightgray',
                fontSize: '12px',
                fontFamily: 'Manrope, sans-serif',
                marginLeft: '5px',
              }}
            >
              {' '}
              · Just Now
            </span>{' '}
            <br />
          </div>
          <div className="p-2 pb-1 flex flex-col gap-2">
            <p
              style={{
                fontFamily: 'Manrope, sans-serif',
              }}
            >
              <span style={{ fontSize: '15px' }}>{item.text}</span>
            </p>

            {item.buttons.map((button) => (
              <>
                <ChatbotButton
                  onClickFunction={() => onClickLink(button, 'button-array')}
                  primaryColor={primaryColor}
                  maxWidth={'100%'}
                  title={button?.label}
                  subtitle={button?.subTitle}
                />
              </>
            ))}
          </div>
        </div>
        // <div styleName="message-text">{item.text}24</div>
      )}

      {!item.text && item.buttons && item?.buttons?.length === 1 && (
        <div
          className={`relative ${className}`}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && (
            <div
              className="chatbot-button-close"
              style={{ display: isHovered ? 'flex' : 'none', zIndex: '10' }}
              onClick={onDelete}
            >
              <CloseIcon
                height={12}
                width={12}
                style={{ position: 'static' }}
              />
            </div>
          )}
          <ChatbotButton
            onClickFunction={() => {
              onClickLink(item?.buttons[0], 'button-array');
            }}
            primaryColor={primaryColor}
            title={item?.buttons?.[0]?.label}
            subtitle={item?.buttons?.[0]?.subTitle}
            extraStyle={{
              boxShadow: 'rgba(211, 211, 211, 0.326) 1px 4px 20px',
              border: 'solid  .5px',
              backdropFilter: 'blur(4px)',
            }}
            className={className}
          />
        </div>
      )}
    </>
  );
};

export default ChatbotBubble;
