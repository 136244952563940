import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import ChipInput from 'material-ui-chip-input';
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const fieldValues = {
  url: {
    operators: ['includes', 'excludes'],
  },
  layout: {
    operators: ['isMobile', 'isDesktop'],
  },
  date: { operators: ['<=', '>='] },
  tourPixelHistory: { operators: ['>=', '>', '<'] },
};

const TriggersModal = ({
  open,
  setconditionModal,
  handleCloseSignOutDialog,
  routes,
  condition,
  updateCodition,
  addConditon,
  removeCondtion,
  getChatbot,
  setChatbot,
  routeOpts,
  handleDeleteChip,
  valueIndex,
}) => (
  <AnimatedDialog
    open={open}
    onClose={() => handleCloseSignOutDialog()}
    fullWidth
    maxWidth="md"
  >
    <div className=" p-4  w-full">
      <div className=" flex items-center justify-between border-b border-gray-300 w-full ">
        <h4 className=" pb-3 text-xl font-semibold">Conditionally Trigger</h4>
        <AiOutlineClose
          className=" w-7 h-7 cursor-pointer text-gray-600"
          onClick={() => setconditionModal(false)}
        />
      </div>
      <h1 className="  text-xl p-2 mt-2">
        Add conditions that must be met for this bubble to show:
      </h1>
      <div className="flex flex-col gap-4 p-2 border rounded-md bg-gray-50">
        {routes &&
          condition &&
          condition?.map((item, ind) => (
            <div key={item.id} className="flex w-full gap-2">
              <div className="flex-grow flex flex-col gap-2">
                <h2 className=" text-sm text-gray-500">Type*</h2>
                <div className="flex items-center gap-2">
                  <FormControl variant="outlined" className=" w-full">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={condition[ind]?.type}
                      className=" w-full"
                      onChange={(e) => {
                        updateCodition(e.target.value, ind, 'type');
                        e.target.value === 'url' &&
                          updateCodition([], ind, 'value');

                        e.target.value.length > 0 &&
                          e.target.value !== 'url' &&
                          updateCodition('', ind, 'value');
                      }}
                    >
                      <MenuItem value="tourPixelHistory">
                        Tour Pixel History
                      </MenuItem>
                      <MenuItem value="url">Url</MenuItem>
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="layout">Layout</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {condition[ind]?.type && (
                <>
                  {condition[ind]?.type === 'tourPixelHistory' && (
                    <div className="flex-grow flex flex-col gap-2">
                      <h2 className=" text-sm text-gray-500">Route*</h2>
                      {condition[ind]?.type === 'tourPixelHistory' && (
                        <FormControl variant="outlined" className=" w-full">
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined1"
                            value={condition[ind].route}
                            onChange={(e) =>
                              updateCodition(e.target.value, ind, 'route')
                            }
                            className=" w-full"
                          >
                            {routeOpts.map(({ id, route, video }) => (
                              <MenuItem
                                key={id + route + video}
                                value={route + '|' + video}
                              >
                                {id}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  )}

                  <div className="flex-grow flex flex-col gap-2">
                    <h2 className=" text-sm text-gray-500">Operator*</h2>
                    <FormControl variant="outlined" className=" w-full">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={condition[ind].operator}
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'operator')
                        }
                        className=" w-full"
                      >
                        {fieldValues[condition[ind]?.type]?.operators?.map(
                          (op) => (
                            <MenuItem value={op}>{op}</MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>

                  {/* -===- Value field -===- */}
                  {condition[ind]?.type !== 'layout' && (
                    <div
                      className="flex-grow flex-col flex gap-2"
                      style={{ maxWidth: '40%' }}
                    >
                      <h2 className=" text-sm text-gray-500">Value*</h2>
                      {condition[ind]?.type === 'tourPixelHistory' ? (
                        <input
                          style={{ padding: '15px 0' }}
                          className=" px-2 bg-transparent outline-none  w-full border rounded-md"
                          onChange={(e) =>
                            updateCodition(e.target.value, ind, 'value')
                          }
                          value={
                            Array.isArray(condition[ind].value)
                              ? ''
                              : condition[ind].value
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      ) : condition[ind]?.type === 'url' ? (
                        <ChipInput
                          variant="outlined"
                          // style={{ height: '57px' }}
                          blurBehavior="add"
                          value={
                            Array.isArray(condition[ind].value)
                              ? condition[ind].value.map((item) => item)
                              : []
                          }
                          onAdd={(chip) => {
                            console.log('adding chip', chip);
                            updateCodition(
                              [...condition[ind].value, chip],
                              ind,
                              'value'
                            );
                          }}
                          onDelete={(chip, index) =>
                            handleDeleteChip(chip, index, ind)
                          }
                        />
                      ) : condition[ind]?.type === 'date' ? (
                        <input
                          style={{ padding: '15px 0' }}
                          className="px-2 bg-transparent outline-none w-full border rounded-md"
                          onChange={(e) =>
                            updateCodition(e.target.value, ind, 'value')
                          }
                          value={
                            Array.isArray(condition[ind].value)
                              ? ''
                              : condition[ind].value
                          }
                          type="datetime-local"
                          name=""
                          id=""
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              )}
              <button className="self-center px-3 py-3 bg-gray-50 border text-sm hover:bg-gray-200 text-gray-500">
                <AiOutlineDelete
                  onClick={() => removeCondtion(ind)}
                  className="w-5 h-5 cursor-pointer text-gray-500"
                />
              </button>
            </div>
          ))}
        {!condition?.length && 'No triggers.'}
      </div>
      <div className=" flex items-center justify-end w-full mt-3">
        <button
          onClick={() => addConditon()}
          className=" flex items-center gap-1 bg-gray-700 text-sm px-3 py-1 rounded-md text-white  mr-2 hover:opacity-80"
        >
          <AiOutlinePlus className=" w-5 h-5" />
          Add
        </button>
        <button
          onClick={() => {
            console.log('clickedd');
            let copyChatbot = [...getChatbot()];
            copyChatbot[valueIndex].condition = [condition];
            setChatbot([...copyChatbot]);
            handleCloseSignOutDialog();
          }}
          className=" flex items-center gap-1 text-sm bg-pr  px-3 py-1 rounded-md text-white"
        >
          Save
        </button>
      </div>
    </div>
  </AnimatedDialog>
);
export default TriggersModal;
