import axios from 'axios';
import { saveLeadinDB } from '../utilities';

export const getProperty = async (integrationId) => {
  try {
    // const data = await getEntrataData(integrationId);
    // console.log("data",data)
    const query = new URLSearchParams(window.location.search);
    const entratauser = query.get('entratauser');
    const entratapass = query.get('entratapass');
    const entratapropertyId = query.get('entratapropertyId');
    let entratadomain = query.get('entratadomain');
    let creds = {
      username: entratauser,
      password: entratapass,
    };
    if (entratadomain) {
      creds['entratadomain'] = entratadomain;
    }
    const res = await axios.post(
      `https://api.leasemagnets.com/integrations/entrata/getPropertyinfo`,
      {
        creds: creds,
        propertyId: entratapropertyId,
      }
    );
    console.log(res);
    return res?.data?.resp?.result?.PhysicalProperty?.Property[0];
  } catch (err) {
    console.log(err);
    console.log('getPropertyinfo.error', err);
  }
};

export const getAvailablePropertyHours = (selectedDate, propertyInfo) => {
  const allHours = [
    '12:00 AM',
    '01:00 AM',
    '02:00 AM',
    '03:00 AM',
    '04:00 AM',
    '05:00 AM',
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
  ];
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  let theDay = '';
  if (selectedDate) {
    theDay = weekDays[new Date(selectedDate).getDay()];
    let selectedDay = {};
    propertyInfo?.PropertyHours?.OfficeHours?.OfficeHour?.map((item) => {
      if (item.Day == theDay) {
        selectedDay = item;
      }
    });
    let availHours = [];
    const query = new URLSearchParams(window.location.search);
    const start = query.get('start');
    const end = query.get('end');
    if (start && end) {
      selectedDay['OpenTime'] = allHours[start];
      selectedDay['CloseTime'] = allHours[end];
    }
    console.log('selectedDay', selectedDay);

    const startindex = allHours.findIndex((item) => {
      return (
        `${Number(selectedDay?.OpenTime?.split(':')[0])}:${
          selectedDay?.OpenTime?.split(':')[1]
        }` === `${Number(item?.split(':')[0])}:${item?.split(':')[1]}`
      );
    });

    const endindex = allHours.findIndex((item) => {
      return (
        `${Number(item?.split(':')[0])}:${item?.split(':')[1]}` ===
        `${Number(selectedDay?.CloseTime?.split(':')[0])}:${
          selectedDay?.CloseTime?.split(':')[1]
        }`
      );
    });

    allHours.map((item, index) => {
      if (index >= startindex && index <= endindex) {
        availHours.push(item);
      }
    });
    return availHours;
  }
};

export const bookAppointment = async (
  uuid,
  obj,
  userInfo,
  tourType,
  onError
) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const entratauser = query.get('entratauser');
    ///const uuid = query.get('uuid');
    const entratapass = query.get('entratapass');
    const entratapropertyId = query.get('entratapropertyId');
    let data = {};
    if (entratauser && entratapass && entratapropertyId && uuid) {
      data = {
        username: entratauser,
        password: entratapass,
        property_id: entratapropertyId,
      };
    } else {
      //data = await getEntrataData(integrationId);
    }
    let form_data = {
      source: 'cta_tour_mini_app',
      lead_name: `${userInfo.firstname} ${userInfo.lastname}`,
      lead_phone: `+1${userInfo.phone}`,
      lead_email: userInfo.email,
      first_name: userInfo.firstname,
      last_name: userInfo.lastname,
      email: userInfo.email,
      phone: `+1${userInfo.phone}`,
      ...obj,
    };
    console.log('bookAppointment_obj', form_data);
    saveLeadinDB(uuid, {
      form_data,
      form_route: null,
      video_time: 0,
    });
    return true;
  } catch (err) {
    console.log(err);
  }
};
