import axios from 'axios';
import { API_HOST } from '../../../utils/request';
import { getSupabaseRow } from '../../manage-magnets/utils';
// Floorplan Details
export let floorPlan = [
  {
    id: 1,
    title: 'How many bedrooms do you need?',
    options: [
      { label: 'Any', value: 0 },
      //  { label: 'Studio', value: 'studio' },
      { label: '1 Bed', value: 1 },
      { label: '2 Bed', value: 2 },
    ],
  },
  {
    id: 2,
    title: 'How many bathrooms do you need?',
    options: [
      { label: 'Any', value: 0 },
      { label: '1 Bath', value: 1 },
      { label: '2 Bath', value: 2 },
    ],
  },
];

export const getNumberFromString = (str) => {
  var thenumber = str.match(/(\d+)/);
  console.log('str', str);
  return thenumber ? thenumber : 0;
};

export const generateOptionsFromString = (str, index, title) => {
  console.log('str_str', str);
  let options = [];
  str?.split(',')?.map((item, index) => {
    options?.push({
      label: item,
      value: Number(getNumberFromString(item)?.[0]) || 0,
    });
  });
  return {
    id: index + 1,
    title,
    options,
  };
};

export const floorPlanMultiSelector = [
  {
    id: 1,
    title: 'Are You Intered In?',
    options: [
      { label: 'Roommate Matching', value: 0 },
      { label: 'Fitness Room', value: 1 },
      { label: 'Student Events', value: 2 },
      { label: 'Life Style', value: 3 },
    ],
  },
];

export const getFloorplans = async (
  credentials,
  otherparams,
  entrataname = 'getMitsPropertyUnits'
) => {
  console.log('otherparams', otherparams);
  try {
    const res = await axios.post(
      `${API_HOST}/integrations/entrata/${entrataname}`,
      {
        creds: {
          username: credentials.entratauser,
          password: credentials.entratapass,
          //domain: credentials.domain,
        },
        propertyId: credentials.entratapropertyId,
        otherparams,
      }
    );
    console.log('getFloorplans', res);
    return res.data.resp;
  } catch (err) {
    console.log('[getfloorplans]', err);
  }
};

export const getFloorplansFromsupabase = async (propertyId) => {
  try {
    const res = await getSupabaseRow('EntrataCache', '*', {
      column_name: 'property_id',
      column_value: propertyId,
    });
    console.log('getFloorplansFromsupabase', res);
    return res?.data?.[0]?.floorplans;
  } catch (err) {
    console.log('[getfloorplans]', err);
  }
};

export const formatFloorPlans = (floorPlans, ilsUnits) => {
  return floorPlans?.map((item, idx) => {
    const pic = item?.File?.map((file, fidx) => {
      if (file?.Src.includes('.jpg') || file?.Src.includes('.png')) {
        return file?.Src;
      }
      return null;
    }).filter((c) => c !== null);

    let roomTypeJson = {};
    item?.Room?.map((room, ridx) => {
      roomTypeJson[room?.['@attributes'].RoomType] = room?.Count;
    });

    // let dynamicItems = ilsUnits
    //   ?.map((unit, uidx) => {
    //     if (item?.Name && item?.Name == unit?.Units.Unit.FloorplanName) {
    //       if (unit?.Availability?.VacancyClass == 'Unoccupied') {
    //         return unit?.Availability?.VacateDate['@attributes'];
    //       }
    //     }
    //     return null;
    //   })
    //   .filter((c) => c !== null);

    let itemObj = {
      name: item?.Name,
      rents: item?.MarketRent['@attributes'],
      unitsAvailable: item?.UnitsAvailable,
      sqFt: item?.SquareFeet['@attributes'],
      pic: pic?.[0],
      info: item?.FloorplanAvailabilityURL,
      rooms: roomTypeJson,
      available: item?.UnitsAvailable > 0,
      Concession: item?.Concession,
    };
    return itemObj;
  });
};

// Filters floorplans accoriding to bedroom, bathroom and date
export const filterFloorPlans = (
  floorPlans,
  selectedDate,
  selectedBedroom,
  selectedBathroom,
  rentRange
) => {
  let matchedfloorplans = [];
  let unmatchedfloorplans = [];
  console.log('floorPlans_floorPlans', floorPlans),
    floorPlans?.filter((item, index) => {
      /// check bedroom
      let bedroomstatus;
      let bathroomstatus;
      let datestatus;
      let unitsAvailable;
      let rentmatchedstatus;

      if (item?.unitsAvailable > 0) {
        unitsAvailable = true;
      }

      // check bedroom
      if (selectedBedroom == 0 || undefined) {
        bedroomstatus = true;
      } else if (item.rooms.Bedroom === selectedBedroom) {
        bedroomstatus = true;
      }
      // check bathroom
      if (selectedBathroom == 0 || undefined) {
        bathroomstatus = true;
      } else if (item.rooms.Bathroom === selectedBathroom) {
        bathroomstatus = true;
      }

      if (
        rentRange[0] <= item?.rents?.Max &&
        rentRange[1] >= item?.rents?.Max
      ) {
        rentmatchedstatus = true;
      }
      // check datestatus
      // if (item.available) {
      //   if (
      //     new Date(
      //       `${item.available.Month}-${item.available.Day}-${item.available.Year}`
      //     ).getTime() <= selectedDate.getTime()
      //   ) {
      datestatus = true;
      //   }
      // } else {
      //   datestatus = false;
      // }
      //console.log('statusss', bedroomstatus, bathroomstatus, datestatus);
      console.log('status_status', {
        item,
        rentRange,
        bedroomstatus,
        bathroomstatus,
        datestatus,
        unitsAvailable,
        rentmatchedstatus,
      });
      if (
        bedroomstatus &&
        bathroomstatus &&
        datestatus &&
        unitsAvailable &&
        rentmatchedstatus
      ) {
        matchedfloorplans.push(item);
      } else {
        unmatchedfloorplans.push(item);
      }
    });
  return {
    matchedfloorplans,
    unmatchedfloorplans,
  };
};

export const getProperty = async (credentials) => {
  try {
    const res = await axios.post(
      `https://api.leasemagnets.com/integrations/entrata/getPropertyinfo`,
      {
        creds: {
          username: credentials.entratauser,
          password: credentials.entratapass,
        },
        propertyId: credentials.entratapropertyId,
      }
    );
    return res?.data?.resp?.result?.PhysicalProperty?.Property[0];
  } catch (err) {
    console.log('[getProperty]', err);
  }
};
