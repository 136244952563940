/*
This page manages a specific Screen's settings, including updating
Name, Caption, Form Settings, Additional Information and more

handleScreen  usage:             handleScreen( 'updateScreen'/'addScreen'/removeScreen', name, caption, ... metadata={anything you want to include} ); | Originally written on MagnetSummary

Special Functions:
createScreenKey (turn the name into a screenKey on acct creation)
CheckIfEligible is the validation function run before

*/
import { Button, LinearProgress, TextField } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FaPlay, FaWpforms } from 'react-icons/fa';
import { ImEmbed2 } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../../components/LoadingIndicator';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import SimpleAccordion from '../../components/SimpleAccordion';
import { BasicFileUploadComponent } from '../../utils/files';
import { post } from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import AdditionalInfo from './advanced-settings/AdditionalInfo';
import FormSettings from './advanced-settings/FormSettings';
import IframeSettings from './advanced-settings/IframeSettings';
import Settings from './advanced-settings/Settings';
import ShareSettings from './advanced-settings/ShareSettings';
import MagnetPathRow from './MagnetPathRow';
import MagnetPopup from './MagnetPopup';
import './UploadForm.css';

const filter = createFilterOptions();

const UploadForm = ({
  community_id,
  category,
  screen,
  currVid,
  screenObj,
  categoryTitle,
  screenTitle,
  setLoadScreenTextIndex,
  loadScreenTextIndex,

  handleUpdateScreen,
  handleCreateScreen,
  newUploadHasStarted,
  setNewUploadHasStarted,
  buttons,
  centerButtons,
  bottomButtons,
  setButtons,
  setCenterButtons,
  setBottomButtons,
  setThumbnailImg,
  thumbnailImg,
  caption,
  setCaption,
  location,
  setLocation,
  formTemplate,
  setFormTemplate,
  iframeOptions,
  setIframeOptions,
  shareInfo,
  setShareInfo,
  handleUpdateProjectID,
  projectID,
  definedProjectID,
  name,
  setName,
  cloudLibrary,
  setCloudLibrary,
  setCurrVid,
  cloudLibraryProgress,
  setCloudLibraryProgress,
  setButtonsIsDragging,
}) => {
  // Theme
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#8cc6d9',
      },
      secondary: {
        main: '#3898ec',
      },
    },
  });

  const _dispatch = useDispatch();
  const { loadScreen, magnetId, magnetType, previewTemplate } = useSelector(
    (state) => state.magnetStates
  );
  const { template } = useSelector((state) => state.getMagnet);
  const [buttonloading, setbuttonloading] = useState(false);
  const getScreenByRoute = (category, screen) =>
    template?.categories?.[category]?.screens?.[screen];

  const getScreenVideo = ([category, screen]) =>
    getScreenByRoute(category, screen)?.video;

  const getScreenLinks = ([category, screen]) =>
    getScreenByRoute(category, screen)?.links;

  const PrettyPrintJson = ({ data }) => {
    // (destructured) data could be a prop for example
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  const stateRef = useRef();
  const centerRef = useRef();
  const bottomRef = useRef();
  stateRef.current = buttons ? [...buttons] : [];
  centerRef.current = centerButtons?.links ? [...centerButtons.links] : [];
  bottomRef.current = bottomButtons?.links ? [...bottomButtons.links] : [];

  // Keep track of selected routes
  const [routes, setRoutes] = useState();
  const [hrefRoute, setHrefRoute] = useState();
  const [routeOpts, setRouteOpts] = useState();
  const [deletescreenloading, setdeletescreenloading] = useState(false);
  const [linksTab, setLinksTab] = useState('links');
  const [linksCloneTab, setLinksCloneTab] = useState('links');
  const [cloneInputValue, setCloneInputValue] = useState('');

  const [buttonName, setButtonName] = useState(null);

  const [additionalInformation, setAdditionalInformation] = useState({
    price: 0,
    features: [],
    moveInDate: moment().format('YYYY-MM-DD'),
    detailedDescription: '',
    shareCaption: '',
    images: [],
  });
  const [showCloneButton, setShowCloneButton] = useState(false);

  useEffect(() => {
    // Generate all possible link routes
    setRouteOpts(generateRoutes(template));
    // Generate all existing links
    if (screen !== 'new') {
      const buttonOptsTemp = [];
      const centerButtonOptsTemp = [];
      const bottomButtonOptsTemp = [];
      const tempLink = template?.categories[category]?.screens[screen];

      setButtons(tempLink?.links);
      setCenterButtons(tempLink?.centerLinks);
      setBottomButtons(tempLink?.bottomLinks);
      // loading thumbnail
      setThumbnailImg(tempLink?.img);
    }

    // Populate features in additionalInformation
    if (template?.magnetSettings?.features) {
      setAdditionalInformation({
        ...additionalInformation,
        features: template.magnetSettings.features,
      });
    }

    // Populate additional information
    if (screenObj && screenObj.additionalInfo !== null) {
      const tempDict = {};

      for (const k of Object.keys(additionalInformation)) {
        if (
          screenObj.additionalInfo &&
          Object.keys(screenObj.additionalInfo).includes(k)
        ) {
          tempDict[k] = screenObj.additionalInfo[k];
        } else {
          tempDict[k] = additionalInformation[k];
        }
      }
      setAdditionalInformation(tempDict);
    }

    // Populate all the form input templates
    if (screenObj?.form) {
      const tempDict = {};
      for (const k of Object.keys(formTemplate)) {
        if (k in screenObj.form) {
          tempDict[k] = screenObj.form[k];
        } else {
          tempDict[k] = formTemplate[k];
        }
      }
      setFormTemplate(tempDict);
    }

    // Populate iframe options
    if (screenObj?.iframe) {
      const tempDict = {};
      for (const k of Object.keys(iframeOptions)) {
        if (k in screenObj.iframe) {
          tempDict[k] = screenObj.iframe[k];
        } else {
          tempDict[k] = iframeOptions[k];
        }
      }
      setIframeOptions(tempDict);
    }

    // Populate sharing options (no default sharing options to set)
    if (screenObj?.share_info) {
      setShareInfo(screenObj.share_info);
    }

    // Set Links Tab to be the tab in which there is the most links to
    // Set Links Tab to be the tab in which there is the most links to

    console.log(
      'attempt to compare: ',
      screenObj?.centerLinks?.pageEnabled,
      screenObj?.centerLinks?.links?.length || 0,
      screenObj?.links?.length || 0
    );
    if (
      screenObj?.centerLinks?.pageEnabled &&
      (screenObj?.centerLinks?.links?.length || 0) >
        (screenObj?.links?.length || 0)
    ) {
      console.log('attempt set to center');
      setLinksTab('CenterLinks');
    }
    if (
      screenObj?.bottomLinks?.links?.length ||
      0 > screenObj?.centerLinks?.links?.length ||
      0
    ) {
      setLinksTab('BottomLinks');
    }
  }, [template]);

  const [isButtonSeprated, setIsButtonSeprated] = useState('');
  console.log('isButtonSeprated', isButtonSeprated);
  // useEffect(() => {
  //   setCurrVid(cloudLibrary[0])
  // }, [])

  // Add button name and tour path
  useEffect(() => {
    if (screenObj?.video) {
      let url = new URL(screenObj?.video);
      var isButtonSepratedvalue = url.searchParams.get('isButtonSeprated');
      console.log('the_dataa', url, isButtonSepratedvalue);
      if (isButtonSepratedvalue) {
        setIsButtonSeprated(isButtonSepratedvalue == 'true' ? true : false);
      }
    }
  }, []);
  function addPath() {
    if (linksTab === 'links') {
      if (routeAdderType == 1) {
        if (hrefRoute && buttonName) {
          setButtons((prevList) => [
            ...prevList,
            {
              name: buttonName,
              href: hrefRoute,
              id: buttonName,
            },
          ]);
        }
      }
      if (routeAdderType == 0) {
        if (!routes) return;
        setButtons((prevList) => [
          ...prevList,
          {
            name: buttonName || routes.route,
            route: routes.route,
            id: routes.id,
          },
        ]);
      }
      setButtonName(null);
    }
    if (linksTab === 'CenterLinks') {
      if (routeAdderType == 1) {
        if (hrefRoute && buttonName) {
          setCenterButtons({
            ...centerButtons,
            links:
              centerButtons?.links.length > 0
                ? [
                    ...centerButtons.links,
                    {
                      name: buttonName,
                      href: hrefRoute,
                      id: buttonName,
                    },
                  ]
                : [
                    {
                      name: buttonName,
                      href: hrefRoute,
                      id: buttonName,
                    },
                  ],
          });
        }
      }
      if (routeAdderType == 0) {
        if (!routes) return;
        setCenterButtons({
          ...centerButtons,
          links:
            centerButtons?.links.length > 0
              ? [
                  ...centerButtons.links,
                  {
                    name: buttonName || routes.route,
                    route: routes.route,
                    id: routes.id,
                  },
                ]
              : [
                  {
                    name: buttonName || routes.route,
                    route: routes.route,
                    id: routes.id,
                  },
                ],
        });
      }
      setButtonName(null);
    }
    if (linksTab === 'BottomLinks') {
      if (routeAdderType == 1) {
        if (hrefRoute && buttonName) {
          setBottomButtons({
            ...bottomButtons,
            links:
              bottomButtons?.links?.length > 0
                ? [
                    ...bottomButtons.links,
                    {
                      name: buttonName,
                      href: hrefRoute,
                      id: buttonName,
                    },
                  ]
                : [
                    {
                      name: buttonName,
                      href: hrefRoute,
                      id: buttonName,
                    },
                  ],
          });
        }
      }
      if (routeAdderType == 0) {
        if (!routes) return;
        setBottomButtons({
          ...bottomButtons,
          links:
            bottomButtons?.links?.length > 0
              ? [
                  ...bottomButtons.links,
                  {
                    name: buttonName || routes.route,
                    route: routes.route,
                    id: routes.id,
                  },
                ]
              : [
                  {
                    name: buttonName || routes.route,
                    route: routes.route,
                    id: routes.id,
                  },
                ],
        });
      }
      setButtonName(null);
    }
  }

  // Delete button
  const deletePath = (idxToDelete) => {
    if (linksTab === 'links') {
      setButtons((buttons) => buttons?.filter((_, i) => i !== idxToDelete));
      return;
    }

    if (linksTab === 'CenterLinks') {
      setCenterButtons({
        ...centerButtons,
        links: centerButtons?.links?.filter((_, i) => i !== idxToDelete),
      });
      return;
    }
    if (linksTab === 'BottomLinks') {
      setBottomButtons({
        ...bottomButtons,
        links: bottomButtons?.links?.filter((_, i) => i !== idxToDelete),
      });
      return;
    }
  };

  const editPhoto = (btnIdx, url, name) => {
    if (linksTab === 'links') {
      const tempBtns = [...buttons];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      if (url) {
        btnToEdit.img = url;
      }
      setButtons(tempBtns);
      return;
    }

    if (linksTab === 'CenterLinks') {
      const tempBtns = [...centerButtons.links];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      if (url) {
        btnToEdit.img = url;
      }
      setCenterButtons((prevState) => ({
        ...prevState,
        links: tempBtns,
      }));
      return;
    }
    if (linksTab === 'BottomLinks') {
      const tempBtns = [...bottomButtons.links];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      if (url) {
        btnToEdit.img = url;
      }
      setBottomButtons((prevState) => ({
        ...prevState,
        links: tempBtns,
      }));
      return;
    }
  };

  // Edit existing button's name
  function editPath(btnIdx, newName, newRoute, newHref) {
    if (linksTab === 'links') {
      // Create temp buttons
      const tempBtns = [...buttons];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      // Change name
      if (name) {
        btnToEdit.name = newName;
      }
      // Change route
      if (newRoute) {
        btnToEdit.id = newRoute.id;
        btnToEdit.route = newRoute.route;
      }
      // Change href
      if (newHref) {
        btnToEdit.href = newHref;
      }
      // Update buttons
      setButtons(tempBtns);
      return;
    }

    if (linksTab === 'CenterLinks') {
      // Create temp buttons
      const tempBtns = [...centerButtons?.links];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      // Change name
      if (name) {
        btnToEdit.name = newName;
      }
      // Change route
      if (newRoute) {
        btnToEdit.id = newRoute.id;
        btnToEdit.route = newRoute.route;
      }
      // Change href
      if (newHref) {
        btnToEdit.href = newHref;
      }
      // Update buttons
      setCenterButtons({
        ...centerButtons,
        links: tempBtns,
      });
      return;
    }
    if (linksTab === 'BottomLinks') {
      // Create temp buttons
      const tempBtns = [...bottomButtons.links];
      // Get button by id
      const btnToEdit = tempBtns.find((btn, idx) => idx === btnIdx);
      // Change name
      if (name) {
        btnToEdit.name = newName;
      }
      // Change route
      if (newRoute) {
        btnToEdit.id = newRoute.id;
        btnToEdit.route = newRoute.route;
      }
      // Change href
      if (newHref) {
        btnToEdit.href = newHref;
      }
      // Update buttons
      setBottomButtons({
        ...bottomButtons,
        links: tempBtns,
      });
      return;
    }
  }

  console.log('centerButtons?.links', centerButtons?.links);

  // BUTTONS END

  /* Screen Elements end */
  function smartAlert(
    messageRaw = 'Please wait, updating/uploading/creating...',
    msgColor = 'warning',
    openVal = true
  ) {
    setAlertMessage(messageRaw);
    setAlertColor(msgColor);
    setOpen(openVal);
  }

  // Frontend validation to ensure that an update or create can be made

  // update the Route options and category options based on the template

  useEffect(() => {
    setName('');
    if (screen !== 'new') {
      setName(template?.categories[category].screens[screen].title);
    }

    setCaption('');
    if (screen !== 'new') {
      setCaption(template?.categories[category].screens[screen].caption);
    }

    setLocation('');
    if (screen !== 'new') {
      setLocation(template?.categories[category].screens[screen].location_card);
    }
  }, []);

  // alert control
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(
    'Please wait, updating/uploading/creating...'
  );
  const [alertColor, setAlertColor] = useState('warning');

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
    window.history.pushState({}, `View ${name}`, `#`);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Time for loading Screen
  const Timer = ({ seconds }) => {
    // initialize timeLeft with the seconds prop
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
      // exit early when we reach 0
      if (!timeLeft) return;

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft]);

    return (
      <center>
        <span>about {timeLeft} seconds left</span>
      </center>
    );
  };

  let templateName = '';
  let templateLocationCard = '';

  if (screen !== 'new') {
    templateName = template?.categories[category]?.screens[screen]?.title;
    templateLocationCard =
      template?.categories[category]?.screens[screen]?.location_card;
  }

  const templateCategory = category;

  const [routeAdderType, setRouteAdderType] = useState(0);

  const moveMagnetPathRow = useCallback(
    (dragIndex, hoverIndex) => {
      const buttonsTemp =
        (linksTab === 'links' && [...stateRef.current]) ||
        (linksTab === 'CenterLinks' && [...centerRef.current]) ||
        (linksTab === 'BottomLinks' && [...bottomRef.current]);
      const dragMagnetPathRow = buttonsTemp[dragIndex];
      buttonsTemp.splice(dragIndex, 1);
      buttonsTemp.splice(hoverIndex, 0, dragMagnetPathRow);
      stateRef.current = [...buttonsTemp];
      if (linksTab === 'links') {
        setButtons(buttonsTemp);
      }
      if (linksTab === 'CenterLinks') {
        setCenterButtons((prevState) => ({
          ...prevState,
          links: buttonsTemp,
        }));
      }
      if (linksTab === 'BottomLinks') {
        setBottomButtons((prevState) => ({
          ...prevState,
          links: buttonsTemp,
        }));
      }
    },
    [buttons, centerButtons?.links, bottomButtons?.links, linksTab]
  );

  const [editButtonName, setEditButtonName] = useState(false);
  const [editVideoPath, setEditVideoPath] = useState(false);

  document.addEventListener(
    'keydown',
    function (e) {
      if (
        e.keyCode == 83 &&
        (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault(); // Process event...

        if (screen !== 'new') {
          if (definedProjectID) {
            additionalInformation['definedProjectID'] = definedProjectID;
          }
          additionalInformation['projectID'] = projectID;
          handleUpdateScreen(
            thumbnailImg,
            centerButtons,
            bottomButtons,
            name,
            caption,
            location,
            buttons,
            screen,
            category,
            formTemplate,
            additionalInformation,
            iframeOptions,
            shareInfo,
            screenTitle,
            categoryTitle,
            cloudLibrary,
            setCloudLibrary,
            setCloudLibraryProgress
          );
          smartAlert('Please wait, updating/uploading/creating...', 'warning');
        } else {
          if (definedProjectID) {
            additionalInformation['definedProjectID'] = definedProjectID;
          }
          additionalInformation['projectID'] = projectID;
          handleCreateScreen(
            thumbnailImg,
            name,
            caption,
            location,
            buttons,
            centerButtons,
            bottomButtons,
            screen,
            category,
            formTemplate,
            additionalInformation,
            iframeOptions,
            shareInfo,
            screenTitle,
            categoryTitle,
            cloudLibrary,
            setCloudLibrary,
            setCloudLibraryProgress
          );
          smartAlert('Please wait, updating/uploading/creating...', 'warning');
        }
      }
    },
    false
  );

  const [formTab, setFormTab] = useState('Video');

  useEffect(() => {
    if (screenObj?.form?.enabled) {
      setFormTab('Form');
    }
    if (screenObj?.iframe?.enabled || iframeOptions?.enabled) {
      setFormTab('Embed App');
    }
  }, []);

  const [tabsItms, setTabsItems] = useState([
    { label: 'Video' },
    { label: 'Form' },
    { label: 'Embed App' },
    // { label: 'Sharing Setting' },
    // { label: 'Tour Page' },
  ]);

  const [prevLinks, setPrevLinks] = useState();

  const _cloneLinks = () => {
    if (linksTab === 'links') {
      setButtons((prevList) => [...prevList, ...prevLinks]);
    }
    if (linksTab === 'CenterLinks') {
      setCenterButtons({
        ...centerButtons,
        links: [...centerButtons?.links, ...centerButtons],
      });
    }
    if (linksTab === 'BottomLinks') {
      setBottomButtons({
        ...bottomButtons,
        links: [...bottomButtons.links, ...bottomButtons],
      });
    }

    setShowCloneButton(false);
  };

  const _cloneLinks2 = (linksObj) => {
    if (linksTab === 'links') {
      setButtons((prevList) =>
        prevList ? [...prevList, ...linksObj] : [...linksObj]
      );
    }
    if (linksTab === 'CenterLinks') {
      setCenterButtons({
        ...centerButtons,
        links: centerButtons?.links
          ? [...centerButtons?.links, ...linksObj]
          : [...linksObj],
      });
    }
    if (linksTab === 'BottomLinks') {
      setBottomButtons({
        ...bottomButtons,
        links: bottomButtons?.links
          ? [...bottomButtons.links, ...linksObj]
          : [...linksObj],
      });
    }

    setShowCloneButton(false);
  };

  const handleChangeCheckbox = (linkType, e) => {
    const { name, checked } = e.target;
    if (linkType === 'CenterLinks') {
      setCenterButtons((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setBottomButtons((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  // when linkCloneTab changes this useEffect will run
  useEffect(() => {
    cloneInputValue && handleCloneLinks();
  }, [linksCloneTab]);

  const handleCloneLinks = (value) => {
    const [cata, scr] = value
      ? value?.route.split('.')
      : cloneInputValue?.route.split('.');
    let temp = template?.categories?.[cata]?.screens?.[scr];
    // setPrevLinks(links);
    console.log('temp: ', { temp, linksTab });
    if (temp) {
      if (linksCloneTab === 'links') {
        setPrevLinks(temp?.links || []);
        console.log('linkTest:', temp?.links);
        return;
      }
      if (linksCloneTab === 'CenterLinks') {
        setPrevLinks(temp?.centerLinks?.links || []);
        console.log('linkTest:', temp?.centerLinks?.links);
        return;
      }
      if (linksCloneTab === 'BottomLinks') {
        setPrevLinks(temp?.bottomLinks?.links || []);
        console.log('linkTest:', temp?.bottomLinks?.links);
        return;
      }
      setPrevLinks([]);
      return;
    }
  };

  // useEffect(() => {
  // if(isButtonSeprated){
  //   let svideo = screenObj?.video + `?isButtonSeprated=${isButtonSeprated}`;
  //   screenObj?.video = svideo;
  // }
  // }, [isButtonSeprated])

  return (
    <MuiThemeProvider theme={theme}>
      <div className="upload-form">
        <Collapse
          in={
            newUploadHasStarted && 100 > loadScreen[`${category}.${screen}`] > 0
          }
        >
          <Alert
            severity={alertColor}
            action={
              <div>
                {alertColor == 'success' && (
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      _dispatch({
                        type: 'SCREEN_TO_EDIT',
                        payload: '',
                      });
                    }}
                  >
                    <SubscriptionsIcon fontSize="inherit" /> Done
                  </IconButton>
                )}
              </div>
            }
          >
            <div key={loadScreen[`${category}.${screen}`]}>
              {alertMessage} |
              {loadScreen[`${category}.${screen}`] !== 0 && (
                <span>{loadScreen[`${category}.${screen}`]}%</span>
              )}
            </div>
          </Alert>
        </Collapse>

        <p className="text-sm text-gray-400	mb-2">
          Screen Type:
          <br />{' '}
        </p>
        <div className="tabs-blk flex aic flex-wrap">
          {tabsItms.map((tab, i) => (
            <button
              key={i}
              className={`cleanbtn tab-btn font s15 b5 c333 flex items-center gap-2  ${
                formTab === tab.label ? 'on' : ' '
              }`}
              onClick={() => setFormTab(tab.label)}
            >
              {tab.label === 'Video' && <FaPlay />}
              {tab.label === 'Embed App' && <ImEmbed2 className="w-5 h-5" />}
              {tab.label === 'Form' && <FaWpforms />}
              {tab.label}
            </button>
          ))}
        </div>

        <div>
          {formTab == 'Video' && (
            <>
              <div className="feild-blk">
                <div className="lbl font s15 b6 c333">Video Location Card</div>
                <DebouncedTextField
                  id="caption-name"
                  placeholder="2 Bedroom"
                  variant="outlined"
                  value={location}
                  onChange={setLocation}
                  style={{ width: 'calc(100%)' }}
                />
              </div>
              <div className="feild-blk">
                <div className="lbl font s15 b6 c333">Video Caption</div>
                <DebouncedTextField
                  id="title-name"
                  placeholder="Welcome to the...!"
                  variant="outlined"
                  value={caption}
                  onChange={setCaption}
                  style={{ width: 'calc(100%)' }}
                />
              </div>

              <div className="upload-form-links flex aic">
                <button
                  onClick={() => setLinksTab('links')}
                  className={`link-button font ${
                    linksTab === 'links' && 'active'
                  }`}
                >
                  Links
                </button>
                <button
                  onClick={() => setLinksTab('CenterLinks')}
                  className={`link-button font ${
                    linksTab === 'CenterLinks' && 'active'
                  }`}
                >
                  Center Links
                </button>
                <button
                  onClick={() => setLinksTab('BottomLinks')}
                  className={`link-button font ${
                    linksTab === 'BottomLinks' && 'active'
                  }`}
                >
                  Bottom Links
                </button>
              </div>
              {linksTab === 'BottomLinks' && (
                <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className=" flex items-center gap-2 text-sm text-gray-500">
                      Invisible Background
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="invisibleBackground"
                        checked={bottomButtons?.invisibleBackground}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Rounded Video Player{' '}
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="roundedVideoPlayer"
                        checked={bottomButtons?.roundedVideoPlayer}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editButtonName ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Extra Padding
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="extraPadding"
                        checked={bottomButtons?.extraPadding}
                      />
                    </div>
                  </div>
                </div>
              )}
              {linksTab === 'CenterLinks' && (
                <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Page Enabled{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="pageEnabled"
                        checked={centerButtons?.pageEnabled}
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Button Enabled{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="buttonEnabled"
                        checked={centerButtons?.buttonEnabled}
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Background White{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="backgroundWhite"
                        checked={centerButtons?.backgroundWhite}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              )}
              <table className="buttons-tabel">
                <thead style={{ width: 'calc(100%)' }}>
                  <tr>
                    {linksTab !== 'links' && (
                      <th
                        style={{
                          display: editVideoPath ? 'none' : 'table-cell',
                        }}
                      >
                        <div className="btn-lbl font c333 b6">Image</div>
                      </th>
                    )}

                    <th
                      style={{ display: editVideoPath ? 'none' : 'table-cell' }}
                    >
                      <div className="btn-lbl font c333 b6">Button Name</div>
                    </th>
                    <th
                      style={{
                        padding: '0 8px',
                        display: editButtonName ? 'none' : 'table-cell',
                      }}
                    >
                      <div className="btn-lbl font c333 b6">Video Path</div>
                    </th>
                    <th style={{ padding: '0 12px' }}>
                      <div className="btn-lbl font c333 b6">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ width: 'calc(100%)' }}>
                  {buttons &&
                    linksTab === 'links' &&
                    buttons.map((button, i) => (
                      <MagnetPathRow
                        linksType="links"
                        screen={screen}
                        key={i}
                        button={button}
                        button_idx={i}
                        editPath={editPath}
                        community_id={community_id}
                        editPhoto={editPhoto}
                        deletePath={deletePath}
                        routeOpts={routeOpts}
                        dragItem={moveMagnetPathRow}
                        editButtonName={editButtonName}
                        setEditButtonName={setEditButtonName}
                        editVideoPath={editVideoPath}
                        setEditVideoPath={setEditVideoPath}
                        setButtonsIsDragging={setButtonsIsDragging}
                      />
                    ))}
                  {linksTab === 'BottomLinks' &&
                    bottomButtons &&
                    bottomButtons?.links &&
                    bottomButtons?.links?.map((button, i) => (
                      <MagnetPathRow
                        screen={screen}
                        key={i}
                        button={button}
                        button_idx={i}
                        editPath={editPath}
                        community_id={community_id}
                        editPhoto={editPhoto}
                        deletePath={deletePath}
                        routeOpts={routeOpts}
                        dragItem={moveMagnetPathRow}
                        editButtonName={editButtonName}
                        setEditButtonName={setEditButtonName}
                        editVideoPath={editVideoPath}
                        setEditVideoPath={setEditVideoPath}
                        setButtonsIsDragging={setButtonsIsDragging}
                      />
                    ))}
                  {linksTab === 'CenterLinks' &&
                    centerButtons &&
                    centerButtons?.links &&
                    centerButtons?.links?.map((button, i) => {
                      return (
                        <MagnetPathRow
                          screen={screen}
                          key={i}
                          button={button}
                          button_idx={i}
                          editPath={editPath}
                          community_id={community_id}
                          editPhoto={editPhoto}
                          deletePath={deletePath}
                          routeOpts={routeOpts}
                          dragItem={moveMagnetPathRow}
                          editButtonName={editButtonName}
                          setEditButtonName={setEditButtonName}
                          editVideoPath={editVideoPath}
                          setEditVideoPath={setEditVideoPath}
                          setButtonsIsDragging={setButtonsIsDragging}
                        />
                      );
                    })}
                </tbody>
                <tfoot style={{ width: 'calc(100%)' }}>
                  <tr
                    style={{
                      display:
                        editVideoPath || editButtonName ? 'none' : 'table-row',
                    }}
                  >
                    <td style={{ width: 'calc(40%)' }}>
                      <DebouncedTextField
                        id="button-name-input"
                        placeholder="Floor Plan 1"
                        variant="outlined"
                        value={buttonName}
                        onChange={setButtonName}
                      />
                    </td>
                    <td className=" mt-2" style={{ width: 'calc(40%)' }}>
                      <Autocomplete
                        id="video-routes"
                        options={routeOpts}
                        getOptionLabel={({ id }) => id}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (
                            params.inputValue &&
                            (params.inputValue.startsWith('https://') ||
                              params.inputValue.startsWith('http://') ||
                              params.inputValue.startsWith('www.'))
                          ) {
                            filtered.push({
                              inputValue: params.inputValue,
                              id: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        noOptionsText="No floor plans available. Add URL instead"
                        onChange={(event, newValue) => {
                          if (newValue && newValue.id.startsWith('Add')) {
                            setRouteAdderType(1);
                            setHrefRoute(newValue.inputValue);
                          } else {
                            setRouteAdderType(0);
                            setRoutes(newValue);
                            if (newValue?.route) {
                              const [cata, scr] = newValue?.route.split('.');
                              let links =
                                template?.categories?.[cata]?.screens?.[scr]
                                  .links;
                              setPrevLinks(links);
                            }
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            placeholder="Floor Plan / URL"
                          />
                        )}
                      />
                    </td>
                    <td style={{ width: 'calc(20%)' }}>
                      <Tooltip title={`Add button "${buttonName}"`}>
                        <IconButton color="secondary" onClick={addPath}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Clone buttons from another screen">
                        <IconButton
                          color="secondary"
                          onClick={() => setShowCloneButton(!showCloneButton)}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <BasicFileUploadComponent
                defaultFile={thumbnailImg ? thumbnailImg : ''}
                outerPath={community_id}
                innerPath={
                  `/community/${community_id}/videos/thumbnail/` +
                  `${category}.${screen}`
                }
                onSuccess={(url) => {
                  setThumbnailImg(url);
                }}
                placeholder="Upload Thumbnail"
              />
              {/* <div className=" mt-3 flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={isButtonSeprated}
                  onChange={(e) => setIsButtonSeprated(e.target.checked)}
                  name="buttonSeparated"
                  id="buttonSeparated"
                />
                <label htmlFor="buttonSeparated">Button Separated</label>
              </div> */}
              <Settings
                screenObj={screenObj}
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
                screenKey={screen}
                categoryKey={category}
              />
            </>
          )}
          {formTab == 'Embed App' && (
            <>
              <IframeSettings
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
              />
              <div className="feild-blk">
                <div className="lbl font s15 b6 c333">Video Caption</div>
                <DebouncedTextField
                  id="title-name"
                  placeholder="Welcome to the...!"
                  variant="outlined"
                  value={caption}
                  onChange={setCaption}
                  style={{ width: 'calc(100%)' }}
                />
              </div>

              <div className="upload-form-links flex aic">
                <button
                  onClick={() => setLinksTab('links')}
                  className={`link-button font ${
                    linksTab === 'links' && 'active'
                  }`}
                >
                  Links
                </button>
                <button
                  onClick={() => setLinksTab('CenterLinks')}
                  className={`link-button font ${
                    linksTab === 'CenterLinks' && 'active'
                  }`}
                >
                  Center Links
                </button>
                <button
                  onClick={() => setLinksTab('BottomLinks')}
                  className={`link-button font ${
                    linksTab === 'BottomLinks' && 'active'
                  }`}
                >
                  Bottom Links
                </button>
              </div>
              {linksTab === 'BottomLinks' && (
                <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className=" flex items-center gap-2 text-sm text-gray-500">
                      Invisible Background
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="invisibleBackground"
                        checked={bottomButtons?.invisibleBackground}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Rounded Video Player{' '}
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="roundedVideoPlayer"
                        checked={bottomButtons?.roundedVideoPlayer}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editButtonName ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Extra Padding
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeCheckbox('BottomLinks', e)}
                        name="extraPadding"
                        checked={bottomButtons?.extraPadding}
                      />
                    </div>
                  </div>
                </div>
              )}
              {linksTab === 'CenterLinks' && (
                <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Page Enabled{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="pageEnabled"
                        checked={centerButtons?.pageEnabled}
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Button Enabled{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="buttonEnabled"
                        checked={centerButtons?.buttonEnabled}
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: editVideoPath ? 'none' : 'block',
                    }}
                  >
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      Background White{' '}
                      <input
                        onChange={(e) => handleChangeCheckbox('CenterLinks', e)}
                        name="backgroundWhite"
                        checked={centerButtons?.backgroundWhite}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              )}
              <table className="buttons-tabel">
                <thead style={{ width: 'calc(100%)' }}>
                  <tr>
                    {linksTab !== 'links' && (
                      <th
                        style={{
                          display: editVideoPath ? 'none' : 'table-cell',
                        }}
                      >
                        <div className="btn-lbl font c333 b6">Image</div>
                      </th>
                    )}

                    <th
                      style={{ display: editVideoPath ? 'none' : 'table-cell' }}
                    >
                      <div className="btn-lbl font c333 b6">Button Name</div>
                    </th>
                    <th
                      style={{
                        padding: '0 8px',
                        display: editButtonName ? 'none' : 'table-cell',
                      }}
                    >
                      <div className="btn-lbl font c333 b6">Video Path</div>
                    </th>
                    <th style={{ padding: '0 12px' }}>
                      <div className="btn-lbl font c333 b6">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ width: 'calc(100%)' }}>
                  {linksTab === 'links' &&
                    buttons.map((button, i) => (
                      <MagnetPathRow
                        linksType="links"
                        screen={screen}
                        key={i}
                        button={button}
                        button_idx={i}
                        editPath={editPath}
                        community_id={community_id}
                        editPhoto={editPhoto}
                        deletePath={deletePath}
                        routeOpts={routeOpts}
                        dragItem={moveMagnetPathRow}
                        editButtonName={editButtonName}
                        setEditButtonName={setEditButtonName}
                        editVideoPath={editVideoPath}
                        setEditVideoPath={setEditVideoPath}
                        setButtonsIsDragging={setButtonsIsDragging}
                      />
                    ))}
                  {linksTab === 'BottomLinks' &&
                    bottomButtons &&
                    bottomButtons?.links &&
                    bottomButtons?.links?.map((button, i) => (
                      <MagnetPathRow
                        screen={screen}
                        key={i}
                        button={button}
                        button_idx={i}
                        editPath={editPath}
                        community_id={community_id}
                        editPhoto={editPhoto}
                        deletePath={deletePath}
                        routeOpts={routeOpts}
                        dragItem={moveMagnetPathRow}
                        editButtonName={editButtonName}
                        setEditButtonName={setEditButtonName}
                        editVideoPath={editVideoPath}
                        setEditVideoPath={setEditVideoPath}
                        setButtonsIsDragging={setButtonsIsDragging}
                      />
                    ))}
                  {linksTab === 'CenterLinks' &&
                    centerButtons &&
                    centerButtons?.links &&
                    centerButtons?.links?.map((button, i) => {
                      return (
                        <MagnetPathRow
                          screen={screen}
                          key={i}
                          button={button}
                          button_idx={i}
                          editPath={editPath}
                          community_id={community_id}
                          editPhoto={editPhoto}
                          deletePath={deletePath}
                          routeOpts={routeOpts}
                          dragItem={moveMagnetPathRow}
                          editButtonName={editButtonName}
                          setEditButtonName={setEditButtonName}
                          editVideoPath={editVideoPath}
                          setEditVideoPath={setEditVideoPath}
                          setButtonsIsDragging={setButtonsIsDragging}
                        />
                      );
                    })}
                </tbody>
                <tfoot style={{ width: 'calc(100%)' }}>
                  <tr
                    style={{
                      display:
                        editVideoPath || editButtonName ? 'none' : 'table-row',
                    }}
                  >
                    <td style={{ width: 'calc(40%)' }}>
                      <DebouncedTextField
                        id="button-name-input"
                        placeholder="Floor Plan 1"
                        variant="outlined"
                        value={buttonName}
                        onChange={setButtonName}
                      />
                    </td>
                    <td className=" mt-2" style={{ width: 'calc(40%)' }}>
                      <Autocomplete
                        id="video-routes"
                        options={routeOpts}
                        getOptionLabel={({ id }) => id}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (
                            params.inputValue &&
                            (params.inputValue.startsWith('https://') ||
                              params.inputValue.startsWith('http://') ||
                              params.inputValue.startsWith('www.'))
                          ) {
                            filtered.push({
                              inputValue: params.inputValue,
                              id: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        noOptionsText="No floor plans available. Add URL instead"
                        onChange={(event, newValue) => {
                          if (newValue && newValue.id.startsWith('Add')) {
                            setRouteAdderType(1);
                            setHrefRoute(newValue.inputValue);
                          } else {
                            setRouteAdderType(0);
                            setRoutes(newValue);
                            if (newValue?.route) {
                              const [cata, scr] = newValue?.route.split('.');
                              let links =
                                template?.categories?.[cata]?.screens?.[scr]
                                  .links;
                              setPrevLinks(links);
                            }
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            placeholder="Floor Plan / URL"
                          />
                        )}
                      />
                    </td>
                    <td style={{ width: 'calc(20%)' }}>
                      <Tooltip title={`Add button "${buttonName}"`}>
                        <IconButton color="secondary" onClick={addPath}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Clone buttons from another screen">
                        <IconButton
                          color="secondary"
                          onClick={() => setShowCloneButton(!showCloneButton)}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <BasicFileUploadComponent
                defaultFile={thumbnailImg ? thumbnailImg : ''}
                outerPath={community_id}
                innerPath={
                  `/community/${community_id}/videos/thumbnail/` +
                  `${category}.${screen}`
                }
                onSuccess={(url) => {
                  setThumbnailImg(url);
                }}
                placeholder="Upload Thumbnail"
              />
              <Settings
                screenObj={screenObj}
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
                screenKey={screen}
                categoryKey={category}
              />
            </>
          )}
          {formTab == 'Form' && (
            <>
              <FormSettings
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
              />
              <Settings
                screenObj={screenObj}
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
                screenKey={screen}
                categoryKey={category}
              />
            </>
          )}
          {formTab == 'Sharing Setting' && (
            <>
              <ShareSettings
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
              />
              <Settings
                screenObj={screenObj}
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
                screenKey={screen}
                categoryKey={category}
              />
            </>
          )}
          {formTab == 'Tour Page' && (
            <>
              <SimpleAccordion
                title={'Additional Information'}
                startOpen={true}
                children={
                  <>
                    <AdditionalInfo
                      additionalInformation={additionalInformation}
                      setAdditionalInformation={setAdditionalInformation}
                    />
                  </>
                }
              />
              <Settings
                screenObj={screenObj}
                formTemplate={formTemplate}
                setFormTemplate={setFormTemplate}
                iframeOptions={iframeOptions}
                setIframeOptions={setIframeOptions}
                shareInfo={shareInfo}
                setShareInfo={setShareInfo}
                screenKey={screen}
                categoryKey={category}
              />
            </>
          )}
        </div>

        <div className="bottom-status-bar flex aic">
          {previewTemplate.enablePreview ? (
            <div className="prev-mode font s15 red flex aic">
              You cannot update screen because you are in template preview mode.
            </div>
          ) : (
            <>
              {loadScreen[`${category}.${screen}`] > 0 && newUploadHasStarted && (
                <>
                  <LinearProgress
                    style={{
                      marginTop: -15,
                      marginLeft: -15,
                      marginRight: -15,
                      marginBottom: 10,
                      paddingTop: 0,
                    }}
                    value={loadScreen[`${category}.${screen}`]}
                    color={loadScreenTextIndex == 0 ? 'secondary' : 'primary'}
                    variant="determinate"
                    fullWidth
                    minWidth="200px"
                  />
                </>
              )}

              {screen !== 'new' ? (
                <>
                  {/* <Dialog
                        open={loadScreen < 100}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        autoDetectWindowHeight={false}
                        autoScrollBodyContent={false}
                        contentStyle={{ width: '900px' }}
                        fullWidth
                      >
                        <Box padding={3}>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            padding={3}
                          >
                            <Grid item xs={12}>
                              <h2>{loadScreenText[loadScreenTextIndex]}</h2>
                            </Grid>
                            <Grid item xs={12}>
                              <LinearProgress value={loadScreen} color={loadScreenTextIndex == 0 ? "primary" : "secondary"} variant="determinate" fullWidth minWidth="200px" />
                            </Grid>
                            <Grid item xs={12}>
                              <center>
                                <h3>{loadScreen}%</h3>
                                <h5>
                                  On average, a video upload should take about 2-3
                                  minutes to upload
                              </h5>
                              </center>
                            </Grid>
                          </Grid>

                          <Timer seconds={45} />
                        </Box>
                      </Dialog> */}

                  <button
                    className="status-bar-btn font"
                    onClick={() => {
                      _dispatch({
                        type: 'SCREEN_TO_EDIT',
                        payload: '',
                      });
                      window.history.pushState({}, `View ${name}`, `#`);
                    }}
                  >
                    Back
                  </button>

                  <button
                    className="status-bar-btn font filled"
                    disabled={cloudLibraryProgress?.uploading}
                    onClick={() => {
                      if (definedProjectID) {
                        additionalInformation['definedProjectID'] =
                          definedProjectID;
                      }
                      additionalInformation['projectID'] = projectID;
                      handleUpdateScreen(
                        thumbnailImg,
                        centerButtons,
                        bottomButtons,
                        name,
                        caption,
                        location,
                        buttons,
                        screen,
                        category,
                        formTemplate,
                        additionalInformation,
                        iframeOptions,
                        shareInfo,
                        screenTitle,
                        categoryTitle,
                        cloudLibrary,
                        setCloudLibrary,
                        setCloudLibraryProgress,
                        screenObj,
                        isButtonSeprated
                      );
                      smartAlert(
                        'Please wait, updating/uploading/creating...',
                        'warning'
                      );
                    }}
                  >
                    Update Screen
                  </button>
                  <button
                    className="status-bar-btn font"
                    onClick={handleClickOpenDelete}
                  >
                    <div className="flex flex-row	">
                      {deletescreenloading ? 'Deleting Screen.. ' : 'Delete'}
                      {deletescreenloading && <LoadingIndicator />}
                    </div>
                  </button>
                  <button
                    className="status-bar-btn font"
                    onClick={() => {
                      _dispatch({
                        type: 'SCREEN_TO_EDIT',
                        payload: `${category}.new`,
                      });
                    }}
                  >
                    Duplicate
                  </button>

                  {/* Delete Screen Dialog */}
                  <AnimatedDialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    fullWidth
                    maxWidth="xs"
                  >
                    <div className="add-category-dialog">
                      <div className="hdr flex aic">
                        <div className="title font s18 b6 c000">
                          Delete Screen
                        </div>
                        <button
                          className="cross-btn cleanbtn fontr"
                          onClick={handleCloseDelete}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="wrap flex flex-col">
                        <div className="txt font s15 black">
                          Are you sure you want to delete this screen?
                        </div>
                      </div>
                      <div className="ftr flex aic">
                        <button
                          className="cleanbtn add-cata font s15 cfff"
                          onClick={async () => {
                            setdeletescreenloading(true);
                            handleCloseDelete();

                            await post('/magnets/template/screen/delete', {
                              screen_key: screen,
                              category_key: category,
                              magnet_uuid: magnetId,
                              magnet_type: magnetType,
                            });
                            _dispatch({
                              type: 'removeScreen',
                              payload: {
                                magnet_id: magnetId,
                                magnet_type: magnetType,
                                screen_key: screen,
                                category_key: category,
                              },
                            });

                            // going back to videos screen
                            _dispatch({
                              type: 'SCREEN_TO_EDIT',
                              payload: '',
                            });
                          }}
                        >
                          Confirm
                        </button>
                        <button
                          className="cleanbtn add-cata cancel font s15 c333"
                          onClick={handleCloseDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </AnimatedDialog>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      _dispatch({
                        type: 'SCREEN_TO_EDIT',
                        payload: `${category}.new`,
                      });
                    }}
                  >
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={cloudLibraryProgress?.uploading}
                    onClick={() => {
                      if (definedProjectID) {
                        additionalInformation['definedProjectID'] =
                          definedProjectID;
                      }
                      additionalInformation['projectID'] = projectID;
                      setbuttonloading(true);
                      handleCreateScreen(
                        thumbnailImg,
                        name,
                        caption,
                        location,
                        buttons,
                        centerButtons,
                        bottomButtons,
                        screen,
                        category,
                        formTemplate,
                        additionalInformation,
                        iframeOptions,
                        shareInfo,
                        screenTitle,
                        categoryTitle,
                        cloudLibrary,
                        setCloudLibrary,
                        setCloudLibraryProgress
                      );
                      smartAlert(
                        'Please wait, updating/uploading/creating...',
                        'warning'
                      );
                    }}
                  >
                    {buttonloading
                      ? 'Creating Screen... '
                      : ' Create New Screen'}
                    {buttonloading && <LoadingIndicator />}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        {/* <Button
            disabled={open}
            variant="outlined"
            onClick={() => {
              setOpen(true);
            }}
          >
            ReOPEN
          </Button> */}
      </div>

      {/* Clone Dialog Button */}
      <AnimatedDialog
        open={showCloneButton}
        onClose={() => setShowCloneButton(false)}
      >
        <div className="clone-dailog">
          <div className="hdr flex aic">
            <div className="title font s16 b6 c000">Clone Button options</div>
            <button
              className="cleanbtn cross font s26 c777"
              onClick={() => setShowCloneButton(false)}
            >
              &times;
            </button>
          </div>
          <div className="upload-form-links flex aic">
            <button
              onClick={() => {
                setLinksCloneTab('links');
              }}
              className={`link-button font ${
                linksCloneTab === 'links' && 'active'
              }`}
            >
              Links
            </button>
            <button
              onClick={() => {
                setLinksCloneTab('CenterLinks');
              }}
              className={`link-button font ${
                linksCloneTab === 'CenterLinks' && 'active'
              }`}
            >
              Center Links
            </button>
            <button
              onClick={() => {
                setLinksCloneTab('BottomLinks');
              }}
              className={`link-button font ${
                linksCloneTab === 'BottomLinks' && 'active'
              }`}
            >
              Bottom Links
            </button>
          </div>
          <div className="iput-blk">
            <Autocomplete
              id="video-routes"
              t
              options={routeOpts ? routeOpts : []}
              getOptionLabel={({ id }) => id}
              onChange={(event, newValue) => {
                console.log('onchange clone: ', newValue, screenObj);
                setCloneInputValue(newValue);
                handleCloneLinks(newValue);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  placeholder="Floor Plan / URL"
                />
              )}
            />
          </div>
          <div className="prevList flex flex-col">
            {prevLinks?.map((button, i) => (
              <tr>
                <td
                  style={{
                    width: editButtonName ? 'calc(80%)' : 'calc(40%)',
                    position: 'relative',
                  }}
                >
                  <TextField
                    id={i}
                    value={button.name}
                    disabled
                    fullWidth
                    style={{ paddingLeft: '25px' }}
                  />
                </td>
                <td>
                  {button.route ? (
                    <MagnetPopup template={template} videoPath={button.route} />
                  ) : (
                    <TextField
                      id={button.href}
                      value={button.href}
                      disabled
                      fullWidth
                    />
                  )}
                </td>
              </tr>
            ))}
          </div>
          <div className="ftr flex aic">
            <button
              onClick={() => _cloneLinks2(prevLinks)}
              className="cleanbtn clone-btn font s15 b5 cfff anim"
            >
              Clone
            </button>
          </div>
        </div>
      </AnimatedDialog>
    </MuiThemeProvider>
  );
};

export default memo(UploadForm);
