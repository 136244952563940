import React, { useState, useEffect } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import useGetSupabaseTable from '../../hooks/useGetSupabaseTable';
import { FaQrcode } from 'react-icons/fa';
import { generateRoutes } from './ManageMagnet';
import { useSelector } from 'react-redux';
import './ShareableTour.css';

const ShareableTour = ({
  selectedVideos,
  setSelectedVideos,
  getScreen,
  generateSharableTourUrl,
  toggleQrCode,
  sharableTourType,
  community_id,
  magnet_uuid,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  var top100Films = [];

  const {
    data: sharableTours,
    isLoading: isSharableToursLoading,
    error: sharableToursError,
    reloadTable: reloadSharableToursTable,
    insertRow: insertSharableToursRow,
    deleteRow: deleteSharableToursRow,
  } = useGetSupabaseTable('SharableTours', '', {
    column_name: 'magnet_uuid',
    column_value: magnet_uuid,
  });

  useEffect(() => {
    if (sharableTours) {
      console.log('sharableTours: TYG ', sharableTours);
    }
  }, [sharableTours]);

  const [sharableTourList, setSharableTourList] = useState([
    {
      magnet_uuid: '008d45d0-ab7f-49fd-b082-77a48380010e',
      sharableTourType: 'Instagram Stories',
      id: 1,
      title: '1 Bed options',
      created_at: 'Dec 21',
      by: 'Amulya Parmar',
      views: '8 times (2 times today)/ 3 unique users',
      selectedVideos: [
        'floor_plans.d4',
        'amenities.courtyard',
        'amenities.game_room',
        'thank_you.thank_you_video_4',
        'thank_you.schedule_a_tour_tyg',
      ],
    },
    {
      magnet_uuid: '008d45d0-ab7f-49fd-b082-77a48380010e',
      sharableTourType: 'Slides Share',
      id: 2,
      title: '2 Bed options',
      created_at: 'Jan 22',
      by: 'Amulya Parmar',
      views: '8 times (2 times today)/ 3 unique users',
      selectedVideos: [
        'floor_plans.d4',
        'amenities.courtyard',
        'amenities.game_room',
        'thank_you.thank_you_video_4',
        'thank_you.schedule_a_tour_tyg',
      ],
    },
  ]);

  const SharableTourRow = ({
    _id,
    item,
    _title,
    _createdOn,
    _by,
    _viewed,
    _selectedVideos,
  }) => {
    const deleteTourList = async (row_id) => {
      if (
        window.confirm(
          `Do you really want to Delete this Sharable tour template: "${_title}"?`
        )
      ) {
        // const newTourList = sharableTourList.filter(
        //   (item, item_idx) => item_idx !== _id
        // );
        // console.log(newTourList);
        // setSharableTourList([...newTourList]);
        deleteSharableToursRow('id', item?.id);
        reloadSharableToursTable();
      }
    };

    return (
      <>
        {' '}
        <div
          key={_id}
          className=" flex items-center lg:col-span-8 gap-1 lg:gap-4 py-2"
        >
          {/* <img src="/radix.png" alt="" /> */}
          {/* <img className=" w-8 lg:w-14 object-contain" src="/main.png" alt="" /> */}
          <h1 className="w-50 text-xs lg:text-xl font-semibold flex items-center lg:gap-2">
            {_title}{' '}
            <MdEdit
              className="w-5 h-5 cursor-pointer hover:text-pr"
              onClick={() => setSelectedVideos(_selectedVideos)}
            />
            <MdDeleteOutline
              className="w-5 h-5 cursor-pointer hover:text-pr"
              onClick={() => deleteTourList(item?.id)}
            />
          </h1>
          {/* <div className=" w-36 lg:w-80 relative">
            <Autocomplete
              multiple
              id="tags-filled"
              options={top100Films.map((option) => option)}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({
                      index,
                    })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Send tour via email/text"
                />
              )}
            />
            <div className=" absolute top-0 -right-10 h-full">
              <button className=" px-3 rounded-md h-full bg-pr flex items-center justify-center">
                <img src="/vector.png" alt="" />
              </button>
            </div>
          </div> */}
          {/* // function generateSharableTourUrl(share_tour_type="", lead_name="", magnet_uuid="", selected_videos="", agent_id="", hyper_iframe="", intro_video="") { */}

          <div className=" w-60 relative">
            <div className=" absolute top-0 -left-11 z-4 h-full">
              <button
                className=" px-3 rounded-md h-full bg-pr flex items-center justify-center"
                onClick={() => {
                  toast('Copied to clipboard');
                  navigator.clipboard.writeText(
                    generateSharableTourUrl(
                      item?.sharableTourType,
                      '',
                      item?.magnet_uuid,
                      item?.selectedVideos,
                      item?.by,
                      '',
                      ''
                    )
                  );
                }}
              >
                <AiOutlineCopy className="w-5 h-5 cursor-pointer text-white" />
              </button>
            </div>
            <TextField
              variant="outlined"
              value={generateSharableTourUrl(
                item?.sharableTourType,
                '',
                item?.magnet_uuid,
                item?.selectedVideos,
                item?.by,
                '',
                ''
              )}
              fullWidth
            />
          </div>
        </div>
        {/* <p className=" hidden lg:block">{_createdOn}</p> */}
        <p className=" lg:col-span-2 lg:block">{/* {_viewed}{' '} */}</p>
      </>
    );
  };

  return (
    <div className=" lg:px-3 mt-2">
      <div className=" grid  lg:grid-cols-8 ">
        <div className=" flex items-center lg:col-span-4 gap-2">
          {/* <h1
            onClick={() => setActiveTab(0)}
            className={` text-lg lg:text-2xl cursor-pointer font-medium ${
              activeTab === 0 ? 'text-black' : 'text-gray-400'
            }`}
          >
            Sharable Tours{' '}
          </h1>{' '} */}
        </div>
        {/* <p className=" hidden lg:block">created on</p>
        <p className=" hidden lg:block">by</p>
        <p className=" lg:col-span-2 hidden lg:block">Viewed</p> */}
      </div>
      <div className=" grid lg:grid-cols-8 items-center ">
        {sharableTours &&
          sharableTours.map((item, idx) => (
            <SharableTourRow
              _id={idx}
              item={item}
              _title={item?.title}
              _createdOn={item?.created_at}
              _by={item?.by}
              _viewed={item?.views}
              _selectedVideos={item?.selectedVideos}
            />
          ))}
      </div>
      <div className=" flex items-center mt-4 justify-between w-full">
        <div
          className="px-4 py-1 border-gray-600 border hover:bg-gray-200 cursor-pointer rounded-sm"
          onClick={() => {
            let shareTourName = window.prompt(
              'What do you want to name this tour?'
            );
            if (shareTourName === '' || shareTourName === null) {
              console.log('not saving / cancel');
            } else {
              console.log('new tour name: ', shareTourName);
              insertSharableToursRow([
                {
                  title: shareTourName,
                  selectedVideos: selectedVideos,
                  sharableTourType: sharableTourType,
                  magnet_uuid: magnet_uuid,
                  community_id: community_id,
                },
              ]);
              reloadSharableToursTable();
            }
          }}
        >
          <p className="">Save Sharable Tour</p>
        </div>
        {/* <div
        style={{ marginTop: '0' }}
        className={styles.share_popup_next}
        onClick={() => setModalStep(1)}
      >
        Next
      </div> */}
        <div
          className="px-2 py-1 border-gray-600 border hover:bg-gray-200 cursor-pointer rounded-sm"
          onClick={() => toggleQrCode()}
        >
          <FaQrcode />
        </div>
      </div>
    </div>
  );
};

export default ShareableTour;

export const SharableTourList = ({
  magnet_uuid,
  community_id,
  generateSharableTourUrl,
  setSelectedVideos,
  setOpenShareDialog,
}) => {
  const { template } = useSelector((state) => state.getMagnet);
  const routeOpts = generateRoutes(template);
  const startRoute = template?.default_config?.startScreen;

  const {
    data: sharableTours,
    isLoading: isSharableToursLoading,
    error: sharableToursError,
    reloadTable: reloadSharableToursTable,
    insertRow: insertSharableToursRow,
    deleteRow: deleteSharableToursRow,
  } = useGetSupabaseTable('SharableTours', '', {
    column_name: 'community_id',
    column_value: community_id,
  });

  useEffect(() => {
    if (sharableTours) {
      console.log('sharableTours below: TYG ', sharableTours);
    }
  }, [sharableTours]);

  const [sharableTourList, setSharableTourList] = useState([
    {
      magnet_uuid: '008d45d0-ab7f-49fd-b082-77a48380010e',
      sharableTourType: 'Instagram Stories',
      id: 1,
      title: '1 Bed options',
      created_at: 'Dec 21',
      by: 'Amulya Parmar',
      views: '8 times (2 times today)/ 3 unique users',
      selectedVideos: [
        'floor_plans.d4',
        'amenities.courtyard',
        'amenities.game_room',
        'thank_you.thank_you_video_4',
        'thank_you.schedule_a_tour_tyg',
      ],
    },
    {
      magnet_uuid: '008d45d0-ab7f-49fd-b082-77a48380010e',
      sharableTourType: 'Slides Share',
      id: 2,
      title: '2 Bed options',
      created_at: 'Jan 22',
      by: 'Amulya Parmar',
      views: '8 times (2 times today)/ 3 unique users',
      selectedVideos: [
        'floor_plans.d4',
        'amenities.courtyard',
        'amenities.game_room',
        'thank_you.thank_you_video_4',
        'thank_you.schedule_a_tour_tyg',
      ],
    },
  ]);

  const SharableTourCard = ({ item }) => {
    const [filteredRouteOpts, setFilteredRouteOpts] = useState(
      routeOpts.slice(0, 3)
    );

    function getPicturesFromRouteOpts(shareType, routes = [startRoute]) {
      let routesWithLogic = routes;
      if (shareType === 'Full Tour URL') {
        routesWithLogic = [startRoute];
      }
      //   // let filteredRouteOpts = routeOpts.filter((routeObj) => {
      //   //   return routes?.includes(routeObj?.route);
      //   // });
      //   routesWithLogic = routes

      //   // console.log('filteredRouteOpts: ', shareType,Object.values(filteredRouteOpts) )
      // }
      //   setFilteredRouteOpts(filteredRouteOpts)
      // } else {
      //   setFilteredRouteOpts(routeOpts.slice(0, 3))
      // }
      let filteredRouteOpts = {};
      routesWithLogic.map((route) => {
        filteredRouteOpts[route] =
          routeOpts.filter((routeObj) => {
            return routeObj?.route === route && routeObj?.img;
          })?.[0] || {};
      });
      setFilteredRouteOpts(Object.values(filteredRouteOpts));
    }

    useEffect(() => {
      getPicturesFromRouteOpts(item?.sharableTourType, item?.selectedVideos);
    }, [item]);

    return (
      <div
        className=" flex flex-col items-center justify-center "
        style={{ width: '220px', paddingLeft: '12px' }}
      >
        <div
          style={{
            borderRadius: '10px',
            height: '130px',
            width: '200px',
            background:
              'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.024) 0%, rgba(0, 0, 0, 0.074) 68.75%), #F7F7F7',
          }}
          onClick={() => {
            toast('Copied to clipboard');
            navigator.clipboard.writeText(
              generateSharableTourUrl(
                item?.sharableTourType,
                '',
                item?.magnet_uuid,
                item?.selectedVideos,
                item?.by,
                '',
                ''
              )
            );
          }}
          className=" bg-gray-200 relative cursor-pointer h-35 rounded-xl center flex items-center	justify-center transition-all  transform  hover:-translate-y-2  "
        >
          <div
            className=" opacity-0 hover:opacity-100 bg-gray-500 absolute bg-opacity-0  hover:bg-opacity-60 rounded-xl"
            style={{ zIndex: 10, height: '100%', width: '100%' }}
          >
            <span className="text-white" style={{ zIndex: 12 }}>
              {' '}
              <br />
              <button
                className=" border border-white rounded-lg px-3 py-1 mb-2 hover:bg-white hover:text-gray-700 "
                onClick={(e) => {
                  e.stopPropagation();
                  // open in new tab
                  window.open(
                    generateSharableTourUrl(
                      item?.sharableTourType,
                      '',
                      item?.magnet_uuid,
                      item?.selectedVideos,
                      item?.by,
                      '',
                      ''
                    ),
                    '_blank'
                  );
                }}
              >
                open
              </button>
              <button
                className=" border border-white rounded-lg px-3 py-1 mb-2 hover:bg-white hover:text-gray-700 ml-2 "
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedVideos(item?.selectedVideos);
                  setOpenShareDialog(true);
                }}
              >
                edit
              </button>
              <br />{' '}
              <button className="border border-white rounded-lg  px-3 py-1 hover:bg-white hover:text-gray-700">
                copy
              </button>
            </span>
          </div>

          <img
            style={{
              width: '30%',
              position: 'absolute',
              left: '15%',
              zIndex: 3,
            }}
            className="rounded-sm sidesharingimage"
            src={filteredRouteOpts?.[1]?.img}
            // src="https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/127/magnet/844f6551-ea6c-4d16-9a62-f49fd79d0306/amenities_Amenities_Intro/The20Ruckus_broll20amt_2023.mp4.jpg"
          />
          <img
            style={{
              width: '50%',
              position: 'absolute',
              left: '25%',
              zIndex: 5,
            }}
            className="rounded-sm middlesharingimage"
            src={filteredRouteOpts?.[0]?.img}
            // src="https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/127/magnet/category/form_screen/altmediatoken279c5eeb-54f8-4785-b6bd-cbfe81e46132.jpg"
          />
          <img
            style={{
              width: '30%',
              position: 'absolute',
              right: '15%',
              zIndex: 3,
            }}
            className="rounded-sm sidesharingimage"
            src={filteredRouteOpts?.[2]?.img}
            // src="https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/127/magnet/844f6551-ea6c-4d16-9a62-f49fd79d0306/amenities_Amenities_Intro/The20Ruckus_broll20amt_2023.mp4.jpg"
          />
        </div>

        <span className=" items-center justify-center">
          Sharable: {item?.title}
        </span>
      </div>
    );
  };

  return (
    <>
      {sharableTours &&
        sharableTours.map((item, idx) => (
          <SharableTourCard key={idx} item={item} />
        ))}

      {/* Create a suggested tour if no tours already created, screens must have images and probably ignore the floor plan intro, main intro pickers */}
      {!sharableTours?.length && (
        <>
          <SharableTourCard
            key={1}
            item={{
              magnet_uuid: magnet_uuid,
              sharableTourType: 'Slides Share',
              id: 1,
              title: 'Suggested',
              created_at: 'Dec 21',
              by: 'Amulya Parmar',
              views: '8 times (2 times today)/ 3 unique users',
              selectedVideos: routeOpts
                ?.filter((routeObj) => routeObj?.img)
                ?.map((routeObjFiltered) => routeObjFiltered?.route),
            }}
          />
        </>
      )}
    </>
  );
};
