import moment from 'moment';

const UpdateConfigAlert = ({
  selectedDate,
  onSaveClick,
  onCancelClick,
  onEditClick,
}) => {
  function saveClickHandler() {
    if (onSaveClick) onSaveClick();
    // setTempConfig(null)
  }

  function cancelClickHandler() {
    if (onCancelClick) onCancelClick();
    // setTempConfig(null)
  }

  function editClickHandler() {
    if (onEditClick) onEditClick();
  }

  return (
    <div
      className="w-full p-3 bg-blue-400 flex items-center justify-center gap-4 shadow-md"
      style={{ zIndex: 20 }}
    >
      <p className="text-white">
        You are editing {moment(selectedDate).format('LL')}. Do you want to
        update the config ?
      </p>
      <button
        onClick={saveClickHandler}
        className="py-1 px-4 rounded bg-gray-50 cursor-pointer hover:bg-gray-100 active:bg-gray-200"
      >
        Save
      </button>
      <button
        onClick={editClickHandler}
        className="py-1 px-3 rounded bg-gray-50 cursor-pointer hover:bg-gray-100 active:bg-gray-200"
      >
        Save to Editor
      </button>
      <button
        onClick={cancelClickHandler}
        className="py-1 px-3 rounded bg-gray-50 cursor-pointer hover:bg-gray-100 active:bg-gray-200"
      >
        Cancel
      </button>
    </div>
  );
};

export default UpdateConfigAlert;
