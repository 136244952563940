import React from 'react';
function hexToHSL(hex, lightness = '') {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  (r /= 255), (g /= 255), (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  if (lightness) {
    return `hsl(${h}, ${s}%, ${lightness}%)`;
  }
  return `hsl(${h}, ${s}%, ${l}%)`;

  // return { h, s, l };
}

function ChatbotButton({
  primaryColor = '#4399DF',
  maxWidth = '100%',
  title = '',
  subtitle = '',
  extraStyle = {},
  onClickFunction,
  className,
}) {
  // const hsl_local =  hexToHSL(primaryColor)
  const normalStyle = {
    backgroundColor: hexToHSL(primaryColor, 94),
    padding: '10px',
    border: 'none',
    color: primaryColor,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    fontFamily: 'Manrope, sans-serif',
    opacity: '95%',
    backdropFilter: 'blur(4px)',
    width: maxWidth,
    alignItems: 'center',
    justifyContent: 'space-between',
    ...extraStyle,
    // Add other styles for the normal state
  };

  const hoverStyle = {
    //   backgroundColor: hexToHSL(primaryColor),
    backgroundColor: hexToHSL(primaryColor, 91),
    color: `${primaryColor}`,
    opacity: '100%',
    // Add other styles for the hover state
  };

  const [isHoveredBtn, setIsHoveredBtn] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHoveredBtn(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredBtn(false);
  };

  return (
    <button
      className={className}
      style={isHoveredBtn ? { ...normalStyle, ...hoverStyle } : normalStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClickFunction()}
    >
      {title && <b>{title}</b>}
      {subtitle && <span style={{ fontSize: '10px' }}>{subtitle}</span>}
    </button>
  );
}

export default ChatbotButton;
