import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  widgets,
  theme,
} from '../../tour-magnet-tabs/analyticsComponents/assets';

function BlackChart({ blackChartOptions }) {
  let chartoptions = {
    series: [
      {
        name: 'Reviews',
        type: 'scatter',
        fill: {
          type: 'image',
          opacity: 1,
          image: {
            src: [
              'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/70812f1c-bb57-4ed1-26da-d6ba093ded00/w=40,sharpen=1',
            ],
            width: 40,
            height: 40,
          },
        },
        data: [
          [1667887036000, 4],
          [1667642796000, 5],
          [1666863115000, 4],
          [1666636441000, 1],
          [1665434116000, 1],
          [1663414890000, 5],
          [1663145058000, 3],
          [1662922291000, 5],
        ],
      },
      {
        name: 'Link/scans',
        type: 'area',
        data: [
          [1667887036000, 4],
          [1667642796000, 5],
          [1666863115000, 4],
          [1666636441000, 1],
          [1665434116000, null],
        ],
      },
    ],
    options: {},
  };

  // console.log('chartoptionsss', chartoptions);

  const { series, ranges } = blackChartOptions || widgets;
  const [tabValue, setTabValue] = useState(0);
  const currentRange = Object.keys(ranges)[tabValue];
  const [reviewSummaryStats, setReviewSummaryStats] = useState({});

  useEffect(() => {
    let tempReviewSummaryStats = {
      total: {
        '1_2_3': 0,
        '4_5': 0,
      },
      '30_days': {
        '1_2_3': 0,
        '4_5': 0,
      },
      '7_days': {
        '1_2_3': 0,
        '4_5': 0,
      },
    };

    var today = new Date();
    var thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
    var sevenDaysAgo = new Date(new Date().setDate(today.getDate() - 7));

    series?.[0]?.data?.map((reviewRow, idx) => {
      var date = new Date(0);
      date.setUTCSeconds(reviewRow?.[0] / 1000);
      //  console.log('date: ', reviewRow[0], date)
      let rating = reviewRow[1];
      let keyToInsert = '1_2_3';
      if (rating >= 4) keyToInsert = '4_5';

      let locationJson = {
        date: '',
        all: false,
        '30_days': false,
        '7_days': false,
      };

      locationJson['all'] = true;
      locationJson['date'] = date;
      tempReviewSummaryStats['total'][keyToInsert] += 1;
      if (date > sevenDaysAgo) {
        tempReviewSummaryStats['7_days'][keyToInsert] += 1;
        locationJson['7_days'] = true;
      }
      if (date > thirtyDaysAgo) {
        tempReviewSummaryStats['30_days'][keyToInsert] += 1;
        locationJson['30_days'] = true;
      }
      //  return locationJson
    });
    console.log('reviewsSeries TYG: ', series, tempReviewSummaryStats);
    setReviewSummaryStats(tempReviewSummaryStats);
    // window.ApexCharts.exec('Clicks', 'hideSeries', {});
  }, [series]);

  const chartOptions = {
    chart: {
      height: 350,
      types: ['scatter', 'area'],
      type: ['scatter', 'area'],
      animations: {
        enabled: false,
      },
      // zoom: {
      //   enabled: false,
      // },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#056BF6', '#F7BF3E'],
    // xaxis: {
    //   tickAmount: 10,
    //   min: 0,
    //   max: 40
    // },
    // yaxis: {
    //   tickAmount: 7
    // },
    markers: {
      size: [20, 5],
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        customHTML: [
          function () {
            return '';
          },
          function () {
            return '';
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: [
      {
        max: 5,
        seriesName: 'Ratings',
        title: {
          text: 'Ratings',
        },
      },
      {
        opposite: true,
        seriesName: 'Clicks',
        title: {
          text: 'Clicks',
        },
      },
    ],
    stroke: {
      curve: ['straight', 'smooth'],
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    fill: {
      type: ['image', 'pattern'],
      opacity: [1, 0.8],
      image: {
        src: [
          'https://imagedelivery.net/d3WSibrZmE8m_HEZW60OcQ/70812f1c-bb57-4ed1-26da-d6ba093ded00/w=40,sharpen=1',
          'solid',
        ],
        width: 40,
        height: 40,
      },
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
      pattern: {
        style: ['verticalLines', 'horizontalLines'],
        width: 5,
        height: 6,
      },
      // pattern: [{},{
      //   style: ['verticalLines', 'horizontalLines'],
      //   width: 5,
      //   height: 6
      // }],
    },
  };

  return (
    <div theme={theme.palette.primary.main}>
      <div
        style={{ background: '#e9e9e9', maxWidth: '520px' }}
        className="sm:col-span-2 lg:col-span-3 dark flex flex-col flex-auto shadow relative rounded-2xl overflow-hidden  text-gray-800"
      >
        <div className="flex items-center gap-32 mt-10 ml-7">
          <div className="flex flex-col">
            {/* <h1 className="mr-16 text-2xl md:text-3xl font-semibold  leading-7">
              Review Summary
            </h1> */}
            <h2 className="font-medium">
              <span className="text-blue-500">
                {reviewSummaryStats?.['30_days']?.['1_2_3'] +
                  reviewSummaryStats?.['30_days']?.['4_5']}{' '}
                reviews
              </span>{' '}
              over the past 30 days.{' '}
              <span className="text-blue-500">
                {' '}
                {reviewSummaryStats?.['30_days']?.['1_2_3']} reviews{' '}
              </span>{' '}
              were 3-stars or less.
            </h2>
          </div>
        </div>

        <div className="flex flex-col flex-auto h-80">
          <ReactApexChart
            options={chartOptions}
            series={blackChartOptions?.series}
            //type="scatter"
            // height={chartOptions.chart.height}
          />
        </div>
        {!blackChartOptions && (
          <div class="flex justify-center items-center absolute top-0 left-0 w-full h-full z-20">
            <div
              class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              {/* <span class="visually-hidden">Loading...</span> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlackChart;
