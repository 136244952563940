import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="flex flex-col align-items-center gap-8">
      <div className="backer-pill flex align-items-center gap-4 rounded-full">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Y_Combinator_logo.svg"
          alt="Y Combinator Logo"
          className="rounded-full"
          height={24}
          width={24}
        />
        <p>Backed by Y-Combinator</p>
      </div>
      <h1
        className="text-6xl font_uber font-bold"
        style={{ maxWidth: '900px' }}
      >
        Interactive, virtual tours that scale your{' '}
        <span className="font_uber font-bold">sales</span> team
      </h1>
      <span className="text-gray-500">
        A better way to <b>convert</b> leads from <b>start</b> to <b>finish</b>
      </span>

      <div className="flex">
        <input type="text email-input p-4" placeholder="Email address"></input>
        <button className="get-started-button">Get Started</button>
      </div>

      <div className="image-container relative flex flex-col align-items-center mt-16">
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113227086514102413/Group_39941.png"
          alt="video call demo"
          id="video-call-demo"
          style={{ 'max-width': '80%' }}
        />
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113227865069191219/Group_39943.png"
          alt="magnet circle"
          id="magnet-circle"
          className="absolute"
          style={{
            right: '-2%',
            bottom: '-20%',
            zIndex: 5,
          }}
        />
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113228589941399554/Frame_4.png"
          alt="tour startbox"
          id="tour-startbox"
          className="absolute"
          style={{
            right: '5%',
            bottom: '-7%',
            zIndex: 4,
          }}
        />
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113228905696993401/Calendar_Container.png"
          alt="Calendar container"
          id="calendar-container"
          className="absolute"
          style={{
            left: '-11%',
            bottom: '-5%',
          }}
        />
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113229142918430741/Group_39940.png"
          alt="new tour card"
          id="new-tour-card"
          className="absolute"
          style={{
            left: '5%',
            bottom: '1%',
          }}
        />
        <img
          src="https://cdn.discordapp.com/attachments/1010534491690897451/1113229495273537636/Group_39942.png"
          alt="floating notif"
          id="floating-notif"
          className="absolute"
          style={{
            right: '22%',
            top: '-14%',
          }}
        />
      </div>
    </div>
  );
};

export default LandingPage;
