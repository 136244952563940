import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import {
  getMagnetId,
  getUserInfo,
  getVisitId,
  post,
  setUserInfo,
} from './eventUtils';
import moment from 'moment';
import { API_HOST } from './request';

// Copy and pasted from tour magnet api.js with slight changes

// Used to keep track of metadata required for Events
// Having this global thing here is pretty bad, but the alternative
// is passing a lot of parameters around just for analytics or a Context
export let analyticsMetadata = {
  route: null,
  videoTime: null,
};

// This should be kept in sync with EventType in models.py on the backend
const EVENT_TYPE = Object.freeze({
  OPEN_TOUR: 'open_tour',
  BUTTON_CLICK: 'button_click',
  FORM_SUBMISSION: 'form_submission',
  CLOSE_TOUR: 'close_tour',
});

// Map EVENT_TYPEs to Google Analytics events
const GA_EVENT = Object.freeze({
  open_tour: 'Open Tour',
  button_click: 'Button Click',
  form_submission: 'Form Submission',
  close_tour: 'Close Tour',
});

const ga_event_label = (type, details) => {
  if (type === EVENT_TYPE.BUTTON_CLICK) {
    return details.to;
  } else if (type === EVENT_TYPE.FORM_SUBMISSION) {
    return details.form_route;
  } else if (type === EVENT_TYPE.CLOSE_TOUR) {
    return details.last_route;
  }
  return undefined;
};

// Map EVENT_TYPEs to Facebook Pixel events
const PIXEL_EVENT = Object.freeze({
  open_tour: 'OpenTour',
  button_click: 'ButtonClick',
  form_submission: 'FormSubmission',
  close_tour: 'CloseTour',
});

const track = async (type, details) => {
  // Add current lead UUID to `details`
  const { leadUUID } = getUserInfo();
  if (leadUUID) details.lead_uuid = leadUUID;

  // It's OK to fire GA or Pixel events even if it isn't initialized
  ReactGA.event({
    category: 'Tour Event',
    action: GA_EVENT[type],
    label: ga_event_label(type, details),
    ...('video_time' in details && { value: details.video_time }),
  });
  ReactPixel.trackCustom(PIXEL_EVENT[type], details);

  const res = await post('/event', {
    visit_uuid: await getVisitId(),
    magnet_uuid: getMagnetId(),
    url: window.location.href,
    event_type: type,
    details: details,
  });
  return res;
};

export const startVisit = async (magnetTemplate) => {
  if ('gaPropertyId' in magnetTemplate) {
    // Note that react-ga only supports "UA-" (Universal Analytics) IDs,
    // not "G-" (Google Analytics 4) IDs. Google Analytics does have an
    // option to create both a UA and a GA4 ID for the same project though
    ReactGA.initialize(magnetTemplate.gaPropertyId, {
      gaOptions: {
        siteSpeedSampleRate: 100,
        // userId?
      },
    });
    // We could use custom URLs here to track movement through the screens
    // instead of using ButtonClick events
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  if ('fbPixelId' in magnetTemplate) {
    ReactPixel.init(magnetTemplate.fbPixelId, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.pageView();
  }
  // getVisitId() will fetch a new visit UUID if one isn't found in cookies
  await getVisitId();
};

export const startTour = (name = null) => {
  track(EVENT_TYPE.OPEN_TOUR, { type: 'tour_page', name: name });
};

export const buttonClick = (from, to) => {
  const res = track(EVENT_TYPE.BUTTON_CLICK, {
    // Please note based on how the backend, analytics dashboard, etc is set up we only
    // look at the from, to fix this issue tempurarly we just switched from and to
    // note that this is not a permanent solution but allows analytics to work correctly
    // TODO: update this when backend and frontend are update to allow for better use of
    // to and fron
    from: to,
    to: from,
    video_time: analyticsMetadata.videoTime,
  });
  return res;
};

export const formSubmission = async (formData) => {
  const res = await track(EVENT_TYPE.FORM_SUBMISSION, {
    form_data: formData,
    form_route: window.location.href,
    video_time: analyticsMetadata.videoTime,
  });
  const newLeadUUID = res.lead_uuid;
  if (newLeadUUID) {
    setUserInfo({
      leadUUID: newLeadUUID,
      // Some of these may be undefined, that's OK
      name: formData.lead_name,
      email: formData.lead_email,
      phone: formData.lead_phone,
    });
  }
  return res;
};

export async function getCommunityData(communityId) {
  try {
    const communityResponse = await axios({
      method: 'post',
      url: `https://3aes6e.deta.dev/get_customer/${communityId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({}),
    });

    if (!communityResponse?.data) return null;

    return communityResponse.data;
  } catch (err) {
    console.log('getCustomerData', err);
  }
}

export const fetchanalytics = async (
  setmod_comminities,
  the_communities,
  communityid,
  fetchcounter,
  setfetchcounter
) => {
  try {
    const res = await axios({
      method: 'post',
      url: `https://3aes6e.deta.dev/get_customer/${communityid}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({}),
    });
    if (res?.data) {
      the_communities[fetchcounter]['queryinfo'] = res?.data?.invoices;
    } else {
      the_communities[fetchcounter]['queryinfo'] = {};
    }
    the_communities[fetchcounter]['loading'] = false;
    setmod_comminities([...the_communities]);
  } catch (error) {
    the_communities[fetchcounter]['loading'] = false;
    console.log(
      `Couldn't fetch details for community ${the_communities[fetchcounter]}`
    );
  }
  if (fetchcounter < the_communities.length) {
    setfetchcounter(fetchcounter + 1);
  }
};

export const createStripeId = (stripe_id, cidForStripe) => {
  console.log('stripe id new: ', stripe_id);

  // TODO Use the standard request method instead of fetch
  if (!cidForStripe) return;

  fetch('https://3aes6e.deta.dev/create_customer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      community_id: cidForStripe, // TODO Soft Code
      customer_id: stripe_id,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Added stripe id successfully.', data));
};

export const updateStripeId = (deta_object) => {
  // TODO Use the standard request method instead of fetch
  // if (!cidForStripe) return;

  fetch('https://3aes6e.deta.dev/update_customer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      update_json: {
        community_id: deta_object?.community_id,
        created: deta_object?.created,
        customer_id: deta_object?.customer_id,
        invoices: deta_object?.invoices,
        last_stripe_update: deta_object?.last_stripe_update,
        unpaid_invoices: deta_object?.unpaid_invoices,
        unpaid_invoices_amount: deta_object?.unpaid_invoices_amount,
        updated: deta_object?.updated,
      }, // deta object
      key: deta_object?.key,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Added stripe id successfully.', data));
};

export const getanalyicsbigquery = async (query) => {
  try {
    console.log('res1', query);
    const { data } = await axios.get(
      `${API_HOST}/run_sqlquery_inbigquery?querystring=${query}`
    );
    console.log('goot data', data.res);
    return data;
  } catch (error) {
    console.log('errror', error);
  }
};

export const get_location_by_id = async (ip) => {
  try {
    const { data } = await axios.post(
      'https://api.leasemagnets.com/get_location_by_ip',
      {
        ip_address: ip,
      }
    );
    return data?.location;
  } catch (error) {
    console.log('errror', error);
  }
};

export const get_ip = async () => {
  try {
    const { data } = await axios.get('https://api.ipify.org?format=json');
    return data;
  } catch (error) {
    console.log('errror', error);
  }
};

export const send_Request_data_to_discord = async (obj, location_enable) => {
  try {
    const customConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (location_enable) {
      let res = await get_ip();
      if (res?.ip) {
        let location_data = await get_location_by_id(res?.ip);
        console.log('location_data', location_data);
        if (location_data) {
          obj = {
            content: `${obj?.content}
            ${location_data?.city} ${location_data?.country} ${location_data?.state}
            lon_lat : ${location_data?.longitude} ${location_data?.latitude}
            `,
          };
        }
      }
    }
    console.log('get_ip_final', obj);
    const { data } = await axios.post(
      'https://discord.com/api/webhooks/1097618275359281212/_uw9-ZK8VOTco6i5fFX1Tt5tR5WwnRRJIfp4dfzfqQvNuHkoAdEMRVg6Zcjeh8t1D49O',
      obj,
      customConfig
    );
    return data;
  } catch (error) {
    console.log('errror', error);
  }
};
export const send_user_login_location_to_discord = async (u) => {
  try {
    if (u?.email == 'team@leasemagnets.com') {
      return;
    }
    let login_history_date = localStorage.getItem('login_user_date');
    if (login_history_date) {
      console.log(
        'login_history_date',
        `${moment(new Date()).format(
          'MMMM Do YYYY, h:mm:ss a'
        )} ${moment.locale()}`
      );
      if (moment(new Date()).format('dddd') !== login_history_date) {
        localStorage.setItem(
          'login_user_date',
          moment(new Date()).format('dddd')
        );
        send_Request_data_to_discord(
          {
            content: `**Logined User entered:** 
          User: ${u.email} 
          Date: ${new Date()}`,
          },
          true
        );
      }
    } else {
      localStorage.setItem(
        'login_user_date',
        moment(new Date()).format('dddd')
      );
      send_Request_data_to_discord(
        {
          content: `**Logined User entered:** 
        User: ${u.email} 
        Date: ${new Date()}`,
        },
        true
      );
    }
  } catch (error) {
    console.log('errror', error);
  }
};
