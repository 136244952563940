import { IoMdClose } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineDelete } from 'react-icons/ai';
import AccessLevelWrapper from '../../components/Reusable/AccessLevelWrapper';
import RouteInput from '../../components/Reusable/RouteInput';
import { FiUserPlus } from 'react-icons/fi';
import { BsCheck2All } from 'react-icons/bs';
import { useState } from 'react';
import { getRandomId } from '../../utils/files';
import ChatbotBubble from './ChatbotBubble';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from '@material-ui/core';

const AddBubbleSection = ({
  community_id,
  routes,
  addNewChatBubble,
  saveChatbot,
  setValueIndex,
  setCondition,
  setconditionModal,
  setIdx,
  setButtonIndex,
  onSelectImage,
  addSubtitles,
  deleteSelectedImage,
  progress,
  defaultConfig,
  setDefaultConfig,
  handleRowSelection,
  selectedRow,
  setSelectedRow,
  isValidRowSelected,
}) => {
  const [activeSubtitle, setActiveSubtitle] = useState({});
  const [showjs, setShowjs] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  function getChatbot() {
    return defaultConfig?.chatbot || [];
  }

  function setChatbot(chatbotPayload) {
    setDefaultConfig((prev) => ({ ...prev, chatbot: chatbotPayload }));
  }

  /* --- HELPER FUNCTIONS __ START --- */

  function deleteChatBubble(idxToDelete) {
    let currentchatbot = [...getChatbot()];
    currentchatbot.splice(idxToDelete, 1);
    setChatbot([...currentchatbot]);
  }

  const cloneChatBubble = (idxToClone) => {
    let localChatbotBubbles = [...getChatbot()];
    let chatbotBubbleToAdd = {
      ...getChatbot()[idxToClone],
      buttons: [...getChatbot()[idxToClone]?.buttons],
      condition: [[]],
      id: uuidv4(),
    };
    let Index = parseInt(idxToClone + 1);
    localChatbotBubbles.splice(Index, 0, chatbotBubbleToAdd);
    setChatbot([...localChatbotBubbles]);
    handleRowSelection(getChatbot()?.length);
  };

  const editBubbleRowValues = (bubbleIndex, buttonIndex, e, isPathInput) => {
    console.log({ bubbleIndex, buttonIndex, e, isPathInput });
    var urlRegexExp = /(^(http|https|www):\/\/)/gi;
    var urlReg = new RegExp(urlRegexExp);

    let currentBubble = getChatbot();

    if (isPathInput) {
      if (typeof e === 'string' && e?.match(urlReg)) {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          href: e,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].route;
      } else {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          route: e?.route,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].href;
      }
      //return;
    }
    if (e?.target?.name === 'button-name') {
      currentBubble[bubbleIndex].buttons[buttonIndex] = {
        ...currentBubble[bubbleIndex].buttons[buttonIndex],
        label: e.target.value,
        route: currentBubble[bubbleIndex].buttons[buttonIndex].route,
      };
    }
    // else {
    //   return null;
    // }
    setChatbot([...currentBubble]);
  };

  const editBubbleType = (bubbleIndex, newType) => {
    const currentBubble = getChatbot();
    currentBubble[bubbleIndex].type = newType;
    // switch (newType) {
    //   case 'SocialProof':
    //   case 'Urgency':
    // }
    setChatbot([...currentBubble]);
  };

  const editBubbleText = (bubbleIndex, newText) => {
    const currentBubble = getChatbot();
    currentBubble[bubbleIndex].text = newText;
    setChatbot([...currentBubble]);
  };

  // Add Bubble Row
  const addBubbleRow = (index) => {
    let currentBubble = [...getChatbot()];
    currentBubble?.[index]?.buttons.push({
      label: '',
      route: '',
      uuid: getRandomId(),
    });
    setChatbot([...currentBubble]);
  };

  // Delete Bubble Row
  const deleteBubbleRow = (bubbleIndex, buttonIndex) => {
    let currentBubble = [...getChatbot()];
    currentBubble[bubbleIndex].buttons.splice(buttonIndex, 1);
    setChatbot([...currentBubble]);
  };

  function addjs(index, idx, e) {
    let copytemporaryChatbot = [...getChatbot()];
    copytemporaryChatbot[index].buttons[idx].js = e.target.value;
    setChatbot([...copytemporaryChatbot]);
  }

  const foo = (index) => {
    console.log('getChatbot()[index]', getChatbot()[index]);
    return 'placeholder';
  };

  /* --- HELPER FUNCTIONS __ END --- */

  return (
    <>
      <div className="bubbles-list mt-4">
        {getChatbot()?.length !== 0 &&
          getChatbot()?.map((bubble, index) => {
            if (index !== selectedRow) return <></>;
            return (
              <div key={index} className={` p-3`}>
                <div className="title font s15 b6 italic text-sm pb-2">{`Edit CTA Bubble: ${
                  index + 1
                }`}</div>
                <div className="bubble flex flex-col w-full border p-3 rounded-md ">
                  <div className="rit flex  justify-end items-center gap-2 text-sm text-slate-400 ">
                    <button
                      onClick={() => deleteChatBubble(index)}
                      className="flex items-cente gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                    >
                      <span className="ico icon-delete1 b6 s16 text-slate-400	fill-slate-400"></span>
                      <span className=" text-slate-400">Delete</span>
                    </button>
                    <button
                      onClick={() => cloneChatBubble(index)}
                      className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                    >
                      <span className="ico icon-paste b6 s16 text-xs text-slate-400	"></span>
                      <span className=" text-slate-400">Clone</span>
                    </button>
                    <button
                      className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                      onClick={() => {
                        setValueIndex(index);
                        !!getChatbot() &&
                          setCondition(
                            getChatbot()[index]?.condition?.[0] || [
                              {
                                type: 'tourPixelHistory',
                                route: '',
                                operator: '',
                                value: [],
                              },
                            ]
                          );
                        setStartDate(
                          getChatbot()?.[index]?.condition?.dateStart
                        );
                        setEndDate(getChatbot()?.[index]?.condition?.dateEnd);
                        setconditionModal(true);
                      }}
                    >
                      <span className="ico icon-magic-wand b6 s16"></span>
                      <span>Triggers {}</span>
                    </button>
                  </div>
                  <div className="bubble-content">
                    <FormControl variant="outlined" className="w-full my-2">
                      <InputLabel id="bubble-type-select-label">
                        Bubble Type
                      </InputLabel>
                      <Select
                        labelId="bubble-type-select-label"
                        id="bubble-type-select"
                        value={getChatbot()[index].type}
                        label="Bubble Type"
                        onChange={(e) => editBubbleType(index, e.target.value)}
                      >
                        <MenuItem value="Basic">Basic</MenuItem>
                        <MenuItem value="SocialProof">Social Proof</MenuItem>
                        <MenuItem value="Urgency">Urgency</MenuItem>
                      </Select>
                      <TextField
                        name="message"
                        variant="outlined"
                        className="w-full mt-4"
                        label="Bubble Message (Optional)"
                        id={`chatbot-text-bubble-${index}`}
                        value={getChatbot()[index].text}
                        onChange={(e) => {
                          editBubbleText(index, e.target.value);
                        }}
                      />
                    </FormControl>
                    <p className=" text-sm text-gray-500">Buttons</p>
                    <div className="variables-wrapper">
                      {bubble.buttons.map((item, idx) => (
                        <>
                          {' '}
                          <div
                            key={idx}
                            id={`item-bubble-${index}-${idx}`}
                            className=" flex items-start gap-2 my-3"
                          >
                            <div className="   flex-col pt-1 pr-2 rounded-lg flex items-center justify-center">
                              <div className=" flex items-center gap-1">
                                {item?.picArray && (
                                  <div className=" flex items-center">
                                    {item?.picArray.map((item, ind) => (
                                      <div
                                        key={ind}
                                        className={
                                          ind !== 0
                                            ? ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative -ml-3'
                                            : ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative'
                                        }
                                      >
                                        <img
                                          className=" w-full h-full"
                                          style={{
                                            objectFit: 'cover',
                                          }}
                                          src={item.img}
                                          alt=""
                                        />
                                        <div
                                          onClick={() =>
                                            deleteSelectedImage(
                                              item,
                                              index,
                                              idx,
                                              ind
                                            )
                                          }
                                          className=" img_hover cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center"
                                        >
                                          <IoMdClose />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {/* {console.log(index)} */}
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    onSelectImage(e);
                                  }}
                                  accept="image/*"
                                  className=" hidden"
                                  name="picture"
                                  id="picture"
                                />
                                <label
                                  onClick={() => {
                                    setIdx(idx);
                                    setButtonIndex(index);
                                  }}
                                  htmlFor="picture"
                                  className={
                                    progress === false
                                      ? ' p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 '
                                      : ' p-2 cursor-pointer rounded-full bg-gray-200 text-gray-400 animate-spin opacity-30'
                                  }
                                >
                                  <FiUserPlus className=" w-5 h-5" />
                                </label>
                              </div>
                            </div>

                            <div className="field flex flex-col w-full gap-2">
                              <input
                                type="text"
                                name="button-name"
                                placeholder="Button title"
                                className="border rounded-md px-2 py-2 w-full"
                                onFocus={() =>
                                  setActiveSubtitle({ index, idx })
                                }
                                id={`chatbot-text-bubble-title-${index}-${idx}`}
                                value={getChatbot()[index].buttons[idx].label}
                                onChange={(e) => {
                                  editBubbleRowValues(index, idx, e);
                                }}
                              />
                              {activeSubtitle?.index === index &&
                                activeSubtitle?.idx === idx && (
                                  <input
                                    type="text"
                                    name="subtitle"
                                    onFocus={() =>
                                      setActiveSubtitle({ index, idx })
                                    }
                                    placeholder="Add subtitle"
                                    className=" w-full py-2 px-2 border rounded-md  bg-gray-50 text-sm"
                                    id={`chatbot-text-bubble-subtitle-${index}-${idx}`}
                                    value={
                                      getChatbot()[index].buttons[idx].subTitle
                                    }
                                    onChange={(e) => {
                                      addSubtitles(index, e, idx);
                                    }}
                                  />
                                )}
                            </div>

                            <div className="field flex flex-col w-full">
                              <RouteInput
                                routes={routes}
                                onFocus={() => {
                                  setShowjs({ index, idx });
                                }}
                                id={`chatbot-text-bubble-path-${index}-${idx}`}
                                value={
                                  getChatbot()[index].buttons[idx]?.route ||
                                  getChatbot()[index].buttons[idx]?.href
                                }
                                onChange={(value) => {
                                  editBubbleRowValues(index, idx, value, true);
                                }}
                                minWidth="50px"
                              />
                              <AccessLevelWrapper
                                access_level_clearance="global"
                                community_id={community_id}
                              >
                                {
                                  <textarea
                                    onChange={(e) => addjs(index, idx, e)}
                                    className=" border p-1 rounded-md"
                                    placeholder="Enter Js"
                                    name=""
                                    id=""
                                    defaultValue={item?.js}
                                    value={item?.js}
                                    cols="10"
                                    rows="5"
                                  ></textarea>
                                }
                              </AccessLevelWrapper>
                            </div>

                            <button
                              onClick={() => deleteBubbleRow(index, idx)}
                              className="flex items-center gap-1  border rounded-md px-2 py-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                              style={{ height: '38px' }}
                            >
                              <AiOutlineDelete className="w-4 h-4" />
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="mt-3 flex justify-between">
                      <button
                        onClick={() => addBubbleRow(index)}
                        className="mr-4 flex items-center gap-1 bg-gray-600 px-2 text-sm py-2 rounded-md text-white"
                      >
                        +<span>Add new row</span>
                      </button>
                      <button
                        onClick={() => setSelectedRow(null)}
                        className="flex items-center gap-1 bg-pr px-2 text-sm py-2 rounded-md text-white"
                      >
                        <span>Finish Editing</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* {getChatbot().length !== 0 && (
        <div className="flex aic  items-center justify-between pl-2 w-full pb-10 px-3">
          <button
            onClick={addNewChatBubble}
            className=" border px-3 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500"
          >
            <span className="ico icon-plus s15 pr-1"></span>
            <span>Add bubble</span>
          </button>
          <button
            onClick={saveChatbot}
            className="border px-3 py-2 text-sm font-semibold flex items-center gap-1 hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500"
          >
            <BsCheck2All className=" w-5 h-5" />
            <span>Save Chatbot</span>
          </button>
        </div>
      )} */}
    </>
  );
};

export default AddBubbleSection;
