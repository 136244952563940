import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import {
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  Slide,
  Switch,
} from '@material-ui/core';
import WidgetColorPicker from './WidgetColorPicker';
import MenuItem from '@material-ui/core/MenuItem';
import { MdEdit } from 'react-icons/md';
import RouteInput from '../../components/Reusable/RouteInput';
import MagnetPopup from '../manage-magnets/MagnetPopup';
import PreviewVideoMagnet from '../manage-magnets/PreviewVideoMagnet';
import { Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import MagnetSettings from '../manage-magnets/MagnetSettings';
import ChatbotBubble from './ChatbotBubble';

const TourBubbleConfig = ({
  deleteChatBubble,
  routeOpts,
  magnetUuid,
  videoStartChoice,
  handleChangeMenu,
  widgetLayout,
  setLayoutDirection,
  PreviewWidgetEmbed,
  setDefaultConfig,
  defaultConfig,
  addNewChatBubble,
  selectedRow,
  setSelectedRow,
  handleRowSelection,
  isValidRowSelected,
}) => {
  // start screen route state
  const [tourPopupScreenRoute, setTourPopupScreenRoute] = useState([]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    setTourPopupScreenRoute(null);
  };

  const onClickLink = (button) => {
    console.log('buttonClick: ', button);
    if ('route' in button) {
      setTourPopupScreenRoute(button?.route?.split('.'));
    } else if ('href' in button) {
      window.open(button?.href, '_blank');
    }
  };

  const { magnetId } = useSelector((state) => state.magnetStates);
  const { template } = useSelector((state) => state.getMagnet);

  var latestSpecial = defaultConfig?.specialOffer || 'Latest special Offer';

  let latestSpecialForm = {
    title: latestSpecial
      ? `Redeem your special offer: ` + latestSpecial
      : 'Register to qualify for our latest specials',
    form: {
      contact: {
        enabled: false,
      },
      enabled: true,
      inputs: [
        {
          id: 'lead_name',
          label: 'Name',
          required: true,
          type: 'lead_name',
        },
        {
          id: 'lead_phone',
          label: 'Phone Number',
          required: true,
          type: 'lead_phone',
        },
        {
          id: 'lead_email',
          label: 'Email',
          required: true,
          type: 'lead_email',
        },
      ],
      opacity: 1,
      text_color: 'black',
      text_data: {
        title: latestSpecial
          ? `Redeem your special offer: ` + latestSpecial
          : 'Register to qualify for our latest specials',
        body_text:
          'Fill out your Details Below to claim the special and become eligible for other specials at this time',
        submit_body:
          'You can share this tour with a friend using the buttons below.',
        submit_fail:
          'Oops! Something went wrong while submitting the form. Please try again in a moment.',
        submit_success: 'Your submission has been received!',
      },
    },
    links: [],
  };

  const generateLatestSpecialForm = () => {
    console.log('tourPopupScreenRoute: ', tourPopupScreenRoute);
    if (
      // !defaultConfig?.specialOffer ||
      tourPopupScreenRoute?.[0] === '_promotions_'
    ) {
      return {
        category: '_preview_',
        screen: 'new',
        previewTemplateScreen: latestSpecialForm,
      };
    }
    return {
      screen: tourPopupScreenRoute?.[1],
      category: tourPopupScreenRoute?.[0],
    };
  };

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-6 pt-4 items-center p-4">
        <Dialog
          open={tourPopupScreenRoute && tourPopupScreenRoute?.length === 2}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth={'l'}
          PaperProps={{
            style: {
              height: '95vh',
              width: '80vw',
              borderRadius: 13,
              overflow: 'hidden',
            },
          }}
        >
          {/* If we render when not open, then the video starts playing in the background */}
          {tourPopupScreenRoute}

          {tourPopupScreenRoute && tourPopupScreenRoute?.length === 2 && (
            <PreviewVideoMagnet
              uuid={magnetId}
              template={template}
              {...generateLatestSpecialForm()}
              inline={false}
              singleScreen={true}
              onClose={handleClose}
            />
          )}
        </Dialog>

        <div className=" ml-4">
          <div className="rs">
            <span className="font-bold pb-2">
              What your website visitors see:{' '}
            </span>
            <div
              className={`chatbot-preview ${
                defaultConfig?.layout === 'right' && 'items-end'
              }`}
            >
              <div
                className={`chatbot-bubbles float-right ${
                  defaultConfig?.layout === 'right' && 'items-end'
                }`}
              >
                {defaultConfig?.chatbot?.length > 0 &&
                  defaultConfig?.chatbot?.map((item, index) => {
                    if (item?.text == '' && item?.buttons?.[0]?.label == '') {
                      return (
                        <div className="flex">
                          <div
                            key={index}
                            className={`initial-message-bubble font-gray-500 flex items-center text-gray-500 text-sm ${
                              defaultConfig?.layout === 'right' && 'order-2'
                            }`}
                          >
                            <div class="typingIndicatorBubble">
                              <div class="typingIndicatorBubbleDot"></div>
                              <div class="typingIndicatorBubbleDot"></div>
                              <div class="typingIndicatorBubbleDot"></div>
                            </div>
                            Add to your CTA bubble
                          </div>
                          <div
                            className={`justify-center p-2 ${
                              defaultConfig?.layout === 'right' && 'order-1'
                            }`}
                          >
                            {selectedRow === index ? (
                              <span
                                className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                                onClick={() => {
                                  handleRowSelection(null);
                                }}
                              >
                                {' '}
                                <MdEdit /> Close{' '}
                                <span className="italic inline">
                                  ({index + 1})
                                </span>
                              </span>
                            ) : (
                              <span
                                className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                                onClick={() => {
                                  handleRowSelection(index);
                                  console.log('Edit: ', index);
                                }}
                              >
                                {' '}
                                <MdEdit /> Edit{' '}
                                <span className="italic inline">
                                  ({index + 1})
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className="flex items-center">
                        <ChatbotBubble
                          item={item}
                          key={item.id}
                          primaryColor={defaultConfig.primaryColor}
                          onDelete={() => deleteChatBubble(index)}
                          className={
                            defaultConfig?.layout === 'right' && 'order-2'
                          }
                        />
                        <div
                          className={`justify-center p-2 ${
                            defaultConfig?.layout === 'right' && 'order-1'
                          }`}
                        >
                          {selectedRow === index ? (
                            <span
                              className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                              onClick={() => {
                                handleRowSelection(null);
                              }}
                            >
                              {' '}
                              <MdEdit /> Close
                            </span>
                          ) : (
                            <span
                              className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                              onClick={() => {
                                handleRowSelection(index);
                                console.log('Edit: ', index);
                              }}
                            >
                              {' '}
                              <MdEdit /> Edit{' '}
                              <span className="italic"> ({index + 1}) </span>
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <button
                  className=" border px-3 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500 mb-2"
                  style={{ maxWidth: '240px' }}
                  onClick={addNewChatBubble}
                >
                  <span className="ico icon-plus s15 pr-1"></span>
                  <span>Add CTA Bubble</span>
                </button>
              </div>
            </div>
          </div>
          {/* Tour Bubble Main Preview */}
          <div
            className={`flex ${
              defaultConfig.layout === 'right' && 'justify-end'
            }`}
          >
            <div
              style={{
                position: 'relative',
                height: 120,
                width: 120,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `8px solid ${defaultConfig?.primaryColor || '#224493'}`,
                borderRadius: 100,
              }}
            >
              <video
                src={videoStartChoice?.toString()}
                muted
                autoPlay
                playsInline
                loop
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 100,
                  overflow: 'hidden',
                  objectFit: 'cover',
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  fontFamily: 'inherit',
                  textShadow: '0px 0px 4px black',
                }}
              >
                {defaultConfig?.buttonLabel}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <FormControl
              variant="filled"
              style={{ width: '80%', maxWidth: '375px' }}
            >
              <RouteInput
                placeholder="Default Screen"
                routes={routeOpts}
                value={defaultConfig?.startScreen || ''}
                disableHrefGeneration={true}
                // value={condition[ind].route}
                onChange={(value) => {
                  console.log('value: ', value);
                  setDefaultConfig({
                    ...defaultConfig,
                    startScreen: value?.route,
                  });
                }}
              />
            </FormControl>
          </div>

          <br />
          <div>
            <DebouncedTextField
              style={{ minWidth: '350px' }}
              label="Call to Action"
              value={defaultConfig?.buttonLabel}
              onChange={(value) => {
                setDefaultConfig((prev) => ({ ...prev, buttonLabel: value }));
              }}
            />
          </div>
          <br />
          <div className="flex w-full gap-4">
            <div>
              <InputLabel shrink={true}>Widget Position</InputLabel>
              <ButtonGroup>
                <Button
                  style={
                    defaultConfig?.layout === 'left'
                      ? {
                          backgroundColor: 'rgb(59 130 246)',
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : {
                          textTransform: 'capitalize',
                        }
                  }
                  onClick={() => setLayoutDirection('left')}
                >
                  Left
                </Button>
                <Button
                  onClick={() => setLayoutDirection('right')}
                  style={
                    defaultConfig?.layout === 'right'
                      ? {
                          backgroundColor: 'rgb(59 130 246)',
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : {
                          textTransform: 'capitalize',
                        }
                  }
                >
                  Right
                </Button>
              </ButtonGroup>
            </div>
            <div>
              <InputLabel shrink={true}>Primary Color</InputLabel>
              <WidgetColorPicker
                widgetColorPrimary={defaultConfig?.primaryColor || '#224493'}
                setWidgetColorPrimary={(e) => {
                  console.log('setWidgetColorPrimary', e);
                  setDefaultConfig((prev) => ({ ...prev, primaryColor: e }));
                }}
              />
            </div>
          </div>
          {PreviewWidgetEmbed({
            uuid: magnetUuid,
            buttonLabel: defaultConfig?.buttonLabel,
          })}
        </div>
      </div>

      {/* <MagnetSettings
            // community_id={community_id}
            magnet_uuid={magnetId}
            title="Settings"
          /> */}
    </>
  );
};

export default TourBubbleConfig;
