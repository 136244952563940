import { useState, useEffect } from 'react';
import { getanalyicsbigquery } from '../../../utils/api';

function InfoScreen({ children, onCall, visitorData }) {
  const [visitorLocationobj, setvisitorLocationobj] = useState();
  const getuserlocationbyVisitorId = async () => {
    let form_view_res = await getanalyicsbigquery(
      'SELECT `location_city`,`location_country_name`,`location_continent` FROM `leasemagnets-app.default.events` WHERE `visitor_uuid` = ' +
        `'${visitorData?.visitorId}'`
    );
    console.log('form_view_res', form_view_res);
    setvisitorLocationobj({
      city: form_view_res?.res?.[0]?.[0],
      country: form_view_res?.res?.[0]?.[1],
      continent: form_view_res?.res?.[0]?.[2],
    });
  };
  useEffect(() => {
    getuserlocationbyVisitorId();
  }, []);

  return (
    <div className="bg-black p-2 my-2 mx-2 rounded">
      {/* ---- HEADER ---- */}
      <div className="bg-gray-700 p-3 text-white rounded text-base">
        <p className="break-all">{visitorData?.pageUrl}</p>
      </div>
      {/* ---- MIDDLE ---- */}
      {children}
      {/* ---- FOOTER ---- */}
      <section
        className="grid text-white"
        style={{ gridTemplateColumns: '1fr 1fr' }}
      >
        {/* ---- LEFT SIDE ---- */}
        <div className="p-2">
          <p className="font-bold">
            {visitorData?.name ? visitorData.name : 'Unidentified'}
          </p>
          <p className="mb-2">
            {' '}
            {visitorLocationobj
              ? `${
                  visitorLocationobj?.city
                    ? visitorLocationobj?.city + ', '
                    : ''
                }${
                  visitorLocationobj?.country
                    ? visitorLocationobj?.country + ', '
                    : ''
                }${
                  visitorLocationobj?.continent
                    ? visitorLocationobj?.continent
                    : ''
                }`
              : 'Fetching Location...'}
          </p>
          <div className="break-words bg-gray-700 p-2 rounded">
            <p>Current Visit ? How long, when times</p>
            <p>How many times they came</p>
            <p>User Initiated Call</p>
          </div>
        </div>
        {/* ---- RIGHT SIDE ---- */}
        <div className="p-2">
          <p className="my-2 font-bold">Actions</p>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center flex-wrap gap-2">
              <button
                onClick={onCall}
                className="px-4 py-1 rounded-lg font-bold bg-green-500"
              >
                Call
              </button>
              <button className="px-4 py-1 rounded-lg font-bold bg-gray-700">
                Prequalify Form
              </button>
            </div>
            <div>
              <textarea
                className="bg-gray-700 p-2 rounded w-full resize-none"
                placeholder="message"
                style={{ height: 'fit-content' }}
              ></textarea>
              <button className="px-2 py-1 my-2 w-full rounded-lg font-bold bg-blue-500">
                Send
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InfoScreen;
