import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import AddBubbleSection from '../talk-and-triggers/AddBubbleSection';
import { generateRoutes } from './ManageMagnet';
import { getMagnetData } from '../../store/actions/magnetActions';

function ChatbotDialog({ optionalDefaultConfig, setOptionalDefaultConfig }) {
  const { template } = useSelector((state) => state.getMagnet);
  const { magnetId: magnetUuid } = useSelector((state) => state.magnetStates);
  const { community_id } = useParams();
  const [defaultConfig, setDefaultConfig] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [valueIndex, setValueIndex] = useState(null);
  const [condition, setCondition] = useState([]);
  const [conditionModal, setconditionModal] = useState(false);
  const [idx, setIdx] = useState(0);
  const [buttonIndex, setButtonIndex] = useState(0);
  const [progress, setProgress] = useState(false);
  const [routes, setRoutes] = useState(null);
  const _dispatch = useDispatch();

  useEffect(() => {
    // console.log('BCDA Template: ', template);
    setDefaultConfig(optionalDefaultConfig || template?.default_config || []);
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setRoutes(generateRoutes(template));
      console.log('BCDA Routes: ', generateRoutes(template));
    }
  }, [template, optionalDefaultConfig]);

  const handleCloseSignOutDialog = () => {
    setconditionModal(false);
  };

  const handleRowSelection = (newSelection) => {
    console.log('rowSelected: ', newSelection);
    setSelectedRow(newSelection);
  };

  const deselectRow = () => {
    setSelectedRow(null);
  };

  const onClickLink = (link) => {
    if (link.route) {
      const newRoute = link.route.split('.');
      // onClick(newRoute);
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href, '_blank').focus();
    } else if (link.href_direct) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href_direct, '_self').focus();
    }
  };

  function getChatbot() {
    return defaultConfig?.chatbot || [];
  }

  function setChatbot(chatbotPayload) {
    setDefaultConfig((prev) => {
      const updatedConfig = { ...prev, chatbot: chatbotPayload };
      console.log('BCDA UpdateConfig with Chatbot', updatedConfig);
      return updatedConfig;
    });
    setOptionalDefaultConfig({
      ...optionalDefaultConfig,
      chatbot: chatbotPayload,
    });
  }

  function addNewChatBubble() {
    setChatbot([
      ...getChatbot(),
      {
        type: 'Basic',
        text: '',
        condition: [[]],
        buttons: [{ label: '', route: '', href: '' }],
      },
    ]);
    handleRowSelection(getChatbot()?.length);
  }

  const isValidRowSelected = selectedRow !== null;

  const saveChatbot = async (props) => {
    const data = {
      community_id,
      magnet_uuid: magnetUuid,
    };
    console.log('saving_default_config', {
      ...defaultConfig,
      chatbot: getChatbot(),
    });
    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbed',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...defaultConfig,
          chatbot: getChatbot(),
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        _dispatch(getMagnetData(community_id));

        toast(
          props?.toastMessage
            ? props?.toastMessage
            : `Successfully Saved CTAs & Special Offers`,
          {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          }
        );
      })
      .catch(() => {
        toast('No internet connection found. App is running in offline mode.', {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      });
  };

  const handleUpload = async (file) => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        'magnetuid/' + guidGenerator()
      );
      setProgress(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      let imageUrl = await handleUpload(e.target.files[0]);

      let copytemporaryChatbot = getChatbot();
      if (copytemporaryChatbot[buttonIndex].buttons[idx]?.picArray) {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray.push({
          img: imageUrl,
          alt: '',
        });
      } else {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray = [
          { img: imageUrl, alt: '' },
        ];
      }

      setChatbot([...copytemporaryChatbot]);
    }
  }

  const deleteSelectedImage = (item, index, idx, pic_idx) => {
    const filterdImageArrray = getchatbot()[index].buttons[idx].picArray.filter(
      (pic, l_index) => pic_idx != l_index
    );
    let copyAgainChatbot = [...getChatbot()];
    copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray;
    console.log(
      (copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray)
    );
    setChatbot([...copyAgainChatbot]);
    // setchatbot(chatbot[index].buttons[idx].picArray = filterdImageArrray);
  };

  function addSubtitles(index, e, idx) {
    let copytemporaryChatbot = [...getChatbot()];

    copytemporaryChatbot[index].buttons[idx].subTitle = e.target.value;

    setChatbot([...copytemporaryChatbot]);
  }

  return (
    <div>
      {/* Add Bubble Section */}
      <AnimatedDialog
        open={isValidRowSelected}
        onClose={() => {
          deselectRow();
        }}
        fullWidth
        maxWidth="md"
      >
        <AddBubbleSection
          defaultConfig={defaultConfig}
          setDefaultConfig={setDefaultConfig}
          addNewChatBubble={addNewChatBubble}
          handleRowSelection={handleRowSelection}
          saveChatbot={saveChatbot}
          setValueIndex={setValueIndex}
          setCondition={setCondition}
          setconditionModal={setconditionModal}
          setIdx={setIdx}
          setButtonIndex={setButtonIndex}
          onSelectImage={onSelectImage}
          deleteSelectedImage={deleteSelectedImage}
          addSubtitles={addSubtitles}
          progress={progress}
          community_id={community_id}
          routes={routes}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isValidRowSelected={isValidRowSelected}
          deselectRow={deselectRow}
        />
      </AnimatedDialog>
      {/* Chatbot Editor */}
      <div className="rs">
        <div className="chatbot-preview">
          <div className="chatbot-bubbles float-right">
            {defaultConfig?.chatbot?.length > 0 &&
              defaultConfig?.chatbot?.map((item, index) => {
                if (item?.text == '' && item?.buttons?.[0]?.label == '') {
                  return (
                    <div className="flex">
                      <div
                        key={index}
                        className={`initial-message-bubble font-gray-500 flex items-center text-gray-500 text-sm`}
                      >
                        <div class="typingIndicatorBubble">
                          <div class="typingIndicatorBubbleDot"></div>
                          <div class="typingIndicatorBubbleDot"></div>
                          <div class="typingIndicatorBubbleDot"></div>
                        </div>
                        Add to your CTA bubble
                      </div>
                      <div className="justify-center p-2">
                        {selectedRow === index ? (
                          <span
                            className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                            onClick={() => {
                              handleRowSelection(null);
                            }}
                          >
                            {' '}
                            <MdEdit /> Close{' '}
                            <span className="italic inline">({index + 1})</span>
                          </span>
                        ) : (
                          <span
                            className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                            onClick={() => {
                              handleRowSelection(index);
                              console.log('Edit: ', index);
                            }}
                          >
                            {' '}
                            <MdEdit /> Edit{' '}
                            <span className="italic inline">({index + 1})</span>
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="flex items-center">
                    <div
                      key={index}
                      className={`initial-message-bubble ${
                        item.buttons.length === 1 && !item.text
                          ? 'transparent'
                          : ''
                      } `}
                      style={{ minWidth: '175px' }}
                    >
                      {item.text && (
                        <div className="message-text">{item.text}</div>
                      )}
                      {item.users && (
                        <div className="support-block">
                          {item.users.map((user, index) => (
                            <div className="user">
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${user.img})`,
                                }}
                              />
                              <div className="name">{user.name}</div>
                            </div>
                          ))}
                        </div>
                      )}

                      {item.buttons.length > 0 && (
                        <div className="bubble-buttons">
                          {item.buttons.map((button, btn_index) => (
                            <button
                              onClick={() => onClickLink(button)}
                              className={`border bubble-btn ${
                                button.fullWidth ? 'full' : ''
                              }`}
                            >
                              <div className=" flex items-center w-full gap-3">
                                {button?.picArray && (
                                  <div className="flex items-center ">
                                    {button.picArray.map((item, ind) => (
                                      <img
                                        key={ind}
                                        src={item.img}
                                        className={
                                          ind === 0
                                            ? ' w-7 h-7 rounded-full'
                                            : ' w-7 h-7 -ml-1 rounded-full'
                                        }
                                        style={{
                                          objectFit: 'cover',
                                        }}
                                        alt=""
                                      />
                                    ))}
                                  </div>
                                )}

                                <div className="flex flex-col">
                                  <p> {button.label}</p>
                                  {button.subTitle && (
                                    <p className=" text-xs text-gray-400">
                                      {button.subTitle}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="justify-center p-2">
                      {selectedRow === index ? (
                        <span
                          className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                          onClick={() => {
                            handleRowSelection(null);
                          }}
                        >
                          {' '}
                          <MdEdit /> Close
                        </span>
                      ) : (
                        <span
                          className="ml-2 flex items-center gap-1 cursor-pointer transition-all hover:text-gray-500 transform hover:-translate-y-1"
                          onClick={() => {
                            handleRowSelection(index);
                            console.log('Edit: ', index);
                          }}
                        >
                          {' '}
                          <MdEdit /> Edit{' '}
                          <span className="italic"> ({index + 1}) </span>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <button
            className=" border px-3 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500 mb-2"
            style={{ maxWidth: '240px' }}
            onClick={addNewChatBubble}
          >
            <span className="ico icon-plus s15 pr-1"></span>
            <span>Add CTA Bubble</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatbotDialog;
